import { Box, Grid, Typography, TextField, Button, Paper, InputAdornment } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';


const defaultTheme = createTheme();
const EmailVrification = () => {

    const [email, setEmail] = useState('')
    const [userotp, setUserOtp] = useState('')
    const [talentId, setTalentId] = useState('')
    const location = useLocation();
    const { userName, password, firstName, lastName, mobileNumber, useremail, otp, companyName, workPhone, talentFile, organizationFile } = location.state || {};
    const navigate = useNavigate()
    const { save } = useAuth()
    const { enqueueSnackbar } = useSnackbar();
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));



    console.log(talentFile);


    const handleSubmitOtp = async (event) => {

        event.preventDefault();

        if (!userotp) {
            enqueueSnackbar('Please enter verification code', { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            return;
        }

        if (userotp !== otp) {
            enqueueSnackbar('Invalid verification code', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            return;
        }

        if (userotp === otp) {
            if (firstName && lastName && mobileNumber) {
                try{
                const formData = new FormData();
                formData.append('userName', userName);
                formData.append('password', password);
                formData.append('firstName', firstName);
                formData.append('lastName', lastName);
                formData.append('mobileNumber', mobileNumber);
                formData.append('userEmail', useremail);
                formData.append('emailVerification', true);
                formData.append('talentFile', talentFile); // Append the file to FormData

                const response = await axios.post(`${process.env.REACT_APP_URL}/api/signuptalent`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Required for file uploads
                    },
                });

                if (response.status === 201) {
                    const talentId = response.data.talentId;
                    save({}, response.data.token);
                    navigate('/talentprofile', { state: { talentId } });
                }
            }catch (error) {
                    if (error.response && error.response.status === 409) {
                        console.log('Talent already present');
                    } else {
                        enqueueSnackbar('Internal server error', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    }
                }

                console.log('successful');

            } else if (companyName && firstName && lastName && workPhone) {
                try {
                    // Create a FormData object
                    const formData = new FormData();
                    formData.append('companyName', companyName);
                    formData.append('firstName', firstName);
                    formData.append('lastName', lastName);
                    formData.append('workPhone', workPhone);
                    formData.append('workEmail', useremail);
                    formData.append('password', password);
                
                    // Append the file to the FormData object
                    if (organizationFile) {
                        formData.append('organizationFile', organizationFile);
                    }
                
                    // Send the POST request with FormData
                    const response = await axios.post(`${process.env.REACT_APP_URL}/api/createorganization`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                
                    console.log(response.data);
                
                    if (response.data === 'Phone') {
                        console.log('User is already present with mobile');
                    } else if (response.data === 'Email') {
                        console.log('User is already present with email');
                    } else if (response.status === 201) {
                        console.log('Successful');
                        const id = response.data.id;
                        save({}, response.data.token);
                        navigate('/organizationprofile', { state: { id } });
                    } else {
                        enqueueSnackbar('Internal server error', {
                            variant: 'error',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        });
                    }
                } catch (error) {
                    console.error('Error adding user data:', error);
                    enqueueSnackbar('Internal server error', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    });
                }
                
            }
        }

    }





    return (
        <Grid
            container
            sx={{
                background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
                display: 'flex',
                minHeight: '100vh'
            }}
        >
            <Grid item xs={12} sm={8} md={6} lg={4} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: isMobile ? 0 : 2,
                margin: 'auto'
            }}>

                <Paper elevation={10} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 5,
                    borderRadius: "25px",
                    width: { xs: '90%', md: '80%' }
                }}  >
                    <Grid container direction="column" alignItems="center" sx={{
                        borderRadius: "20px 20px 0 0"
                    }} xs={12}>
                        <Grid item>
                            <img
                                src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                alt="Logo"
                                style={{ width: '100px', marginRight: '5px' }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography component="h1" variant="h5" marginTop={2}>
                                Email Verification
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container component="form" noValidate sx={{ mt: 1 }} xs={12}>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                variant='standard'
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                value={useremail}
                                disabled
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon color='primary' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                variant='standard'
                                required
                                fullWidth
                                autoFocus
                                id="verificationcode"
                                label="Enter Verification Code"
                                onChange={(e) => setUserOtp(e.target.value)}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                // color="success"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={handleSubmitOtp}
                            >
                                Verify
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default EmailVrification