import { Box, Grid, Typography, InputAdornment, TextField, FormControl, FormControlLabel, InputLabel, IconButton, OutlinedInput, Button, Input } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const defaultTheme = createTheme();
const SignUp = () => {

    const [useremail, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showForm, setShowForm] = useState('talent')
    const [organizationName, setOrganizationName] = useState('');
    const [representativeName, setRepresentativeName] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [emailError, setEmailError] = useState(false);
    const [error, setError] = useState('');
    // const [formErrors, setFormErrors] = useState({});


    const [formErrors, setFormErrors] = useState({});
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('md'));

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateTalentForm = () => {
        let errors = {};
        if (!firstName.trim()) errors.firstName = 'First Name is required';
        if (!lastName.trim()) errors.lastName = 'Last Name is required';
        if (!useremail.trim()) errors.useremail = 'Email is required';
        else if (!validateEmail(useremail)) errors.useremail = 'Please enter a valid email address';
        if (!mobileNumber.trim()) errors.mobileNumber = 'Mobile Number is required';
        if (!password.trim()) errors.password = 'Password is required';
        else if (password.length < 8) errors.password = 'Password must be at least 8 characters long';
        else if (!isValidPassword(password)) {
            errors.password = 'Password must contain at least one uppercase, one lowercase, one digit, and one special character.';
        }
        if (!confirmPassword.trim()) errors.confirmPassword = 'Confirm Password is required';
        else if (password !== confirmPassword) errors.confirmPassword = 'Passwords do not match';

        setFormErrors(errors);
        return Object.keys(errors).length === 0; // return true if no errors
    }

    const validateOrganizationForm = () => {
        let errors = {};
        if (!organizationName.trim()) errors.organizationName = 'organizationName is required';
        if (!firstName.trim()) errors.firstName = 'First Name is required';
        if (!lastName.trim()) errors.lastName = 'Last Name is required';
        if (!useremail.trim()) errors.useremail = 'Email is required';
        else if (!validateEmail(useremail)) errors.useremail = 'Please enter a valid email address';
        if (!mobileNumber.trim()) errors.mobileNumber = 'Mobile Number is required';
        if (!password.trim()) errors.password = 'Password is required';
        else if (password.length < 8) errors.password = 'Password must be at least 8 characters long';
        if (!confirmPassword.trim()) errors.confirmPassword = 'Confirm Password is required';
        else if (password !== confirmPassword) errors.confirmPassword = 'Passwords do not match';

        setFormErrors(errors);
        return Object.keys(errors).length === 0; // return true if no errors
    }

    // const handleEmailChange = (e) => {
    //     const value = e.target.value;
    //     setEmail(value);
    //     if (value === '') {

    //         setEmailError(false);
    //     } else if (!validateEmail(value)) {

    //         setEmailError(true);
    //     } else {

    //         setEmailError(false);
    //     }
    // };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Remove email error as the user starts typing
        if (value === '') {
            // setEmailError(false);
            setFormErrors((prevErrors) => ({ ...prevErrors, useremail: '' }));
        } else if (!validateEmail(value)) {
            // setEmailError(true);
            setFormErrors((prevErrors) => ({ ...prevErrors, useremail: 'Please enter a valid email address.' }));
        } else {
            // setEmailError(false);
            setFormErrors((prevErrors) => ({ ...prevErrors, useremail: '' }));
        }
    };




    const navigate = useNavigate();



    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        if (formErrors.password) {
            setFormErrors((prevErrors) => ({ ...prevErrors, password: '' }));
        }
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (formErrors.password) {
            setFormErrors((prevErrors) => ({ ...prevErrors, password: '' }));
        }
    };

    const isValidPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/;
        return regex.test(password);
    };


    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_URL}/api/signuptalent`, {
    //             userName: useremail,
    //             password,
    //             fullName : name,
    //             mobileNumber : mobileNumber,
    //             userEmail : useremail,  
    //         });

    //         if (response.status === 201) {
    //             // handleSucces()
    //             console.log('Successful');

    //         }
    //     } catch (error) {
    //         if (error.response && error.response.status === 409) {
    //             // Swal.fire({
    //             //     icon: 'error',
    //             //     title: 'Admin already present'
    //             // });
    //             console.log('Talent already present');

    //         } else {
    //             // Swal.fire({
    //             //     icon: 'error',
    //             //     title: 'Something went wrong',
    //             //     text: error.message
    //             // });
    //             console.log('Something went wrong');
    //         }
    //     }
    // };



    // const navigate = useNavigate();

    const handleTalentSubmit = async (event) => {
        event.preventDefault();
        // if (password !== confirmPassword) {
        //     // setError("Passwords do not match");
        //     console.log("password is not strong");
        //     return;
        // }else if (!isValidPassword(password)) {
        //     setError("Password is not strong enough");
        //     console.log("password is not strong");

        //     return;
        // }

        const isFormValid = validateTalentForm();

        if (isFormValid) {
            // Validate form inputs here if necessary
            try {
                const response = await axios.post(`${process.env.REACT_APP_URL}/api/emailverification`, {
                    firstName: firstName,
                    lastName: lastName,
                    userEmail: useremail,
                    mobileNumber
                });

                if (response.status === 200) {
                    console.log(response.data.otp);
                    const otp = response.data.otp

                    console.log('Successful');
                    navigate('/emailverification', {
                        state: {
                            userName: useremail,
                            password,
                            firstName,
                            lastName,
                            mobileNumber,
                            useremail,
                            otp
                        }
                    });

                }
            } catch (error) {
                if (error.response && error.response.status === 409) {


                    enqueueSnackbar('Talent already present! Please Sign In', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                    })

                } else if (error.response.data.message === "Failed to send verification email. Please check the email address and try again.") {
                    enqueueSnackbar('Invalid Email!', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                    })
                } else {

                    enqueueSnackbar('Something went wrong', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                    })
                }




                setError('');



            };
        }
        // else {
        //     enqueueSnackbar('Please fill all fields', {
        //         variant: 'error',
        //         anchorOrigin: { vertical: 'top', horizontal: 'right' }
        //     })

        // }

    }


    const handleOrganizationSubmit = async (event) => {
        event.preventDefault();
        const isFormValid = validateOrganizationForm()
        if (isFormValid) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_URL}/api/organiationemailverification`, {
                    companyName: organizationName,
                    firstName: firstName,
                    lastName: lastName,
                    workPhone: mobileNumber,
                    workEmail: useremail,

                });


                if (response.status === 200) {
                    console.log(response.data.otp);
                    const otp = response.data.otp

                    console.log('Successful');
                    navigate('/emailverification', {
                        state: {
                            companyName: organizationName,
                            firstName: firstName,
                            lastName: lastName,
                            workPhone: mobileNumber,
                            useremail: useremail,
                            password,
                            otp
                        }
                    });

                }
            } catch (error) {
                if (error.response && error.response.status === 409) {

                    enqueueSnackbar('Orgaization already present ! Please Sign In', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                    })


                } else if (error.response.data.message === "Failed to send verification email. Please check the email address and try again.") {
                    enqueueSnackbar('Invalid Email!', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                    })
                } else {

                    enqueueSnackbar('Something went wrong!', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                    })
                }




                setError('');



            };
        } 
        // else {
        //     enqueueSnackbar('Please fill all fields', {
        //         variant: 'error',
        //         anchorOrigin: { vertical: 'top', horizontal: 'right' }
        //     })
        // }

    }





    return (
        <Box display='flex' >
            <Grid container xs={12} md={12} lg={12} justifyContent='center' alignItems='center' sx={{
                background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
                height: '100%',
                display: 'flex',
                minHeight: '100vh',
                padding: isMobile ? 1 : 3,
                // p: 3
            }}>
                <Grid container xs={10} md={5} lg={5} sx={{ bgcolor: 'white', borderRadius: 5 }} justifyContent='center' p={3}>
                    <Grid container direction="column" alignItems="center" sx={{
                        borderRadius: "20px 20px 0 0"
                    }} xs={12}>
                        <Grid item>
                            <img
                                src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                alt="Logo"
                                style={{ width: '100px', marginRight: '5px' }}
                            />
                        </Grid>

                    </Grid>

                    <Grid container xs={10} md={8} lg={8} pt={3} pb={3}>
                        <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>

                            {/* <Button variant={showForm === 'talent' ? 'contained' : 'outlined'} fullWidth onClick={() => setShowForm('talent')} color={showForm === 'talent' ? 'success' : 'primary'} sx={{ borderRadius: '2px 0px 0px 2px' }}>
                                Talent
                            </Button> */}
                            <Button
                                variant={showForm === 'talent' ? 'contained' : 'outlined'}
                                fullWidth
                                onClick={() => setShowForm('talent')}
                                color={showForm === 'talent' ? 'success' : 'primary'}
                                sx={{
                                    borderRadius: '20px 0px 0px 20px',
                                    backgroundColor: showForm === 'talent' ? '#48216D' : 'transparent',
                                    color: showForm === 'talent' ? '#FFFFFF' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: showForm === 'talent' ? '#48216D' : 'inherit',
                                    }

                                }}
                            >
                                Talent
                            </Button>

                        </Grid>

                        <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant={showForm === 'organization' ? 'contained' : 'outlined'} fullWidth onClick={() => setShowForm('organization')}
                                color={showForm === 'organization' ? 'success' : 'primary'}
                                sx={{
                                    borderRadius: '0px 20px 20px 0px',
                                    backgroundColor: showForm === 'organization' ? '#48216D' : 'transparent',
                                    color: showForm === 'organization' ? '#FFFFFF' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: showForm === 'organization' ? '#48216D' : 'inherit',
                                    }
                                }}>
                                Organization
                            </Button>
                        </Grid>
                    </Grid>


                    {showForm === 'talent' && (
                        <Grid container xs={12} md={12} lg={12} justifyContent='center' >

                            <Grid container xs={12} md={8} lg={8} gap={1} justifyContent={isMobile ? "center" : 'space-between'} >
                                <Grid item xs={10} md={5.5} lg={5.5}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="firstname"
                                        label="First Name"
                                        name="firstname"
                                        autoComplete="firstname"
                                        autoFocus
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                            // Clear error for First Name when the user starts typing
                                            if (formErrors.firstName) {
                                                setFormErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only alphabetic characters
                                            if (!/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formErrors.firstName && <Typography color="error" fontSize='10px'>{formErrors.firstName}</Typography>}
                                </Grid>

                                <Grid item xs={10} md={5.5} lg={5.5}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lastName"
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            if (formErrors.lastName) {
                                                setFormErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only alphabetic characters
                                            if (!/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formErrors.lastName && <Typography color="error" fontSize='10px'>{formErrors.lastName}</Typography>}
                                </Grid>
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    onChange={handleEmailChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {formErrors.useremail && <Typography color="error" fontSize='10px'>{formErrors.useremail}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="mobileNumber"
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    autoComplete="tel"
                                    // value={mobileNumber}
                                    // onChange={(e) => setMobileNumber(e.target.value)}
                                    onChange={(e) => {
                                        setMobileNumber(e.target.value);
                                        if (formErrors.mobileNumber) {
                                            setFormErrors((prevErrors) => ({ ...prevErrors, mobileNumber: '' }));
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                        inputMode: 'numeric', // Ensures numeric keyboard on mobile devices
                                    }}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}

                                />
                                {formErrors.mobileNumber && <Typography color="error" fontSize='10px'>{formErrors.mobileNumber}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <FormControl required variant='standard' fullWidth margin='normal'>
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    <Input
                                        id="standard-password-input"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}

                                        onChange={handlePasswordChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                                {formErrors.password && <Typography color="error" fontSize='10px'>{formErrors.password}</Typography>}
                            </Grid>


                            <Grid item xs={10} md={8} lg={8} >
                                <FormControl required variant="standard" fullWidth margin='normal'>
                                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                                    <Input
                                        id="outlined-adornment-confirm-password"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        label="Confirm Password"
                                    />
                                </FormControl>
                                {formErrors.confirmPassword && <Typography color="error" fontSize='10px'>{formErrors.confirmPassword}</Typography>}
                                <Typography variant="caption" color="textSecondary" >
                                    Note : Password should contain at least one special character, one digit, one uppercase letter, and one lowercase letter
                                </Typography>
                                
                            </Grid>

                            <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
                                <Button variant='contained' sx={{ bgcolor: '#ecb61e', borderRadius: 5 }} onClick={handleTalentSubmit} fullWidth>
                                    Submit
                                </Button>
                            </Grid>

                            <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
                                <Typography>
                                    Already have account?
                                </Typography>
                            </Grid>
                            <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
                                <Link sx={{ cursor: 'pointer' }} to='/talentlogin'>Talent Login</Link>
                            </Grid>

                            <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
                                <Link sx={{ cursor: 'pointer' }} to='/organizatiologin'>Organization Login</Link>
                            </Grid>
                        </Grid>
                    )}

                    {showForm === 'organization' && (
                        <Grid container xs={12} md={12} lg={12} justifyContent='center' >
                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="organizationName"
                                    label="Organization Name"
                                    name="organizationName"
                                    autoComplete="organizationName"
                                    autoFocus
                                    // onChange={(e) => setOrganizationName(e.target.value)}
                                    onChange={(e) => {
                                        setOrganizationName(e.target.value);
                                        // Clear error for First Name when the user starts typing
                                        if (formErrors.organizationName) {
                                            setFormErrors((prevErrors) => ({ ...prevErrors, organizationName: '' }));
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <HomeRepairServiceIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                 {formErrors.organizationName && <Typography color="error" fontSize='10px'>{formErrors.organizationName}</Typography>}
                            </Grid>
                            <Grid container xs={12} md={8} lg={8} gap={1} justifyContent={isMobile ? "center" : 'space-between'} >
                                <Grid item xs={10} md={5.5} lg={5.5}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="firstname"
                                        label="First Name"
                                        name="firstname"
                                        autoComplete="firstname"
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                            // Clear error for First Name when the user starts typing
                                            if (formErrors.firstName) {
                                                setFormErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only alphabetic characters
                                            if (!/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                     {formErrors.firstName && <Typography color="error" fontSize='10px'>{formErrors.firstName}</Typography>}
                                </Grid>

                                <Grid item xs={10} md={5.5} lg={5.5}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lastName"
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            if (formErrors.lastName) {
                                                setFormErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only alphabetic characters
                                            if (!/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                     {formErrors.lastName && <Typography color="error" fontSize='10px'>{formErrors.lastName}</Typography>}
                                </Grid>
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="email"
                                    label="Work Email"
                                    name="email"
                                    autoComplete="email"
                                    onChange={handleEmailChange}
                                    // error={emailError} // Shows the error state if the email is invalid
                                    // helperText={emailError ? "Please enter a valid email address." : ""}
                                    // onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                {formErrors.useremail && <Typography color="error" fontSize='10px'>{formErrors.useremail}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="mobileNumber"
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    autoComplete="tel"
                                    // value={mobileNumber}
                                    // onChange={(e) => setMobileNumber(e.target.value)}
                                    onChange={(e) => {
                                        setMobileNumber(e.target.value);
                                        // Clear error for First Name when the user starts typing
                                        if (formErrors.mobileNumber) {
                                            setFormErrors((prevErrors) => ({ ...prevErrors, mobileNumber: '' }));
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                        inputMode: 'numeric', // Ensures numeric keyboard on mobile devices
                                    }}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}

                                />

                                {formErrors.mobileNumber && <Typography color="error" fontSize='10px'>{formErrors.mobileNumber}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <FormControl required variant='standard' fullWidth margin='normal'>
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    <Input
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}

                                        onChange={handlePasswordChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>

                                {formErrors.password && <Typography color="error" fontSize='10px'>{formErrors.password}</Typography>}
                            </Grid>


                            <Grid item xs={10} md={8} lg={8} >
                                <FormControl required variant='standard' fullWidth margin='normal'>
                                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                                    <Input
                                        id="outlined-adornment-confirm-password"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        label="Confirm Password"
                                    />
                                </FormControl>
                                <Typography variant="caption" color="textSecondary" >
                                    Note : Password should contain at least one special character, one digit, one uppercase letter, and one lowercase letter
                                </Typography>
                                {formErrors.confirmPassword && <Typography color="error" fontSize='10px'>{formErrors.confirmPassword}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
                                <Button onClick={handleOrganizationSubmit} variant='contained' sx={{ bgcolor: '#ecb61e', borderRadius: 5 }} fullWidth>
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
                                <Typography>
                                    Already have account?
                                </Typography>
                            </Grid>
                            <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
                                <Link sx={{ cursor: 'pointer' }} to='/talentlogin'>Talent Login</Link>
                            </Grid>

                            <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
                                <Link sx={{ cursor: 'pointer' }} to='/organizatiologin'>Organization Login</Link>
                            </Grid>
                        </Grid>
                    )}
                </Grid>



            </Grid>
        </Box>
    )
}

export default SignUp