import { AppBar, Box, Divider, Drawer, ListItem, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Toolbar, Tooltip, Typography, Grid, Button, Avatar } from '@mui/material'
import React from 'react'
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiAppBar from '@mui/material/AppBar';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LogoutIcon from '@mui/icons-material/Logout';
import WorkIcon from '@mui/icons-material/Work';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import LockIcon from '@mui/icons-material/Lock';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';





export const DrawerOrganization = ({ id }) => {


    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const drawerWidth = 240;
    const [open, setOpen] = useState(!isMobile);
    const theme = useTheme();
    const [clicked, setClicked] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [profileDrawerOpen, setProfileDrawerOpen] = useState(false);

    // const { talentId } = location.state || {};
    const handleProfileDrawerToggle = () => {
        setProfileDrawerOpen(!profileDrawerOpen); // Toggle the profile drawer
    };

    useEffect(() => {
        setOpen(!isMobile);
    }, [isMobile]);

    const handleError = () => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "This module is under progress",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Reload the window after clicking "OK"
                window.location.reload();
            }
        });
    }


    // const handleLogout = () => {
    //     localStorage.clear();
    //     navigate('/organizatiologin')



    // }

    const handleLogout = () => {
        const organizationRememberedEmail = localStorage.getItem('organizationRememberedEmail');
        const organizationRememberedPassword = localStorage.getItem('organizationRememberedPassword');
        const AdminRememberedEmail = localStorage.getItem('adminRememberedEmail');
        const AdminRememberedPassword = localStorage.getItem('adminRememberedPassword');
        const talentRememberedEmail = localStorage.getItem('talentRememberedEmail');
        const talentRememberedPassword = localStorage.getItem('talentRememberPassword');

        // Clear all localStorage data
        localStorage.clear();

        // Restore the remembered credentials in localStorage
        if (organizationRememberedEmail && organizationRememberedPassword) {
            localStorage.setItem('organizationRememberedEmail', organizationRememberedEmail);
            localStorage.setItem('organizationRememberedPassword', organizationRememberedPassword);

        }

        if (AdminRememberedEmail && AdminRememberedPassword) {
            localStorage.setItem('adminRememberedEmail', AdminRememberedEmail);
            localStorage.setItem('adminRememberedPassword', AdminRememberedPassword);
        }

        if (talentRememberedEmail && talentRememberedPassword) {
            localStorage.setItem('talentRememberedEmail', talentRememberedEmail);
            localStorage.setItem('talentRememberPassword', talentRememberedPassword);
        }
        navigate('/organizationlogin');
    };

    const handleChangePassword = () => {
        navigate('/organizationchangepassword', { state: { id } });
    }






    const handleDrawerOpen = () => {
        setOpen(true); // Open sidebar
    };

    const handleDrawerClose = () => {
        setOpen(false); // Close sidebar
    };

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 10px)`, // Increase width here
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 10px)`, // Adjust for breakpoints
        },
    });



    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#3c1767', // Set background color to white
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));



    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );



    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const LogoContainer = styled('div')({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    });


    // const getPaperStyle = (path) => ({
    //     margin: 10,
    //     backgroundColor: location.pathname === path ? '#e0e0e0' : 'white'
    // });

    // const getPaperStyle = (paths) => ({
    //     margin: 10,
    //     backgroundColor: paths.includes(location.pathname) ? '#E7E1F0' : 'white'
    //     // bgcolor='#f5dbe0'
    // });
    const getPaperStyle = (paths) => ({
        margin: 10,
        backgroundColor: paths.includes(location.pathname) ? '#E7E1F0' : 'white',
        boxShadow: paths.includes(location.pathname) ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : '0px 2px 4px rgba(0, 0, 0, 0.1)', // More pronounced shadow for highlighted
        transition: "box-shadow 0.3s, background-color 0.3s", // Transition for both shadow and background color
        "&:hover": {
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)', // Increase shadow on hover
        },
    });

    const handleProfileClick = () => {
        navigate('/organizationprofile', { state: { id } });
    };

    const handleDashboard = () => {
        navigate('/organizationdashboard', { state: { id } });
    };

    const handleJob = () => {
        navigate('/organizationjobs', { state: { id } });
    };

    const handleTalentProfiles = () => {
        navigate('/talentprofileapply', { state: { id } });
    };

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleAvatarClick = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };




    return (
        <Box>
            <AppBar position='fixed' open={open}>
                <Toolbar>
                    <Grid container xs={12} sm={12} md={12}>
                        <Grid item xs={6} sm={6} md={6}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                            {/* <Tooltip title="logout" placement="bottom">
                                <Avatar onClick={handleLogout} style={{ cursor: 'pointer' }} />
                            </Tooltip> */}
                            <Tooltip title="Logout">
                                <Button
                                    onClick={handleLogout}
                                    startIcon={<LogoutIcon />}
                                    sx={{ color: 'white', textTransform: 'none' }}
                                >
                                    Logout
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            {/* <Drawer
                variant='permanent'
                anchor="right"
                open={profileDrawerOpen}
                onClose={handleProfileDrawerToggle}
            >
                  <DrawerHeader>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'left',
                            px: 2.5,
                            bgcolor: "white",
                            justifyItems: 'center',
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }} />
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <LogoContainer>
                                <img
                                    src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                    alt="Logo"
                                    style={{ width: isMobile ? '80px' : '150px' }}
                                />
                            </LogoContainer>

                            <IconButton onClick={handleDrawerClose} sx={{ alignItems: 'end' }}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </Box>
                    </ListItemButton>
                </DrawerHeader>
                <List>
                    <ListItem button onClick={handleChangePassword}>
                        <ListItemIcon>
                            <LockIcon />
                        </ListItemIcon>
                        <ListItemText primary="Change Password" />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Drawer> */}
            <Grid component={Drawer} variant='permanent' open={open}>
                <DrawerHeader>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'left',
                            px: 2.5,
                            bgcolor: "white",
                            justifyItems: 'center'
                        }}
                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>
                        </ListItemIcon>
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <LogoContainer>
                                <img
                                    src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                    alt="Logo"
                                    style={{ width: isMobile ? '80px' : '150px' }}
                                />
                            </LogoContainer>

                            <IconButton onClick={handleDrawerClose} sx={{ alignItems: 'end' }} >
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </Box>
                    </ListItemButton>
                </DrawerHeader>

                <Divider />
                <List>
                    <Tooltip title="Dashboard" placement="right-start">
                        <Paper elevation={3} style={getPaperStyle('/organizationdashboard')}>
                            <ListItemButton onClick={handleDashboard}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <HomeOutlinedIcon sx={{ color: '#3C2565' }} />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Paper>
                    </Tooltip>

                    <Tooltip title="Profile" placement="right-start">
                        <Paper elevation={3} style={getPaperStyle('/organizationprofile')}>
                            <ListItemButton onClick={handleProfileClick}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <PersonAddIcon sx={{ color: '#3C2565' }} />
                                </ListItemIcon>
                                <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Paper>
                    </Tooltip>
                    <Tooltip title="Jobs" placement="right-start">
                        <Paper elevation={3} style={getPaperStyle(['/organizationjobs', '/editjoborganization', '/jobdetailsorganization'])}>
                            <ListItemButton onClick={handleJob}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <WorkIcon sx={{ color: '#3C2565' }} />
                                </ListItemIcon>
                                <ListItemText primary="Jobs" sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Paper>
                    </Tooltip>

                    <Tooltip title="Talent Profies" placement="right-start">
                        <Paper elevation={3} style={getPaperStyle(['/talentprofileapply', '/persontalentdata'])}>
                            <ListItemButton onClick={handleTalentProfiles}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <SwitchAccountIcon sx={{ color: '#3C2565' }} />
                                </ListItemIcon>
                                <ListItemText primary="Talent Profiles" sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Paper>
                    </Tooltip>
                </List>
                <Divider />
            </Grid>
        </Box>






    )
}

export default DrawerOrganization;

// import { AppBar, Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Toolbar, Tooltip, Typography, Grid, Avatar, Popover, Button } from '@mui/material';
// import React from 'react';
// import MuiDrawer from '@mui/material/Drawer';
// import MenuIcon from '@mui/icons-material/Menu';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import { styled, useTheme } from '@mui/material/styles';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import MuiAppBar from '@mui/material/AppBar';
// import { useState, useEffect } from 'react';
// import Swal from 'sweetalert2';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useLocation, useNavigate } from 'react-router-dom';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import WorkIcon from '@mui/icons-material/Work';

// export const DrawerOrganization = ({ id }) => {
//     const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
//     const drawerWidth = 240;
//     const [open, setOpen] = useState(!isMobile);
//     const theme = useTheme();
//     const navigate = useNavigate();
//     const location = useLocation();

//     useEffect(() => {
//         setOpen(!isMobile);
//     }, [isMobile]);

//     const handleError = () => {
//         Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: "This module is under progress",
//             customClass: {
//                 popup: 'custom-swal'
//             }
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 window.location.reload();
//             }
//         });
//     };

//     const handleLogout = () => {
//         localStorage.clear();
//         navigate('/organizatiologin');
//     };

//     const handleDrawerOpen = () => {
//         setOpen(true); // Open sidebar
//     };

//     const handleDrawerClose = () => {
//         setOpen(false); // Close sidebar
//     };

//     const openedMixin = (theme) => ({
//         width: drawerWidth,
//         transition: theme.transitions.create('width', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//         overflowX: 'hidden',
//     });

//     const closedMixin = (theme) => ({
//         transition: theme.transitions.create('width', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//         }),
//         overflowX: 'hidden',
//         width: `calc(${theme.spacing(7)} + 10px)`,
//         [theme.breakpoints.up('sm')]: {
//             width: `calc(${theme.spacing(8)} + 10px)`,
//         },
//     });

//     const AppBar = styled(MuiAppBar, {
//         shouldForwardProp: (prop) => prop !== 'open',
//     })(({ theme, open }) => ({
//         zIndex: theme.zIndex.drawer + 1,
//         transition: theme.transitions.create(['width', 'margin'], {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//         }),
//         backgroundColor: '#3c1767',
//         ...(open && {
//             marginLeft: drawerWidth,
//             width: `calc(100% - ${drawerWidth}px)`,
//             transition: theme.transitions.create(['width', 'margin'], {
//                 easing: theme.transitions.easing.sharp,
//                 duration: theme.transitions.duration.enteringScreen,
//             }),
//         }),
//     }));

//     const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//         ({ theme, open }) => ({
//             width: drawerWidth,
//             flexShrink: 0,
//             whiteSpace: 'nowrap',
//             boxSizing: 'border-box',
//             ...(open && {
//                 ...openedMixin(theme),
//                 '& .MuiDrawer-paper': openedMixin(theme),
//             }),
//             ...(!open && {
//                 ...closedMixin(theme),
//                 '& .MuiDrawer-paper': closedMixin(theme),
//             }),
//         }),
//     );

//     const DrawerHeader = styled('div')(({ theme }) => ({
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         padding: theme.spacing(0, 1),
//         ...theme.mixins.toolbar,
//     }));

//     const LogoContainer = styled('div')({
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         flex: 1,
//     });

//     const getPaperStyle = (paths) => ({
//         margin: 10,
//         backgroundColor: paths.includes(location.pathname) ? '#e0e0e0' : 'white',
//     });

//     const handleProfileClick = () => {
//         navigate('/organizationprofile', { state: { id } });
//     };

//     const handleDashboard = () => {
//         navigate('/organizationdashboard', { state: { id } });
//     };

//     const handleJob = () => {
//         navigate('/organizationjobs', { state: { id } });
//     };

//     const [anchorEl, setAnchorEl] = useState(null);

//     const handleAvatarClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleClosePopover = () => {
//         setAnchorEl(null);
//     };

//     const openPopover = Boolean(anchorEl);
//     const popoverId = openPopover ? 'avatar-popover' : undefined;

//     return (
//         <Box>
//             <AppBar position="fixed" open={open}>
//                 <Toolbar>
//                     <Grid container xs={12} sm={12} md={12}>
//                         <Grid item xs={6} sm={6} md={6}>
//                             <IconButton
//                                 color="inherit"
//                                 aria-label="open drawer"
//                                 onClick={handleDrawerOpen}
//                                 edge="start"
//                                 sx={{
//                                     ...(open && { display: 'none' }),
//                                 }}
//                             >
//                                 <MenuIcon />
//                             </IconButton>
//                         </Grid>
//                         <Grid item xs={6} sm={6} md={6}>
//                             <Box display="flex" justifyContent="flex-end">
//                                 <Avatar
//                                     sx={{ cursor: 'pointer' }}
//                                     onClick={handleAvatarClick}
//                                 />
//                             </Box>
//                         </Grid>
//                     </Grid>
//                 </Toolbar>
//             </AppBar>
//             <Grid component={Drawer} variant="permanent" open={open}>
//                 <DrawerHeader>
//                     <ListItemButton
//                         sx={{
//                             minHeight: 48,
//                             justifyContent: open ? 'initial' : 'left',
//                             px: 2.5,
//                             bgcolor: "white",
//                             justifyItems: 'center',
//                         }}
//                     >
//                         <ListItemIcon sx={{
//                             minWidth: 0,
//                             mr: open ? 3 : 'auto',
//                             justifyContent: 'center',
//                         }}>
//                         </ListItemIcon>
//                         <Box display='flex' alignItems='center' justifyContent='space-between'>
//                             <LogoContainer>
//                                 <img
//                                     src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
//                                     alt="Logo"
//                                     style={{ width: isMobile ? '80px' : '150px' }}
//                                 />
//                             </LogoContainer>

//                             <IconButton onClick={handleDrawerClose} sx={{ alignItems: 'end' }}>
//                                 {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
//                             </IconButton>
//                         </Box>
//                     </ListItemButton>
//                 </DrawerHeader>

//                 <Divider />
//                 <List>
//                     <Tooltip title="Dashboard" placement="right-start">
//                         <Paper elevation={3} style={getPaperStyle(['/organizationdashboard'])}>
//                             <ListItemButton onClick={handleDashboard}
//                                 sx={{
//                                     minHeight: 48,
//                                     justifyContent: open ? 'initial' : 'center',
//                                     px: 2.5,
//                                 }}
//                             >
//                                 <ListItemIcon sx={{
//                                     minWidth: 0,
//                                     mr: open ? 3 : 'auto',
//                                     justifyContent: 'center',
//                                 }}>
//                                     <HomeOutlinedIcon sx={{ color: '#3C2565' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
//                             </ListItemButton>
//                         </Paper>
//                     </Tooltip>

//                     <Tooltip title="Profile" placement="right-start">
//                         <Paper elevation={3} style={getPaperStyle(['/organizationprofile'])}>
//                             <ListItemButton onClick={handleProfileClick}
//                                 sx={{
//                                     minHeight: 48,
//                                     justifyContent: open ? 'initial' : 'center',
//                                     px: 2.5,
//                                 }}
//                             >
//                                 <ListItemIcon sx={{
//                                     minWidth: 0,
//                                     mr: open ? 3 : 'auto',
//                                     justifyContent: 'center',
//                                 }}>
//                                     <PersonAddIcon sx={{ color: '#3C2565' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0 }} />
//                             </ListItemButton>
//                         </Paper>
//                     </Tooltip>
//                     <Tooltip title="Jobs" placement="right-start">
//                         <Paper elevation={3} style={getPaperStyle(['/organizationjobs'])}>
//                             <ListItemButton onClick={handleJob}
//                                 sx={{
//                                     minHeight: 48,
//                                     justifyContent: open ? 'initial' : 'center',
//                                     px: 2.5,
//                                 }}
//                             >
//                                 <ListItemIcon sx={{
//                                     minWidth: 0,
//                                     mr: open ? 3 : 'auto',
//                                     justifyContent: 'center',
//                                 }}>
//                                     <WorkIcon sx={{ color: '#3C2565' }} />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Jobs" sx={{ opacity: open ? 1 : 0 }} />
//                             </ListItemButton>
//                         </Paper>
//                     </Tooltip>
//                 </List>
//             </Grid>

//             <Popover
//                 id={popoverId}
//                 open={openPopover}
//                 anchorEl={anchorEl}
//                 onClose={handleClosePopover}
//                 anchorOrigin={{
//                     vertical: 'bottom',
//                     horizontal: 'center',
//                 }}
//                 transformOrigin={{
//                     vertical: 'top',
//                     horizontal: 'center',
//                 }}
//             >
//                 <Box p={2}>
//                     <Button onClick={() => { /* Handle Change Password */ }}>Change Password</Button>
//                     <Button onClick={handleLogout}>Logout</Button>
//                 </Box>
//             </Popover>
//         </Box>
//     );
// };


// export default DrawerOrganization;

