
import React from 'react';
import { Box, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Button, Paper, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import TalentDrawer from './TalentDrawer';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import CheckIcon from '@mui/icons-material/Check';
// import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const OrganizationOffers = () => {
  const location = useLocation();
  const { talentId } = location.state || {};
  const [talentData, setTalentData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()

  // useEffect(() => {
  //   // Function to fetch talent data by talentId
  //   const fetchTalentData = async () => {
  //     if (!talentId) return;

  //     try {
  //       const response = await axios.post(`${process.env.REACT_APP_URL}/api/get-talent-data`, { talentId });
  //       console.log(response.data);

  //       if (response.data) {
  //         // Assuming the API returns the array of talent data
  //         setTalentData(response.data); // Set the data in the state
  //       }
  //     } catch (error) {
  //       console.error('Error fetching talent data:', error);
  //     }
  //   };

  //   fetchTalentData(); // Call the function to fetch data
  // }, [talentId]);
  const fetchTalentData = async () => {
    if (!talentId) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/get-talent-data`, { talentId });
      const data = response.data;

      if (data && data.length > 0) {
        setTalentData(data);

        // For each job, fetch the organization details
        const organizationPromises = data.map(job => fetchHiringDetails(job.organizationid));

        // Wait for all organization details to be fetched
        const organizations = await Promise.all(organizationPromises);

        setOrganizationData(organizations);
      }
    } catch (error) {
      console.error('Error fetching talent or organization data:', error);
    }
  };
  useEffect(() => {


    fetchTalentData();
  }, [talentId]);

  console.log(talentId);


  // Function to fetch organization details
  const fetchHiringDetails = async (organizationid) => {
    const userid = organizationid
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/organizationdata/${userid}`);

      return response.data;
    } catch (error) {
      console.error('Error fetching organization details:', error);
      return null; // Handle errors by returning null
    }
  };




  const handleActionClick = (action, offerId) => {
    console.log(`Action: ${action}, Offer ID: ${offerId}`);
    // Handle action (Pending, Accept, Reject) for the given offerId
  };

  // const handleConfirmOffer = (talentid, organizationid) => {
  //   console.log(talentid, organizationid);

  // }

  const handleConfirmOffer = async (talentId, organizationid) => {
    console.log(talentId, organizationid);

    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/invitation/confirm`, {
        talentId,
        organizationid
      });

      if (response.status === 200) {
        console.log("Invitation confirmed successfully:")
        enqueueSnackbar('Invitation confirmed successfully.', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
        await fetchTalentData();

      } else {
        console.log("Failed to confirm invitation:");
        enqueueSnackbar('Failed to confirm invitation.', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      }
    } catch (error) {
      console.error("Error confirming invitation:");
      enqueueSnackbar('Error confirming invitation.', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      })
    }
  };


  const handleRejectOffer = async (talentId, organizationId) => {
    console.log(talentId, organizationId);

    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/invitation/reject`, {
        talentId,
        organizationid: organizationId,
        status: 2  // Set status to 2 for rejection
      });

      if (response.status === 200) {
        console.log("Invitation rejected successfully:", response.data);
        // You can add further logic here, like updating the UI
        enqueueSnackbar('Invitation rejected successfully.', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
        await fetchTalentData();
      } else {
        console.log("Failed to reject invitation:", response.data.message);
        enqueueSnackbar('Failed to reject invitation.', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      }
    } catch (error) {
      console.error("Error rejecting invitation:", error);
      enqueueSnackbar('Error rejecting invitation.', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      })
    }
  };

  const handleDetails = (talentId, organizationid) => {
    navigate('/talent-organizationdetails', { state: { talentId, organizationid } });
  }


  return (
    <Box display='flex'>
      <TalentDrawer talentId={talentId} />
      <Box component="main" sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 5, bgcolor: '#E7E1F0', minHeight: '100vh' }} marginTop={5}>

        <Paper elevation={20} style={{ padding: 20 }}  >
          <Grid container xs={12} md={12} lg={12} justifyContent='space-between' >
            <Typography fontSize='20px' fontWeight={600} mt={2} mb={2}>
              Offer List
            </Typography>
          </Grid>

          {talentData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 16 }}>Sr No</TableCell>
                    <TableCell style={{ fontSize: 16 }}>Organization Name</TableCell>
                    <TableCell style={{ fontSize: 16 }}>Details</TableCell>
                    <TableCell style={{ fontSize: 16, display: 'flex', justifyContent: 'center' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {talentData.map((job, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: 16 }}>{index + 1}</TableCell>
                      <TableCell style={{ fontSize: 16 }}>
                        {organizationData[index]?.companyName || 'Loading...'}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#3C2565', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleDetails(talentId, organizationData[index]?._id)}
                        >
                          <ListIcon sx={{ mr: 1 }} />
                          Details

                        </Button>
                      </TableCell>
                      <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          variant="contained"
                          color='success'
                          sx={{ color: 'white', fontWeight: 'bold', mr: 2 }}
                          onClick={() => handleConfirmOffer(talentId, organizationData[index]?._id)}
                          disabled={(job.status === 1)}
                        >
                          <CheckCircleIcon sx={{ mr: 1 }} />
                          Accept

                        </Button>

                        <Button
                          variant="contained"
                          color='error'
                          sx={{ color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleRejectOffer(talentId, organizationData[index]?._id)}
                          disabled={(job.status === 2)}
                        >
                          <CancelIcon sx={{ mr: 1 }} />
                          Reject

                        </Button>
                      </TableCell>



                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              {/* <CircularProgress /> */}
              <Typography>No Offers available</Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default OrganizationOffers;
