// import * as React from 'react';
// import PropTypes from 'prop-types';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import { createTheme } from '@mui/material/styles';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import WorkIcon from '@mui/icons-material/Work';
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
// import { AppProvider } from '@mui/toolpad';
// import { DashboardLayout } from '@mui/toolpad'; 



// const NAVIGATION = [
//   {
//     segment: 'dashboard',
//     title: 'Dashboard',
//     icon: <DashboardIcon />,
//   },
//   {
//     segment: 'orders',
//     title: 'Orders',
//     icon: <ShoppingCartIcon />,
//   },
//   {
//     segment: 'talentdashboard',
//     title: 'Dashboard',
//     icon: <HomeOutlinedIcon sx={{ color: '#3C2565' }} />,
//   },
//   {
//     segment: 'talentjob',
//     title: 'Job',
//     icon: <WorkIcon sx={{ color: '#3C2565' }} />,
//   },
//   {
//     segment: 'organizationoffer',
//     title: 'Organization Offers',
//     icon: <LocalOfferIcon sx={{ color: '#3C2565' }} />,
//   },
//   {
//     segment: 'talentprofile',
//     title: 'Profile',
//     icon: <PersonAddIcon sx={{ color: '#3C2565' }} />,
//   },
//   {
//     segment: 'talentprofileinformation',
//     title: 'Details',
//     icon: <AccountBoxIcon sx={{ color: '#3C2565' }} />,
//   },
// ];

// const demoTheme = createTheme({
//   cssVariables: {
//     colorSchemeSelector: 'data-toolpad-color-scheme',
//   },
//   colorSchemes: { light: true, dark: true },
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 600,
//       lg: 1200,
//       xl: 1536,
//     },
//   },
// });

// function DemoPageContent({ pathname }) {
//   return (
//     <Box
//       sx={{
//         py: 4,
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         textAlign: 'center',
//       }}
//     >
//       <Typography>Dashboard content for {pathname}</Typography>
//     </Box>
//   );
// }

// DemoPageContent.propTypes = {
//   pathname: PropTypes.string.isRequired,
// };

// function DemoDashboard(props) {
//   const { window } = props;

//   const [pathname, setPathname] = React.useState('/dashboard');

//   const router = React.useMemo(() => {
//     return {
//       pathname,
//       searchParams: new URLSearchParams(),
//       navigate: (path) => setPathname(String(path)),
//     };
//   }, [pathname]);

//   // Remove this const when copying and pasting into your project.
//   const demoWindow = window !== undefined ? window() : undefined;

//   return (
//     // preview-start
//     <AppProvider
//       navigation={NAVIGATION}
//       branding={{
//         logo: <img src="https://mui.com/static/logo.png" alt="MUI logo" />,
//         title: 'MUI',
//       }}
//       router={router}
//       theme={demoTheme}
//       window={demoWindow}
//     >
//       <DashboardLayout>
//         <DemoPageContent pathname={pathname} />
//       </DashboardLayout>
//     </AppProvider>
//     // preview-end
//   );
// }

// DemoDashboard.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * Remove this when copying and pasting into your project.
//    */
//   window: PropTypes.func,
// };

// export default DemoDashboard;
