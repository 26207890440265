import React, { useState, useEffect, useRef } from 'react';
// import { Box, Button, Typography, TextField, useMediaQuery, Checkbox, Select, MenuItem } from '@mui/material';
import Topbar from '../../components/Topbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FlagIcon } from 'react-flag-kit';
import RedoIcon from '@mui/icons-material/Redo';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, TextField, useMediaQuery, Button, Paper } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Swal from 'sweetalert2';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import Basic from '../../Images/Basic.png'
import FormGroup from '@mui/material/FormGroup';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import LinearDeterminate from './LinearDeterminate';


const steps = [
    'What should we call you?',
    'Mobile verification',
    'Verification code',
    'Job position',
    'Intrest',
    'Experience',
    'JoiningDate',
    'WorkLocation',
    'expertise',
    'Certification',
    'mentor',
    'matches',
    'Review'

];

// const countryCodes = [
//     { code: '+1', country: 'US', flag: 'US' },
//     { code: '+44', country: 'UK', flag: 'GB' },
//     { code: '+91', country: 'IN', flag: 'IN' },
//     { code: '+61', country: 'Australia', flag: 'AU' },
//     // { code: '+1', country: 'Canada', flag: 'CA' },
//     { code: '+81', country: 'Japan', flag: 'JP' },
//     { code: '+49', country: 'Germany', flag: 'DE' },
//     { code: '+33', country: 'France', flag: 'FR' },
//     { code: '+39', country: 'Italy', flag: 'IT' },
//     { code: '+86', country: 'China', flag: 'CN' },
//     { code: '+34', country: 'Spain', flag: 'ES' },
//     { code: '+55', country: 'Brazil', flag: 'BR' },
//     { code: '+7', country: 'Russia', flag: 'RU' },
//     { code: '+27', country: 'South Africa', flag: 'ZA' },
//     { code: '+82', country: 'South Korea', flag: 'KR' },
//     { code: '+46', country: 'Sweden', flag: 'SE' },
//     { code: '+41', country: 'Switzerland', flag: 'CH' },
//     { code: '+31', country: 'Netherlands', flag: 'NL' },
//     { code: '+32', country: 'Belgium', flag: 'BE' },
//     { code: '+65', country: 'Singapore', flag: 'SG' },
//     { code: '+60', country: 'Malaysia', flag: 'MY' },
//     { code: '+63', country: 'Philippines', flag: 'PH' },
//     { code: '+66', country: 'Thailand', flag: 'TH' },
//     { code: '+62', country: 'Indonesia', flag: 'ID' },
//     { code: '+20', country: 'Egypt', flag: 'EG' },
//     { code: '+964', country: 'Iraq', flag: 'IQ' },
//     { code: '+98', country: 'Iran', flag: 'IR' },
//     { code: '+353', country: 'Ireland', flag: 'IE' },
//     { code: '+92', country: 'Pakistan', flag: 'PK' },
//     { code: '+977', country: 'Nepal', flag: 'NP' },
//     { code: '+94', country: 'Sri Lanka', flag: 'LK' },
//     { code: '+880', country: 'Bangladesh', flag: 'BD' },
//     { code: '+971', country: 'United Arab Emirates', flag: 'AE' },
//     { code: '+212', country: 'Morocco', flag: 'MA' },
//     { code: '+54', country: 'Argentina', flag: 'AR' },
//     { code: '+56', country: 'Chile', flag: 'CL' },
//     { code: '+52', country: 'Mexico', flag: 'MX' },
//     { code: '+505', country: 'Nicaragua', flag: 'NI' },
//     { code: '+507', country: 'Panama', flag: 'PA' },
//     { code: '+51', country: 'Peru', flag: 'PE' },
//     { code: '+598', country: 'Uruguay', flag: 'UY' },
//     { code: '+58', country: 'Venezuela', flag: 'VE' },
//     { code: '+48', country: 'Poland', flag: 'PL' },
//     { code: '+420', country: 'Czech Republic', flag: 'CZ' },
//     { code: '+358', country: 'Finland', flag: 'FI' },
//     { code: '+30', country: 'Greece', flag: 'GR' },
//     { code: '+36', country: 'Hungary', flag: 'HU' },
//     { code: '+351', country: 'Portugal', flag: 'PT' },
//     { code: '+40', country: 'Romania', flag: 'RO' },
//     { code: '+380', country: 'Ukraine', flag: 'UA' },
//     { code: '+972', country: 'Israel', flag: 'IL' },
//     { code: '+90', country: 'Turkey', flag: 'TR' },
//     { code: '+966', country: 'Saudi Arabia', flag: 'SA' },
//     { code: '+354', country: 'Iceland', flag: 'IS' },
//     { code: '+376', country: 'Andorra', flag: 'AD' },
//     { code: '+374', country: 'Armenia', flag: 'AM' },
//     { code: '+994', country: 'Azerbaijan', flag: 'AZ' },
//     { code: '+375', country: 'Belarus', flag: 'BY' },
//     { code: '+387', country: 'Bosnia and Herzegovina', flag: 'BA' },
//     { code: '+359', country: 'Bulgaria', flag: 'BG' },
//     { code: '+57', country: 'Colombia', flag: 'CO' },
//     { code: '+357', country: 'Cyprus', flag: 'CY' },
//     { code: '+420', country: 'Czech Republic', flag: 'CZ' },
//     { code: '+372', country: 'Estonia', flag: 'EE' },
//     { code: '+298', country: 'Faroe Islands', flag: 'FO' },
//     { code: '+995', country: 'Georgia', flag: 'GE' },
//     { code: '+36', country: 'Hungary', flag: 'HU' },
//     { code: '+354', country: 'Iceland', flag: 'IS' },
//     { code: '+353', country: 'Ireland', flag: 'IE' },
//     { code: '+39', country: 'Italy', flag: 'IT' },
//     { code: '+371', country: 'Latvia', flag: 'LV' },
//     { code: '+423', country: 'Liechtenstein', flag: 'LI' },
//     { code: '+370', country: 'Lithuania', flag: 'LT' },
//     { code: '+352', country: 'Luxembourg', flag: 'LU' },
//     { code: '+389', country: 'North Macedonia', flag: 'MK' },
//     { code: '+356', country: 'Malta', flag: 'MT' },
//     { code: '+373', country: 'Moldova', flag: 'MD' },
//     { code: '+377', country: 'Monaco', flag: 'MC' },
//     { code: '+382', country: 'Montenegro', flag: 'ME' },
//     { code: '+31', country: 'Netherlands', flag: 'NL' },
//     { code: '+47', country: 'Norway', flag: 'NO' },
//     { code: '+48', country: 'Poland', flag: 'PL' },
//     { code: '+351', country: 'Portugal', flag: 'PT' },
//     { code: '+40', country: 'Romania', flag: 'RO' },
//     { code: '+7', country: 'Russia', flag: 'RU' },
//     { code: '+381', country: 'Serbia', flag: 'RS' },
//     { code: '+421', country: 'Slovakia', flag: 'SK' },
//     { code: '+386', country: 'Slovenia', flag: 'SI' },
//     { code: '+34', country: 'Spain', flag: 'ES' },
//     { code: '+46', country: 'Sweden', flag: 'SE' },
//     { code: '+41', country: 'Switzerland', flag: 'CH' },
//     { code: '+380', country: 'Ukraine', flag: 'UA' },
//     { code: '+44', country: 'United Kingdom', flag: 'GB' },
// ];


const countryCodes = [
    { code: '+1', country: 'US', flag: 'US' },
    { code: '+7', country: 'Russia', flag: 'RU' },
    { code: '+20', country: 'Egypt', flag: 'EG' },
    { code: '+27', country: 'South Africa', flag: 'ZA' },
    { code: '+30', country: 'Greece', flag: 'GR' },
    { code: '+31', country: 'Netherlands', flag: 'NL' },
    { code: '+32', country: 'Belgium', flag: 'BE' },
    { code: '+33', country: 'France', flag: 'FR' },
    { code: '+34', country: 'Spain', flag: 'ES' },
    { code: '+36', country: 'Hungary', flag: 'HU' },
    { code: '+39', country: 'Italy', flag: 'IT' },
    { code: '+40', country: 'Romania', flag: 'RO' },
    { code: '+44', country: 'UK', flag: 'GB' },
    { code: '+46', country: 'Sweden', flag: 'SE' },
    { code: '+48', country: 'Poland', flag: 'PL' },
    { code: '+49', country: 'Germany', flag: 'DE' },
    { code: '+51', country: 'Peru', flag: 'PE' },
    { code: '+52', country: 'Mexico', flag: 'MX' },
    { code: '+54', country: 'Argentina', flag: 'AR' },
    { code: '+55', country: 'Brazil', flag: 'BR' },
    { code: '+56', country: 'Chile', flag: 'CL' },
    { code: '+58', country: 'Venezuela', flag: 'VE' },
    { code: '+60', country: 'Malaysia', flag: 'MY' },
    { code: '+62', country: 'Indonesia', flag: 'ID' },
    { code: '+63', country: 'Philippines', flag: 'PH' },
    { code: '+65', country: 'Singapore', flag: 'SG' },
    { code: '+66', country: 'Thailand', flag: 'TH' },
    { code: '+81', country: 'Japan', flag: 'JP' },
    { code: '+82', country: 'South Korea', flag: 'KR' },
    { code: '+86', country: 'China', flag: 'CN' },
    { code: '+91', country: 'India', flag: 'IN' },
    { code: '+92', country: 'Pakistan', flag: 'PK' },
    { code: '+94', country: 'Sri Lanka', flag: 'LK' },
    { code: '+98', country: 'Iran', flag: 'IR' },
    { code: '+212', country: 'Morocco', flag: 'MA' },
    { code: '+351', country: 'Portugal', flag: 'PT' },
    { code: '+353', country: 'Ireland', flag: 'IE' },
    { code: '+354', country: 'Iceland', flag: 'IS' },
    { code: '+357', country: 'Cyprus', flag: 'CY' },
    { code: '+358', country: 'Finland', flag: 'FI' },
    { code: '+372', country: 'Estonia', flag: 'EE' },
    { code: '+375', country: 'Belarus', flag: 'BY' },
    { code: '+376', country: 'Andorra', flag: 'AD' },
    { code: '+377', country: 'Monaco', flag: 'MC' },
    { code: '+380', country: 'Ukraine', flag: 'UA' },
    { code: '+381', country: 'Serbia', flag: 'RS' },
    { code: '+386', country: 'Slovenia', flag: 'SI' },
    { code: '+387', country: 'Bosnia and Herzegovina', flag: 'BA' },
    { code: '+420', country: 'Czech Republic', flag: 'CZ' },
    { code: '+421', country: 'Slovakia', flag: 'SK' },
    { code: '+423', country: 'Liechtenstein', flag: 'LI' },
    { code: '+505', country: 'Nicaragua', flag: 'NI' },
    { code: '+507', country: 'Panama', flag: 'PA' },
    { code: '+598', country: 'Uruguay', flag: 'UY' },
    { code: '+880', country: 'Bangladesh', flag: 'BD' },
    { code: '+964', country: 'Iraq', flag: 'IQ' },
    { code: '+971', country: 'United Arab Emirates', flag: 'AE' },
    { code: '+972', country: 'Israel', flag: 'IL' },
    { code: '+977', country: 'Nepal', flag: 'NP' },
    { code: '+994', country: 'Azerbaijan', flag: 'AZ' },
    { code: '+995', country: 'Georgia', flag: 'GE' },
];

const usStates = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii',
    'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];





const educationCourses = [
    'Bachelor of Arts in Education (BA)',
    'Bachelor of Science in Education (BSEd)',
    'Elementary Education',
    'Secondary Education',
    'Special Education',
    'Early Childhood Education',
    'Physical Education',
    'Educational Technology',
    'Mathematics Education',
    'Science Education',
    'Master of Education (MEd)',
    'Master of Arts in Teaching (MAT)',
    'Master of Science in Education (MSEd)',
    'Master of Arts in Education (MAEd)',
    'Educational Leadership',
    'Curriculum and Instruction',
    'Educational Administration',
    'School Counseling',
    'Reading and Literacy',
    'Doctor of Education (EdD)',
    'Doctor of Philosophy in Education (PhD)',
    'Doctor of Education in Educational Leadership (EdD)',
    'Doctor of Education in Curriculum and Instruction (EdD)',
    'Doctor of Philosophy in Educational Psychology (PhD)',
    'Doctor of Philosophy in Higher Education Administration (PhD)',
    'Doctor of Education in Organizational Leadership (EdD)',
    'Teaching English as a Second Language (TESOL)',
    'Bilingual Education',
    'Instructional Design',
    'Gifted and Talented Education',
    'Special Needs Education',
    'School Principal Certification',
    'Superintendent Certification',
    'Educational Policy and Leadership',
    'Inclusive Education'
];











const childandMaternalHealthOptions = ['At-risk children', 'At-risk mothers', ' Pregnant/postpartum individuals'];
const communityRepresentationOptions = ['American Indian', 'Alaskan Native', 'Asian', ' Black or African American', 'Hispanic or Latino', 'Native Hawaiian or Other Pacific Islander', 'Rural residents']
const accessibilityandEquityOptions = ['Disability community', 'Economically disadvantaged', ' Farm workers', 'Individuals with limited English proficiency or literacy']
const healthandWellnessOptions = ['Individuals with chronic health conditions', 'Individuals with mental illness']
const socialJusticeandInclusionOptions = ['Justice-involved individuals', ' LGBTQIA+ community', 'Older adults (over 65 years of age)', 'People uninsured or underinsured']
const youthServicesOptions = ['At-risk youth', 'Youth mental health'];
const healthcareOptions = ['CHW (Community Health Worker)', 'HHA (Home Health Aide)', 'CNA (Certified Nursing Assistant)', 'CHES (Certified Health Education Specialist)'];
const specializedCareCertificationsOptions = [' CD (Certified Doula) [DONA]', 'CLD (Certified Lactation Doula)', 'ICBD (International Certified Birth Doula)', 'CHPNA (Certified Hospice and Palliative Nursing Assistant)'];
const mentalHealthandPeerSupportOptions = ['NCPS (National Certified Peer Specialist)', 'CPSS (Certified Peer Support Specialist)', 'NBC-HWC (National Board Certified Health & Wellness Coach)', 'CHWC (Certified Health and Wellness Coach)'];
const otherOptions = ['Not certified ', 'Other']


const OnBoardingNew = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [isFirstFocused, setIsFirstFocused] = useState(false);
    const [isLastFocused, setIsLastFocused] = useState(false);
    const totalSteps = 13;
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [isMobileFocused, setIsMobileFocused] = useState(false);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');


    const [errors, setErrors] = useState({});
    const [selectedCourse, setSelectedCourse] = useState('');
    const [cities, setCities] = useState([]);
    const [citiesFound, setCitiesFound] = useState(true);
    const [otp, setOtp] = useState('');
    const [userOtp, setUserOtp] = useState(Array(6).fill(''));
    const [userEmailOtp, setUserEmailOtp] = useState(Array(6).fill(''));
    const otpContainerRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [otpEmail, setOtpEmail] = useState('');
    const [otpExpiryTimer, setOtpExpiryTimer] = useState(null);
    const [otpEmailExpiryTimer, setOtpEmailExpiryTimer] = useState(null);
    const [otpExpiryTime, setOtpExpiryTime] = useState(null);
    const [otpEmailExpiryTime, setOtpEmailExpiryTime] = useState(null);
    const [countdown, setCountdown] = useState(60);
    const [startCountdown, setStartCountdown] = useState(false);
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const newProgress = ((activeStep + 1) / totalSteps) * 100;
        setProgress(newProgress);
    }, [activeStep, totalSteps]);



    // const requiredFields = {
    //     0: ['firstName', 'lastName'],
    //     1: ['countryCode', 'mobileNumber', 'mobileVerification'],
    //     2: [],
    //     3: [],
    //     4: [],
    //     5: [],
    //     6: ['city', 'jobState'],
    //     7: [],
    //     8: ['certification.state'],
    //     9: [],
    //     10: [],
    //     11: [],


    // }

    const requiredFields = {
        0: ['firstName', 'lastName'],
        1: ['countryCode', 'mobileNumber', 'mobileVerification'],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: ['city', 'jobState'],
        8: [],
        9: ['certification.state'],
        10: [],
        11: [],
        12: []


    }



    const validateFields = () => {
        const newErrors = {};
        const fields = requiredFields[activeStep];
        fields.forEach((field) => {
            // Split field by dot notation to handle nested fields
            const keys = field.split('.');
            let value = formData;

            // Traverse the nested structure
            for (const key of keys) {
                value = value[key];
                if (value === undefined) {
                    break;
                }
            }

            if (!value) {
                newErrors[field] = `${field} is required`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };




    useEffect(() => {
        // console.log("Effect triggered: startCountdown =", startCountdown, "countdown =", countdown);
        if (startCountdown && countdown > 0) {
            const timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 0) {
                        clearInterval(timer);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [startCountdown, countdown]);



    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        countryCode: countryCodes[0].code,
        mobileNumber: '',
        mobileVerification: false,
        userEmail: '',
        jobPosition: [],
        intrest: '',
        experience: '',
        joiningDate: '',
        specificDate: '',
        city: '',
        jobState: '',
        expertise: {
            childAndMaternalHealth: [],
            communityRepresentation: [],
            accessibilityAndEquity: [],
            healthAndWellness: [],
            socialJusticeAndInclusion: [],
            youthServices: [],
        },
        certification: {
            healthcare: [],
            specializedCareCertifications: [],
            mentalHealthandPeerSupport: [],
            otherOptions: '',
            state: '',

        },

        mentor: '',
        matches: '',
        training: ''

    });

    const handleFieldError = () => {
        Swal.fire({
            title: "Incomplete Form",
            text: "Please fill out all required fields before submitting.",
            icon: "warning",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        });
    };

    const handleError = () => {
        Swal.fire({
            title: "Error",
            text: "There was an error processing your request. Please try again.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        });
    };


    const handleUserExist = () => {
        Swal.fire({
            title: "Error",
            text: "Data with mobile number is already present!!",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {

                // window.location.reload();


            }
        });
    };



    const addUserData = async () => {
        console.log("in the data submission 602", formData);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/onboardingdata`, {
                firstName: formData.firstName,
                lastName: formData.lastName,
                countryCode: formData.countryCode,
                mobileNumber: formData.mobileNumber,
                mobileVerification: formData.mobileVerification,
                userEmail: formData.userEmail,
                jobPosition: formData.jobPosition,
                intrest: formData.intrest,
                experience: formData.experience,
                joiningDate: formData.joiningDate,
                specificDate: formData.specificDate,
                city: formData.city,
                jobState: formData.jobState,
                expertise: formData.expertise,
                certification: formData.certification,
                mentor: formData.mentor,
                matches: formData.matches,
                training: formData.training
            });
            console.log(response);

            if (response.data === 'present') {
                console.log('User is already present');
                handleUserExist()
            } else if (response.data === 'new') {
                console.log('User data added successfully', response);
                handleSucces();
            } else {
                handleError();
            }
        } catch (error) {
            console.error('Error adding user data:', error);
            handleError();
        }
    };




    const handleSubmit = (event) => {
        event.preventDefault();



        if (!formData.firstName || !formData.lastName || !formData.city || !formData.jobState || !formData.certification.state) {
            handleFieldError();
            return;
        }
        else {
            console.log("Form Data Submitted:", formData);
            addUserData();
        }


    };


    const renderExpertiseValues = (expertiseCategory) => {
        return expertiseCategory.length > 0 ? expertiseCategory.join(', ') : 'None';
    };

    const renderCertificationValues = (certificationCategory) => {
        return certificationCategory.length > 0 ? certificationCategory.join(', ') : 'None';
    };

    const getSelectedJobPositions = (jobPosition) => {
        return Object.keys(jobPosition)
            .filter((key) => jobPosition[key])
            .map((key) => key.replace(/([A-Z])/g, ' $1').toLowerCase()) // Convert camelCase to regular text
            .join(', ');
    };







    const handleJoiningDateChange = (event) => {
        setFormData({
            ...formData,
            joiningDate: event.target.value,
            specificDate: event.target.value !== 'Specific date' ? '' : formData.specificDate
        });
    };






    // const handleMentorChange = (value) => {
    //     setFormData({ ...formData, mentor: value });
    // };


    const handleMentorChange = (event) => {
        const value = event.target.value;

        // Update the formData object
        const updatedFormData = { ...formData, mentor: value };

        // Now you can use updatedFormData as per your requirement
        // For example, you can send it via AJAX or do something else
        console.log(updatedFormData);

        // Assuming you also need to update your state with the new formData
        setFormData(updatedFormData);
    };



    const handleMatchesChange = (value) => {
        setFormData({ ...formData, matches: value });
    };


    const handleCertificationChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            certification: {
                ...prevFormData.certification,
                [name]: value,
                specifyOption: name === "otherOptions" && value !== "Please Specify" ? '' : prevFormData.certification.specifyOption
            },
        }));

        if (errors['certification.' + name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                ['certification.' + name]: ''
            }));
        }

       
    };

    const handleOtherChange = (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            certification: {
                ...formData.certification,
                otherOptions: value
            }
        });
    };


    const handleSucces = () => {
        Swal.fire({
            title: "Thank you for joining.",
            text: "We will be in touch soon",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: '#FEE4CC',
            // cancelButtonColor: '#F57C20',
            confirmButtonText: "Check your profile",
            // cancelButtonText: "Home",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {

                window.location.reload();


            }
        });
    };





    // const handleCourseChange = (event) => {
    //     setFormData({
    //         ...formData,
    //         trainingCourse: event.target.value,
    //     });
    // };
    const handleCourseChange = (event) => {
        const selectedCourse = event.target.value;
        setSelectedValue(selectedCourse);
        setFormData({
            ...formData,
            training: selectedCourse
        });
    };

    const handleInterestChange = (event) => {
        setFormData({
            ...formData,
            intrest: event.target.value,
        });
    };

    const handleExperienceChange = (event) => {
        setFormData({
            ...formData,
            experience: event.target.value,
        });
    };





    const handleMobileError = () => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong! Please check your mobile number",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                // window.location.reload();

            }
        });
    };



    const handleCheckboxChange = async (event) => {
        const { name, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));

        // if (checked) {
        //     if (formData.userEmail && formData.countryCode && formData.mobileNumber) {
        //         await sendVerificationCode();
        //         await handleCheckEmail()
        //     } else if (formData.countryCode && formData.mobileNumber) {
        //         await sendVerificationCode();
        //     }
        // }

    };





    const sendVerificationCode = async () => {
        const phoneNumber = `${formData.countryCode}${formData.mobileNumber}`;
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/sendverificationcode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ toNumber: phoneNumber }),
            });

            if (response.ok) {
                const data = await response.json();
                setOtp(data.otp);

                // Clear any existing OTP expiry timer
                if (otpExpiryTimer) {
                    clearTimeout(otpExpiryTimer);
                }

                // Set a new timer to invalidate the OTP after 15 minutes
                const timer = setTimeout(() => {
                    setOtp(null);
                }, 15 * 60 * 1000); // 15 minutes in milliseconds

                setOtpExpiryTimer(timer);
                setOtpExpiryTime(Date.now() + 15 * 60 * 1000);
                setStartCountdown(true);

                if (formData.userEmail) {
                    console.log("790");
                    handleCheckEmail()
                }

                console.log("793");
                handleNext()




                // return data.otp;
            } else {
                console.error('742 Failed to send verification code');
                setLoading(false);
                handleMobileError();
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            handleError();
        }
    };

    const handleCheckEmail = async () => {

        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/emailotpsend`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: formData.userEmail }),
            });
            const result = await response.json();
            setOtpEmail(result.combineData.otp);


            if (otpEmailExpiryTimer) {
                clearTimeout(otpEmailExpiryTimer);
            }

            // Set a new timer to invalidate the OTP after 15 minutes
            const timer = setTimeout(() => {
                setOtpEmail(null);
            }, 15 * 60 * 1000); // 15 minutes in milliseconds

            setOtpEmailExpiryTimer(timer);
            setOtpEmailExpiryTime(Date.now() + 15 * 60 * 1000);
            setStartCountdown(true);
            handleNext()


            
         
        } catch (error) {
            console.error("Error To send OTP:", error);
            setLoading(false);
            handleError();
        }
    };

    const [emailError, setEmailError] = useState('');

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e) => {
        const { value } = e.target;

        if (!validateEmail(value)) {
            setEmailError('Invalid email address');
        } else {
            setEmailError('');
        }
        handleInputChange(e);
    };







    const handleNextWithValidation = async () => {
        const currentTime = Date.now();

        if (emailError) {
            console.log("Invalid email address");
            return;
        }

        if (steps[activeStep] === 'Verification code') {
            console.log(combinedEmailOtp, otp, combinedOtp, otpEmail);
            if (!formData.userEmail) {
                if (!combinedOtp) {
                    handleEnterOTP();
                } else if (combinedOtp) {
                    if (combinedOtp === otp && currentTime < otpExpiryTime && validateFields()) {
                        console.log("successful");
                        handleNext();
                    } else {
                        console.log("OTP does not match or has expired");
                        handleWrongOTP();
                    }
                } else {
                    console.log("OTP does not match or has expired");
                    handleWrongOTP();
                }
            } else {
                console.log(combinedEmailOtp, otp, combinedOtp, otpEmail);
                if (!combinedOtp || !combinedEmailOtp) {
                    handleEnterOTP();
                } else if (combinedOtp && combinedEmailOtp) {
                    if (combinedOtp === otp && combinedEmailOtp === otpEmail && currentTime < otpExpiryTime && currentTime < otpEmailExpiryTime && validateFields()) {
                        console.log("successful");
                        handleNext();
                    } else {
                        console.log("OTP does not match or has expired");
                        handleWrongOTP();
                    }
                }
            }
        } else if (steps[activeStep] === 'Mobile verification') {
            try {
                if (validateFields()) {
                    const response = await axios.post(`${process.env.REACT_APP_URL}/api/check-talent`, formData);
                    console.log(response);
                    if (response.data === 'present') {
                        console.log('User is already present');
                        handleUserExist();
                    } else if (response.data === 'new') {
                        console.log('User data added successfully', response);
                        if (!formData.userEmail && otp) {
                            console.log("929");
                            setLoading(false);
                            handleNext()
                        } else if (formData.userEmail && otp && otpEmail) {
                            console.log("984");
                            setLoading(false);
                            handleNext()
                        }
                        else if (formData.userEmail && formData.countryCode && formData.mobileNumber && validateFields()) {
                            console.log("988");
                            await sendVerificationCode();
                            console.log("991");
                            setLoading(false);
                            // handleNext()
                            console.log("995");

                        } else if (formData.countryCode && formData.mobileNumber && validateFields() && !otp) {

                            await sendVerificationCode();
                            setLoading(false);
                            // handleNext()
                        }
                    } else {
                        handleError();
                    }
                }
            } catch (err) {
                handleError();
            }
        } else if (validateFields()) {
            handleNext();
        }
    };

    const onResend = () => {
        setCountdown(60);
        setStartCountdown(true);
        sendVerificationCode()
        handleCheckEmail();

    }






    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }





    const [resendDisabled, setResendDisabled] = useState(false);
    const [timer, setTimer] = useState(60);

    useEffect(() => {
        let timerInterval;
        if (timer > 0 && resendDisabled) {
            timerInterval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0 && resendDisabled) {
            setResendDisabled(false);
            clearInterval(timerInterval);
        }
        return () => clearInterval(timerInterval);
    }, [timer, resendDisabled]);




    const combinedOtp = userOtp.join('');




    const handleOtpInputChange = (index, value) => {
        // Ensure the input value is a number and within the range of 0-9
        const sanitizedValue = value.match(/^[0-9]$/) ? value : '';

        // Update the userOtp array
        setUserOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = sanitizedValue;
            return newOtp;
        });

        // Move focus to the next OTP input field if the current input is valid and not the last digit
        if (sanitizedValue !== '' && index < 5) {
            const nextInput = document.getElementById(`otpInput-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleEmailOtpInputChange = (index, value) => {
        // Ensure the input value is a number and within the range of 0-9
        const sanitizedValue = value.match(/^[0-9]$/) ? value : '';

        // Update the userEmailOtp array
        setUserEmailOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = sanitizedValue;
            return newOtp;
        });

        // Move focus to the next OTP input field if the current input is valid and not the last digit
        if (sanitizedValue !== '' && index < 5) {
            const nextInput = document.getElementById(`emailOtpInput-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const combinedEmailOtp = userEmailOtp.join('');

    const handleWrongOTP = () => {
        Swal.fire({
            title: "Error",
            text: "Wrong Verification Code or code expires ",
            icon: "warning",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        })
    }


    const handleEnterOTP = () => {
        Swal.fire({
            title: "Error",
            text: "Enter verification code!",
            icon: "warning",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        })
    }




    useEffect(() => {
        const handleClickOutside = (event) => {
            if (otpContainerRef.current && !otpContainerRef.current.contains(event.target)) {
                handleWrongOTP();
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);


    // const handleJoiningDateChange = (event) => {
    //     const value = event.target.value;
    //     setFormData({
    //         ...formData,
    //         joiningDate: value,
    //     });
    // };

    const handleExpertiseChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            expertise: {
                ...prevFormData.expertise,
                [name]: value,
            },
        }));
    };


    const handleJobChange = (event) => {
        const { value } = event.target;
        const updatedJobPosition = [...formData.jobPosition]; // Create a copy of the current jobPosition array

        if (updatedJobPosition.includes(value)) {
            // If the selected value is already in the array, remove it
            updatedJobPosition.splice(updatedJobPosition.indexOf(value), 1);
        } else {
            // Otherwise, add it to the array
            updatedJobPosition.push(value);
        }

        setFormData({
            ...formData,
            jobPosition: updatedJobPosition // Update the jobPosition array in formData
        });
    };

    const handleNameChange = (e) => {
        const { name, value } = e.target;
        const lettersOnly = value.replace(/[^a-zA-Z]/g, '');
        console.log(lettersOnly);
        setFormData({ ...formData, [name]: lettersOnly });
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    };

    const handleDateChange = (date) => {
        if (date) {
            // Date is valid
            console.log(date);
            setFormData({ ...formData, specificDate: date.format('DD MMMM YYYY') });
        } else {
            // Date is null (cancel was clicked)
            setFormData({ ...formData, specificDate: '' }); // Or any default value you prefer
        }
    };


    const defaultDate = formData.specificDate ? dayjs(formData.specificDate) : null;
    const minDate = dayjs();




    useEffect(() => {
        if (steps[activeStep] === 'WorkLocation') {
            const data = {
                country: 'United States',
                state: formData.jobState,
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', data, config)
                .then((response) => {
                    console.log(response.data);
                    setCities(response.data.data);
                    setCitiesFound(true);
                })
                .catch((error) => {
                    console.error(error);
                    setCities([]);
                    setCitiesFound(false);
                });
        }
    }, [activeStep, formData.jobState]);










    return (

        <>
            <Box>
                <Topbar />
            </Box>
            <Box sx={{
                bgcolor: '#E7E1F0', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: steps[activeStep] === 'Review' || steps[activeStep] === 'expertise' || steps[activeStep] === 'Certification' || steps[activeStep] === 'Verification code' ? 'auto' : '100vh', mt: isMobile ? 0 : 5, pb: steps[activeStep] === 'Verification code' ? 20 : 20
            }} >

                {/* <Box  width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection='column'> */}
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                    {steps[activeStep] === 'What should we call you?' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }}>
                                {/* <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    {`QUESTION ${activeStep + 1} OF ${totalSteps - 1}`}
                                </Typography> */}
                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {'0'}{activeStep + 1}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '24px', fontWeight: 500 }}>
                                    Hello! What should we call you?
                                </Typography>
                                <TextField
                                    required
                                    fullWidth

                                    label="First Name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    onFocus={() => setIsFirstFocused(true)}
                                    onBlur={() => setIsFirstFocused(false)}
                                    error={!!errors.firstName}
                                    // helperText={errors.firstName ? 'First Name is required' : ''}
                                    onKeyPress={(e) => {
                                        if (/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}

                                    sx={{
                                        mb: 2,
                                        bgcolor: 'white',
                                        borderRadius: '15px',
                                        '& .MuiInputLabel-asterisk': {
                                            color: 'red',
                                            fontSize: '20px'
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: isFirstFocused || formData.firstName ? 'transparent' : '#8C8C8C',
                                            fontSize: isFirstFocused || formData.firstName ? '0' : '18px',
                                            transition: 'all 0.3s ease',



                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                            backgroundColor: 'white',
                                            borderRadius: '15px', // Ensure border radius is applied

                                            fontSize: isMobile ? '16px' : '18px'
                                        }
                                    }}
                                />


                                {errors.firstName && (
                                    <Typography variant="body2" color="error" mb={2} mt={-1}>
                                        First Name is required
                                    </Typography>

                                )}

                                <TextField
                                    required
                                    fullWidth
                                    label="Last Name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    onFocus={() => setIsLastFocused(true)}
                                    onBlur={() => setIsLastFocused(false)}
                                    error={!!errors.lastName}
                                    onKeyPress={(e) => {
                                        if (/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}

                                    sx={{ mb: 2, bgcolor: 'white', borderRadius: '15px' }}
                                    InputLabelProps={{
                                        sx: {
                                            color: isLastFocused || formData.lastName ? 'transparent' : '#8C8C8C',
                                            fontSize: isLastFocused || formData.lastName ? '0' : '18px',
                                            transition: 'all 0.3s ease',



                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                            backgroundColor: 'white',
                                            borderRadius: '15px',
                                            fontSize: isMobile ? '16px' : '18px'
                                        }
                                    }}
                                />

                                {errors.lastName && (
                                    <Typography variant="body2" color="error" mb={2} mt={-1}>
                                        Last Name is required
                                    </Typography>
                                )}
                            </Box>

                        </>
                    )
                    }


                    {steps[activeStep] === 'Mobile verification' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }} mt={isMobile ? 8 : 2}>
                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {'0'}{activeStep + 1}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 4, fontSize: isMobile ? '20px' : '25px', fontWeight: 400 }}>
                                    Awesome! Let's begin with your mobile number which will be served as a Platform ID.
                                </Typography>
                                <Box display="flex" alignItems="center" sx={{ mb: 2 }} justifyContent='center'>
                                    <Select
                                        name="countryCode"
                                        value={formData.countryCode}
                                        onChange={handleInputChange}
                                        displayEmpty
                                        defaultValue={+1}
                                        error={!!errors.countryCode}
                                        inputProps={{ 'aria-label': 'Country code' }}
                                        sx={{ bgcolor: 'white', borderRadius: '15px' }}

                                    >
                                        {countryCodes.map((country) => (
                                            <MenuItem key={country.code} value={country.code} >
                                                <Box display="flex" alignItems="center">
                                                    <FlagIcon code={country.flag} size={27} style={{ marginRight: 8 }} />
                                                    {country.code}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>



                                    <TextField
                                        required
                                        fullWidth
                                        label="Mobile number"
                                        name="mobileNumber"
                                        value={formData.mobileNumber}
                                        onChange={handleInputChange}

                                        onFocus={() => setIsFirstFocused(true)}
                                        onBlur={() => setIsFirstFocused(false)}
                                        error={!!errors.mobileNumber}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}

                                        // sx={{ bgcolor: 'white', borderRadius: '15px', ml: 2 }}
                                        sx={{
                                            ml: 2,
                                            bgcolor: 'white',
                                            borderRadius: '15px',
                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            },

                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: isFirstFocused || formData.mobileNumber ? 'transparent' : '#8C8C8C',
                                                fontSize: isFirstFocused || formData.mobileNumber ? '0' : '18px',
                                                transition: 'all 0.3s ease',




                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                },
                                                fontSize: isMobile ? '16px' : '18px',
                                                fontFamily: 'Times New Roman, serif',
                                                borderRadius: '15px',

                                            }
                                        }}
                                    />

                                </Box>

                                <TextField

                                    fullWidth
                                    label="Email"
                                    name="userEmail"
                                    value={formData.userEmail}
                                    onChange={handleEmailChange}
                                    onFocus={() => setIsLastFocused(true)}
                                    onBlur={() => setIsLastFocused(false)}
                                    sx={{
                                        mb: 2,
                                        bgcolor: 'white',
                                        borderRadius: '15px',
                                        '& .MuiInputLabel-asterisk': {
                                            color: 'red',
                                            fontSize: '20px'
                                        },
                                        mt: 2
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: isLastFocused || formData.userEmail ? 'transparent' : '#8C8C8C',
                                            fontSize: isLastFocused || formData.userEmail ? '0' : '18px',
                                            transition: 'all 0.3s ease',



                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                            backgroundColor: 'white',
                                            borderRadius: '15px', // Ensure border radius is applied

                                            fontSize: isMobile ? '16px' : '18px'
                                        }
                                    }}
                                />
                                {emailError && (
                                    <Typography variant="body2" color="error" mb={2} mt={-1}>
                                        Invalid email
                                    </Typography>
                                )}

                                <Box display={'flex'} flexDirection={'row'} sx={{ mt: 2, mb: 2 }} justifyContent='center'>
                                    <Checkbox
                                        checked={formData.mobileVerification}
                                        onChange={handleCheckboxChange}
                                        name="mobileVerification"
                                        color="primary"
                                        disabled={!formData.countryCode || !formData.mobileNumber}
                                        inputProps={{ 'aria-label': 'Kindly agree to our SMS Verification and Email Verification, privacy policy, and terms for enhanced account security during Sign-Up' }}
                                        error={!!errors.mobileVerification}
                                    />
                                    <Typography variant="body2" fontSize={isMobile ? '14px' : '16px'} fontWeight={600}
                                        color={formData.mobileVerification ? 'primary' : 'textSecondary'}


                                    >Kindly agree to our SMS Verification and Email Verification, privacy policy, and terms for enhanced account security during Sign-Up</Typography>
                                </Box>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={loading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </Box>
                        </>
                    )}



                    {steps[activeStep] === 'Verification code' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }} sx={{ display: 'flex', flexDirection: 'column', mt: isMobile ? 8 : 5 , height: formData.userEmail ? 'auto' : '70vh'}}>
                                <Typography variant="h5" sx={{ mt: 8, mb: 2, fontSize: isMobile ? '20px' : '23px', fontWeight: 600 }}>
                                    Enter a 6-digit code sent to you at {'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 500 }}>
                                        {formData.countryCode}{formData.mobileNumber}
                                    </Box>
                                    {' '}to finish setting up your account
                                </Typography>

                                <Box display="flex" alignItems="center" sx={{ mb: 2 }} justifyContent="center">
                                    {userOtp.map((digit, index) => (
                                        <TextField
                                            key={`otpInput-${index}`}
                                            id={`otpInput-${index}`}
                                            value={digit}
                                            onChange={(e) => handleOtpInputChange(index, e.target.value)}
                                            inputProps={{ maxLength: 1, style: { fontSize: isMobile ? '16px' : '35px' } }}
                                            sx={{
                                                width: '90px',
                                                height: isMobile ? '50px' : '90px',
                                                mr: isMobile ? 1 : 2.5,
                                                bgcolor: 'white',
                                                borderRadius: '15px',
                                                fontFamily: 'Times New Roman, serif'
                                            }}
                                            InputProps={{
                                                sx: {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: isMobile ? '14px' : '40px',
                                                    fontWeight: 400,
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                },
                                                inputProps: {
                                                    style: {
                                                        textAlign: 'center',
                                                    },
                                                    fontSize: isMobile ? '16px' : '18px'
                                                },
                                            }}
                                        />
                                    ))}
                                </Box>
                                {!formData.userEmail && (
                                    <>
                                        <Box width='100%' display='flex' justifyContent='end' alignItems='center' paddingTop={2}>
                                            <ReplayIcon sx={{ fontSize: '20px' }} />
                                            {countdown > 0 && startCountdown ? (
                                                <Typography variant="body2" sx={{ fontSize: isMobile ? '14px' : '18px', color: '#333333', mr: 2 }}>
                                                    RESEND THE CODE | <Box component="span" sx={{ color: 'red', fontFamily: 'Arial, sans-serif' }}>{countdown}</Box>
                                                </Typography>


                                            ) : (
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={onResend}
                                                    sx={{ fontSize: isMobile ? '14px' : '18px', color: '#333333', cursor: 'pointer', textDecoration: 'underline', mr: 2 }}>
                                                    RESEND THE CODE
                                                </Link>
                                            )}
                                        </Box>
                                    </>
                                )}

                                {formData.userEmail && (
                                    <>
                                        <Typography variant="h5" sx={{ mt: 6, mb: 2, fontSize: isMobile ? '20px' : '23px', fontWeight: 600 }}>
                                            Enter a 6-digit code sent to you at {' '}
                                            <Box component="span" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 500 }}>
                                                {formData.userEmail}
                                            </Box>
                                            {' '}to finish setting up your account
                                        </Typography>

                                        <Box display="flex" alignItems="center" sx={{ mb: 2 }} justifyContent="center">
                                            {userEmailOtp.map((digit, index) => (
                                                <TextField
                                                    key={`emailOtpInput-${index}`}
                                                    id={`emailOtpInput-${index}`}
                                                    value={digit}
                                                    onChange={(e) => handleEmailOtpInputChange(index, e.target.value)}
                                                    inputProps={{ maxLength: 1, style: { fontSize: isMobile ? '16px' : '35px' } }}
                                                    sx={{
                                                        width: '90px',
                                                        height: isMobile ? '50px' : '90px',
                                                        mr: isMobile ? 1 : 2.5,
                                                        bgcolor: 'white',
                                                        borderRadius: '15px',
                                                        fontFamily: 'Times New Roman, serif'
                                                    }}
                                                    InputProps={{
                                                        sx: {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            fontSize: isMobile ? '14px' : '40px',
                                                            fontWeight: 400,
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none',
                                                            },
                                                        },
                                                        inputProps: {
                                                            style: {
                                                                textAlign: 'center',
                                                            },
                                                            fontSize: isMobile ? '16px' : '18px'
                                                        },
                                                    }}
                                                />
                                            ))}
                                        </Box>


                                        <Box width='100%' display='flex' justifyContent='end' alignItems='center' paddingTop={2}>
                                            <ReplayIcon sx={{ fontSize: '20px' }} />
                                            {countdown > 0 && startCountdown ? (
                                                <Typography variant="body2" sx={{ fontSize: isMobile ? '14px' : '18px', color: '#333333', mr: 2 }}>
                                                    RESEND THE CODE | <Box component="span" sx={{ color: 'red', fontFamily: 'Arial, sans-serif' }}>{countdown}</Box>
                                                </Typography>


                                            ) : (
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={onResend}
                                                    sx={{ fontSize: isMobile ? '14px' : '18px', color: '#333333', cursor: 'pointer', textDecoration: 'underline', mr: 2 }}>
                                                    RESEND THE CODE
                                                </Link>
                                            )}
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </>
                    )}



                    {steps[activeStep] === 'Job position' && (
                        <>

                            <Box width={{ xs: '90%', md: '40%' }}>
                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {'0'}{activeStep}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: '25px', fontWeight: 400 }}>
                                    What is your current job position?
                                </Typography>
                                {/* <Box display={'flex'} flexDirection={'column'}>
                                    <FormGroup>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.jobPosition.includes("Community Health Worker/ Promotor de Salud/ Community Health Representative")}
                                                    onChange={handleJobChange}
                                                    value="Community Health Worker/ Promotor de Salud/ Community Health Representative"
                                                />
                                            }
                                            label="A. Community Health Worker/ Promotor de Salud/ Community Health Representative"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 500,
                                                        color: formData.jobPosition.includes("Community Health Worker/ Promotor de Salud/ Community Health Representative") ? '#3C2565' : 'inherit'
                                                    }
                                                }
                                            }}
                                            sx={{
                                                alignItems: 'flex-start',
                                                '& .MuiFormControlLabel-label': {
                                                    display: 'inline-block',
                                                },
                                                '& .MuiCheckbox-root': {
                                                    marginTop: '-6px', // Adjust if needed to align the checkbox correctly
                                                },
                                            }}
                                        />

                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={formData.jobPosition.includes("doula")}
                                                onChange={handleJobChange}
                                                value="doula"
                                            />}
                                            label="B. Doula"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 500,
                                                        color: formData.jobPosition.includes("doula") ? '#3C2565' : 'inherit'
                                                    }
                                                }
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={formData.jobPosition.includes("peerSupportSpecialist")}
                                                onChange={handleJobChange}
                                                value="peerSupportSpecialist"
                                            />}
                                            label="C. Peer support specialist"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 500,
                                                        color: formData.jobPosition.includes("peerSupportSpecialist") ? '#3C2565' : 'inherit'
                                                    }
                                                }
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={formData.jobPosition.includes("wellnessCoach")}
                                                onChange={handleJobChange}
                                                value="wellnessCoach"
                                            />}
                                            label="D. Wellness Coach"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 500,
                                                        color: formData.jobPosition.includes("wellnessCoach") ? '#3C2565' : 'inherit'
                                                    }
                                                }
                                            }}
                                        />
                                    </FormGroup>


                                </Box> */}
                                <Box display={'flex'} flexDirection={'column'}>
                                    <FormGroup component={Grid} container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formData.jobPosition.includes("Community Health Worker/ Promotor de Salud/ Community Health Representative")}
                                                        onChange={handleJobChange}
                                                        value="Community Health Worker/ Promotor de Salud/ Community Health Representative"
                                                    />
                                                }
                                                label="A. Community Health Worker/ Promotor de Salud/ Community Health Representative"
                                                componentsProps={{
                                                    typography: {
                                                        sx: {
                                                            fontSize: isMobile ? '13px' : '16px',
                                                            fontWeight: 500,
                                                            color: formData.jobPosition.includes("Community Health Worker/ Promotor de Salud/ Community Health Representative") ? '#3C2565' : 'inherit'
                                                        }
                                                    }
                                                }}
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        display: 'inline-block',
                                                    },
                                                    '& .MuiFormControlLabel-root': {
                                                        cursor: 'pointer',
                                                    },
                                                    '& .MuiCheckbox-root': {
                                                        marginTop: '-22px', // Adjust if needed to align the checkbox correctly
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={formData.jobPosition.includes("Doula")}
                                                    onChange={handleJobChange}
                                                    value="Doula"
                                                />}
                                                label="B. Doula"
                                                componentsProps={{
                                                    typography: {
                                                        sx: {
                                                            fontSize: isMobile ? '13px' : '16px',
                                                            fontWeight: 500,
                                                            color: formData.jobPosition.includes("Doula") ? '#3C2565' : 'inherit'
                                                        }
                                                    }
                                                }}
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        display: 'inline-block',
                                                    },
                                                    '& .MuiFormControlLabel-root': {
                                                        cursor: 'pointer',
                                                    },
                                                    '& .MuiCheckbox-root': {
                                                        // marginTop: '-6px',
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={formData.jobPosition.includes("Peer Support Specialist")}
                                                    onChange={handleJobChange}
                                                    value="Peer Support Specialist"
                                                />}
                                                label="C. Peer support specialist"
                                                componentsProps={{
                                                    typography: {
                                                        sx: {
                                                            fontSize: isMobile ? '13px' : '16px',
                                                            fontWeight: 500,
                                                            color: formData.jobPosition.includes("Peer Support Specialist") ? '#3C2565' : 'inherit'
                                                        }
                                                    }
                                                }}
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        display: 'inline-block',
                                                    },
                                                    '& .MuiFormControlLabel-root': {
                                                        cursor: 'pointer',
                                                    },
                                                    '& .MuiCheckbox-root': {
                                                        // marginTop: '-6px',
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={formData.jobPosition.includes("Wellness Coach")}
                                                    onChange={handleJobChange}
                                                    value="Wellness Coach"
                                                />}
                                                label="D. Wellness Coach"
                                                componentsProps={{
                                                    typography: {
                                                        sx: {
                                                            fontSize: isMobile ? '13px' : '16px',
                                                            fontWeight: 500,
                                                            color: formData.jobPosition.includes("Wellness Coach") ? '#3C2565' : 'inherit'
                                                        }
                                                    }
                                                }}
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        display: 'inline-block',
                                                    },
                                                    '& .MuiFormControlLabel-root': {
                                                        cursor: 'pointer',
                                                    },
                                                    '& .MuiCheckbox-root': {
                                                        // marginTop: '-6px', // Adjust if needed to align the checkbox correctly
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </FormGroup>
                                </Box>
                            </Box>

                        </>
                    )}


                    {steps[activeStep] === 'Intrest' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }}>
                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {'0'}{activeStep}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block' }}>
                                    What interest you most in a job?
                                </Typography>


                                <RadioGroup value={formData.intrest} onChange={handleInterestChange} component={Grid} container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Career Advancement"
                                            control={<Radio />}
                                            label="A. Career Advancement"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.intrest === 'Career Advancement' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Opportunity to get hired"
                                            control={<Radio />}
                                            label="B. Opportunity to get hired"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.intrest === 'Opportunity to get hired' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Compensation"
                                            control={<Radio />}
                                            label="C. Compensation"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.intrest === 'Compensation' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                </RadioGroup>
                            </Box>
                        </>
                    )}

                    {steps[activeStep] === 'Experience' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }}>
                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {'0'}{activeStep}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block' }}>
                                    How many years of experience do you have?
                                </Typography>

                                <RadioGroup value={formData.experience} onChange={handleExperienceChange} component={Grid} container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Currently in training"
                                            control={<Radio />}
                                            label="A. Currently in training"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.experience === 'Currently in training' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                        {formData.experience === 'Currently in training' && (
                                            <Box sx={{ marginTop: '10px' }}>
                                                {/* <FormControl fullWidth>
                                                    <Select
                                                        labelId="education-course-select-label"
                                                        id="education-course-select"
                                                        value={selectedValue}
                                                        onChange={handleCourseChange}
                                                        displayEmpty
                                                        sx={{ bgcolor: 'white', borderRadius: 5 }}
                                                        renderValue={(selected) => {
                                                            if (!selected) {
                                                                return (
                                                                    <Box display="flex" alignItems="center">
                                                                        <AccountBalanceIcon color="action" style={{ marginRight: 8 }} />
                                                                        <Typography color="textSecondary" fontWeight={700} fontSize={isMobile ? '12px' : '14px'}>
                                                                            Select a school or program name
                                                                        </Typography>
                                                                    </Box>
                                                                );
                                                            }
                                                            return (
                                                                <Box display="flex" alignItems="center">
                                                                    <AccountBalanceIcon color="action" style={{ marginRight: 8 }} />
                                                                    {educationCourses.find(course => course.abbreviation === selected).name}
                                                                </Box>
                                                            );
                                                        }}
                                                    >
                                                        {educationCourses.map((course) => (
                                                            <MenuItem key={course.abbreviation} value={course.abbreviation}>
                                                                {course.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl> */}

                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="education-course-select-label"
                                                        id="education-course-select"
                                                        value={formData.training}
                                                        onChange={handleCourseChange}
                                                        displayEmpty
                                                        sx={{ bgcolor: 'white', borderRadius: 5 }}
                                                        renderValue={(selected) => {
                                                            if (!selected) {
                                                                return (
                                                                    <Box display="flex" alignItems="center">
                                                                        <AccountBalanceIcon color="action" style={{ marginRight: 8 }} />
                                                                        <Typography color="textSecondary" fontWeight={700} fontSize={isMobile ? '12px' : '13px'}>
                                                                            Select a school or program name
                                                                        </Typography>
                                                                    </Box>
                                                                );
                                                            }
                                                            return (
                                                                <Box display="flex" alignItems="center">
                                                                    <AccountBalanceIcon color="action" style={{ marginRight: 8 }} />
                                                                    {selected}
                                                                </Box>
                                                            );
                                                        }}
                                                    >
                                                        {educationCourses.map((course) => (
                                                            <MenuItem key={course} value={course}>
                                                                {course}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Under 1 year"
                                            control={<Radio />}
                                            label="B. Under 1 year"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.experience === 'Under 1 year' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="1-2 years"
                                            control={<Radio />}
                                            label="C. 1-2 years"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.experience === '1-2 years' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="3-4 years"
                                            control={<Radio />}
                                            label="D. 3-4 years"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.experience === '3-4 years' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Above 5 years"
                                            control={<Radio />}
                                            label="E. Above 5 years"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.experience === 'Above 5 years' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                </RadioGroup>

                            </Box>
                        </>
                    )}

                    {steps[activeStep] === 'JoiningDate' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }}>
                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {'0'}{activeStep}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block' }}>
                                    When are you able to start?
                                </Typography>
                                <RadioGroup value={formData.joiningDate} onChange={handleJoiningDateChange} component={Grid} container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Not sure"
                                            control={<Radio />}
                                            label="1. Not sure"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.joiningDate === 'Not sure' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="In few weeks"
                                            control={<Radio />}
                                            label="2. In few weeks"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.joiningDate === 'In few weeks' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="In few months"
                                            control={<Radio />}
                                            label="3. In few months"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.joiningDate === 'In few months' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Specific date"
                                            control={<Radio />}
                                            label="4. Specific date"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.joiningDate === 'Specific date' ? '#3C2565' : 'inherit',
                                            }}
                                        />
                                    </Grid>
                                </RadioGroup>
                                {formData.joiningDate === 'Specific date' && (

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            name="specificDate"
                                            value={defaultDate}
                                            onChange={handleDateChange}
                                            minDate={minDate}
                                            sx={{ mt: 2, mb: 2, width: '320px', bgcolor: 'white' }}

                                        />
                                    </LocalizationProvider>
                                )}
                            </Box>

                        </>
                    )}

                    {steps[activeStep] === 'WorkLocation' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }}>
                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {'0'}{activeStep}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block' }}>
                                    Where would you like to work?
                                </Typography>



                                <FormControl fullWidth sx={{ mb: 2, bgcolor: 'white', borderRadius: '15px' }}>
                                    <InputLabel
                                        required
                                        sx={{
                                            color: isLastFocused || formData.jobState ? 'transparent' : '#8C8C8C',
                                            fontSize: isLastFocused || formData.jobState ? '0' : '18px',
                                            transition: 'all 0.3s ease',
                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            }
                                        }}
                                    >
                                        State
                                    </InputLabel>
                                    <Select
                                        name="jobState"
                                        value={formData.jobState}
                                        onChange={handleNameChange}
                                        onFocus={() => setIsLastFocused(true)}
                                        onBlur={() => setIsLastFocused(false)}
                                        error={!!errors.jobState}
                                        sx={{
                                            fontSize: isMobile ? '16px' : '18px',
                                            borderRadius: '15px',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            }
                                        }}
                                    >
                                        {usStates.map((state) => (
                                            <MenuItem key={state} value={state}>
                                                {state}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {errors.jobState && (
                                    <Typography variant="body2" color="error" mb={2} mt={-1}>
                                        State is required
                                    </Typography>
                                )}

                                

                                <FormControl fullWidth error={!!errors.city}>
                                    <Select

                                        value={formData.city}
                                        // onChange={(e) => setFormData({ ...formData, city: e.target.value });
                                        // if (errors.city) {
                                        //     setErrors({ ...errors, city: '' });
                                        // }}

                                        onChange={(e) => {
                                            setFormData({ ...formData, city: e.target.value });
                                            if (errors.city) {
                                                setErrors({ ...errors, city: '' });
                                            }
                                        }}
                                        onBlur={() => setIsFirstFocused(false)}
                                        sx={{
                                            bgcolor: 'white',
                                            borderRadius: '15px',
                                            height: '60px',
                                            borderColor: 'white',
                                            fontSize: '16px'
                                        }}
                                        displayEmpty
                                    >
                                        <MenuItem disabled value="">
                                            Select City
                                        </MenuItem>
                                        {citiesFound ? (
                                            cities.map((city) => (
                                                <MenuItem key={city} value={city}>
                                                    {city}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value="">
                                                <em>No cities found, please check state name</em>
                                            </MenuItem>
                                        )}
                                    </Select>
                                    {errors.city && (
                                        <Typography variant="body2" color="error" mb={2} mt={1}>
                                            City is required
                                        </Typography>
                                    )}
                                </FormControl>


                            </Box>
                        </>
                    )}





                    {steps[activeStep] === 'expertise' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }} pt={10} >

                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {'0'}{activeStep}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: isMobile ? 1 : 1, mb: isMobile ? 0 : 1, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block' }}>
                                    What are your expertise and community management?
                                </Typography>
                                <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="column" width='100%' height='70%'>

                                    <TextField
                                        name="childAndMaternalHealth"
                                        label={formData.expertise.childAndMaternalHealth.length === 0 ? "Child and Maternal Health" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.childAndMaternalHealth}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.childAndMaternalHealth.length > 0 ? '#a675e0' : 'white', // Change bgcolor based on selection
                                            color: formData.expertise.childAndMaternalHealth.length > 0 ? 'white' : 'black', // Change text color based on selection
                                            borderRadius: 5,
                                            height: formData.expertise.childAndMaternalHealth.length > 2 ? 'auto' : '56px', // Adjust height
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.childAndMaternalHealth.length > 0 ? 'white' : 'black', // Change dropdown text color
                                            },
                                            fontSize: formData.expertise.childAndMaternalHealth.length > 0 ? '20px' : '25px', // Adjust font size
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Child and Maternal Health: ${selected.join(', ')}`;
                                            },
                                            multiple: true, // Enable multiple selection
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',
                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {childandMaternalHealthOptions.map((option) => {
                                            const isSelected = formData.expertise.childAndMaternalHealth.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%" >
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px' // Change text color to green if selected
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>




                                    <TextField
                                        name="communityRepresentation"
                                        label={formData.expertise.communityRepresentation.length === 0 ? "Community Representation" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.communityRepresentation}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.communityRepresentation.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.communityRepresentation.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.communityRepresentation.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Community Representation: ${selected.join(', ')}`;
                                            },
                                            multiple: true,
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {communityRepresentationOptions.map((option) => {
                                            const isSelected = formData.expertise.communityRepresentation.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'

                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                    <TextField
                                        name="accessibilityAndEquity"
                                        label={formData.expertise.accessibilityAndEquity.length === 0 ? "Accessibility and Equity" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.accessibilityAndEquity}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.accessibilityAndEquity.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.accessibilityAndEquity.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.accessibilityAndEquity.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Accessibility and Equity: ${selected.join(', ')}`;
                                            },
                                            multiple: true,
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {accessibilityandEquityOptions.map((option) => {
                                            const isSelected = formData.expertise.accessibilityAndEquity.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'

                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>


                                    <TextField
                                        name="healthAndWellness"
                                        label={formData.expertise.healthAndWellness.length === 0 ? "Health and Wellness" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.healthAndWellness}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.healthAndWellness.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.healthAndWellness.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.healthAndWellness.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Health and Wellness: ${selected.join(', ')}`;
                                            },
                                            multiple: true,
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {healthandWellnessOptions.map((option) => {
                                            const isSelected = formData.expertise.healthAndWellness.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'

                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>


                                    <TextField
                                        name="socialJusticeAndInclusion"
                                        label={formData.expertise.socialJusticeAndInclusion.length === 0 ? "Social Justice and Inclusion" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.socialJusticeAndInclusion}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.socialJusticeAndInclusion.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.socialJusticeAndInclusion.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.socialJusticeAndInclusion.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Social Justice and Inclusion: ${selected.join(', ')}`;
                                            },
                                            multiple: true, // Enable multiple selection
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {socialJusticeandInclusionOptions.map((option) => {
                                            const isSelected = formData.expertise.socialJusticeAndInclusion.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                    <TextField
                                        name="youthServices"
                                        label={formData.expertise.youthServices.length === 0 ? "Youth Services" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.youthServices}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.youthServices.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.youthServices.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.youthServices.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Youth Services: ${selected.join(', ')}`;
                                            },
                                            multiple: true, // Enable multiple selection
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >


                                        {youthServicesOptions.map((option) => {
                                            const isSelected = formData.expertise.youthServices.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>


                                </Box>
                            </Box>
                        </>
                    )}


                    {steps[activeStep] === 'Certification' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }} mt={isMobile ? 15 : 7}>
                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {activeStep}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block' }}>
                                    Which certificate(s), certification(s), or license do you hold, and which state?
                                </Typography>

                                <TextField
                                    name="healthcare"
                                    label={formData.certification.healthcare.length === 0 ? "Healthcare" : ""}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={formData.certification.healthcare}
                                    onChange={handleCertificationChange}
                                    select
                                    sx={{
                                        minWidth: isMobile ? '100%' : 400,
                                        bgcolor: formData.certification.healthcare.length > 0 ? '#a675e0' : 'white',
                                        color: formData.certification.healthcare.length > 0 ? 'white' : 'black',
                                        borderRadius: 5,
                                        '& .MuiSelect-select': {
                                            color: formData.certification.healthcare.length > 0 ? 'white' : 'black',
                                        }
                                    }}
                                    SelectProps={{
                                        renderValue: (selected) => {
                                            if (!selected || selected.length === 0) {
                                                return 'Select a course';
                                            }
                                            return `Healthcare: ${selected.join(', ')}`;
                                        },
                                        multiple: true,
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#8C8C8C',
                                            fontSize: '18px',

                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            }
                                        }
                                    }}
                                >
                                    {healthcareOptions.map((option) => {
                                        const isSelected = formData.certification.healthcare.indexOf(option) > -1;
                                        return (
                                            <MenuItem key={option} value={option}>
                                                <Box display="flex" alignItems="center" width="100%" >
                                                    {isSelected && (
                                                        <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                    )}
                                                    <Typography
                                                        sx={{
                                                            flexGrow: 1,
                                                            color: isSelected ? '#3c1767' : 'inherit',
                                                            fontSize: '16px'
                                                        }}
                                                    >
                                                        {option}
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>

                                <TextField
                                    name="specializedCareCertifications"
                                    label={formData.certification.specializedCareCertifications.length === 0 ? "Specialized Care Certifications" : ""}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={formData.certification.specializedCareCertifications}
                                    onChange={handleCertificationChange}
                                    select
                                    sx={{
                                        minWidth: isMobile ? '100%' : 400,
                                        bgcolor: formData.certification.specializedCareCertifications.length > 0 ? '#a675e0' : 'white',
                                        color: formData.certification.specializedCareCertifications.length > 0 ? 'white' : 'black',
                                        borderRadius: 5,
                                        '& .MuiSelect-select': {
                                            color: formData.certification.specializedCareCertifications.length > 0 ? 'white' : 'black',
                                        }
                                    }}
                                    SelectProps={{
                                        renderValue: (selected) => {
                                            if (!selected || selected.length === 0) {
                                                return 'Select a course';
                                            }
                                            return `Specialized Care Certifications: ${selected.join(', ')}`;
                                        },
                                        multiple: true,
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#8C8C8C',
                                            fontSize: '18px',

                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            }
                                        }
                                    }}
                                >
                                    {specializedCareCertificationsOptions.map((option) => {
                                        const isSelected = formData.certification.specializedCareCertifications.indexOf(option) > -1;
                                        return (
                                            <MenuItem key={option} value={option}>
                                                <Box display="flex" alignItems="center" width="100%">
                                                    {isSelected && (
                                                        <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                    )}
                                                    <Typography
                                                        sx={{
                                                            flexGrow: 1,
                                                            color: isSelected ? '#3c1767' : 'inherit',
                                                            fontSize: '16px'
                                                        }}
                                                    >
                                                        {option}
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>

                                <TextField
                                    name="mentalHealthandPeerSupport"
                                    label={formData.certification.mentalHealthandPeerSupport.length === 0 ? "Mental Health and Peer Support" : ""}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={formData.certification.mentalHealthandPeerSupport}
                                    onChange={handleCertificationChange}
                                    select
                                    sx={{
                                        minWidth: isMobile ? '100%' : 400,
                                        bgcolor: formData.certification.mentalHealthandPeerSupport.length > 0 ? '#a675e0' : 'white',
                                        color: formData.certification.mentalHealthandPeerSupport.length > 0 ? 'white' : 'black',
                                        borderRadius: 5,
                                        '& .MuiSelect-select': {
                                            color: formData.certification.mentalHealthandPeerSupport.length > 0 ? 'white' : 'black',
                                        }
                                    }}
                                    SelectProps={{
                                        renderValue: (selected) => {
                                            if (!selected || selected.length === 0) {
                                                return 'Select a course';
                                            }
                                            return `Mental Health and Peer Support: ${selected.join(', ')}`;
                                        },
                                        multiple: true,

                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#8C8C8C',
                                            fontSize: '18px',

                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            }
                                        }
                                    }}
                                >
                                    {mentalHealthandPeerSupportOptions.map((option) => {
                                        const isSelected = formData.certification.mentalHealthandPeerSupport.indexOf(option) > -1;
                                        return (
                                            <MenuItem key={option} value={option}>
                                                <Box display="flex" alignItems="center" width="100%">
                                                    {isSelected && (
                                                        <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                    )}
                                                    <Typography
                                                        sx={{
                                                            flexGrow: 1,
                                                            color: isSelected ? '#3c1767' : 'inherit',
                                                            fontSize: '16px'
                                                        }}
                                                    >
                                                        {option}
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>


                                <TextField
                                    name="otherOptions"
                                    label={formData.certification.otherOptions.length === 0 ? "Other Option" : ""}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={formData.certification.otherOptions}
                                    onChange={handleOtherChange}
                                    select
                                    sx={{
                                        minWidth: isMobile ? '100%' : 400,
                                        bgcolor: formData.certification.otherOptions.length > 0 ? '#a675e0' : 'white',
                                        color: formData.certification.otherOptions.length > 0 ? 'white' : 'black',
                                        borderRadius: 5,
                                        '& .MuiSelect-select': {
                                            color: formData.certification.otherOptions.length > 0 ? 'white' : 'black',
                                        },
                                        height: formData.certification.otherOptions.length > 2 ? 'auto' : '56px',
                                    }}
                                    SelectProps={{
                                        renderValue: (selected) => {
                                            if (!selected || selected.length === 0) {
                                                return 'Other Options';
                                            }
                                            return `Other Options: ${selected}`;
                                        },
                                        multiple: false,

                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#8C8C8C',
                                            fontSize: '18px',
                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            },

                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            }
                                        }
                                    }}
                                >
                                    {otherOptions.map((option) => {
                                        const isSelected = formData.certification.otherOptions.indexOf(option) > -1;
                                        return (
                                            <MenuItem key={option} value={option}>
                                                <Box display="flex" alignItems="center" width="100%">
                                                    {isSelected && (
                                                        <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                    )}
                                                    <Typography
                                                        sx={{
                                                            flexGrow: 1,
                                                            color: isSelected ? '#3c1767' : 'inherit',
                                                            fontSize: '16px'
                                                        }}
                                                    >
                                                        {option}
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>


                                <TextField
                                    required
                                    fullWidth
                                    label="State"
                                    name="state"
                                    value={formData.certification.state}
                                    onChange={handleCertificationChange}
                                    sx={{ mt: 2, mb: 2, bgcolor: 'white', borderRadius: 5 }}
                                    onFocus={() => setIsFirstFocused(true)}
                                    onBlur={() => setIsFirstFocused(false)}
                                    error={!!errors['certification.state']}
                                    onKeyPress={(e) => {
                                        if (/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}


                                    InputLabelProps={{
                                        sx: {
                                            color: isFirstFocused || formData.certification.state ? 'transparent' : '#8C8C8C',
                                            fontSize: isFirstFocused || formData.certification.state ? '0' : '18px',
                                            transition: 'all 0.3s ease',


                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            }, fontSize: isMobile ? '16px' : '18px',
                                            borderRadius: '15px',
                                            bgcolor: 'white'
                                        }
                                    }}
                                />

                                {errors['certification.state'] && (
                                    <Typography variant="body2" color="error" mb={2} mt={-1}>
                                        State is required
                                    </Typography>
                                )}

                            </Box>
                        </>
                    )}


                    {steps[activeStep] === 'mentor' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }}>

                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {activeStep}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block' }}>
                                    Are you intrested in being a mentor?
                                </Typography>

                                <RadioGroup value={formData.mentor} onChange={handleMentorChange} component={Grid} container spacing={2} sx={{ width: '20%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio />}
                                            label="A.Yes"
                                            componentsProps={{
                                                typography: {
                                                    sx: { fontSize: isMobile ? '13px' : '18px', fontWeight: 500, color: selectedValue === 'Yes' ? 'red' : 'inherit' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio />}
                                            label="B.No"
                                            componentsProps={{
                                                typography: {
                                                    sx: { fontSize: isMobile ? '13px' : '18px', fontWeight: 500, color: selectedValue === 'Yes' ? 'red' : 'inherit' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </RadioGroup>

                            </Box>
                        </>
                    )}


                    {steps[activeStep] === 'matches' && (
                        <>
                            <Box width={{ xs: '90%', md: '40%' }}>

                                <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: 700, color: '#8C8C8C' }}>
                                    QUESTION{'  '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {activeStep}
                                    </Box>
                                    {' '}OF{' '}
                                    <Box component="span" sx={{ fontFamily: 'Arial, sans-serif' }}>
                                        {totalSteps - 1}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block' }}>
                                    Where would you like your matches sent?
                                </Typography>


                                <RadioGroup value={formData.matches} onChange={(e) => handleMatchesChange(e.target.value)} component={Grid} container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Text"
                                            control={<Radio />}
                                            label="A. Text"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '18px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.matches === 'Text' ? '#800080' : 'inherit',
                                            }}
                                        />
                                        {formData.matches === 'Text' && (
                                            <TextField
                                                required
                                                fullWidth
                                                label="Mobile number"
                                                name="mobileNumber"
                                                value={formData.mobileNumber}
                                                onChange={handleInputChange}
                                                onFocus={() => setIsFirstFocused(true)}
                                                onBlur={() => setIsFirstFocused(false)}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                sx={{ bgcolor: 'white', borderRadius: '15px', ml: 2, mb: 2 }}
                                                InputLabelProps={{
                                                    sx: {
                                                        color: isFirstFocused || formData.mobileNumber ? 'transparent' : '#8C8C8C',
                                                        fontSize: isFirstFocused || formData.mobileNumber ? '0' : '18px',
                                                        transition: 'all 0.3s ease',
                                                        '& .MuiInputLabel-asterisk': {
                                                            color: 'red',
                                                            fontSize: '20px'
                                                        }
                                                    }
                                                }}
                                                InputProps={{
                                                    sx: {
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none'
                                                        },
                                                        fontSize: isMobile ? '16px' : '18px',
                                                        fontFamily: 'Times New Roman, serif'
                                                    }
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Email"
                                            control={<Radio />}
                                            label="B. Email"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '18px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.matches === 'Email' ? '#800080' : 'inherit',
                                            }}
                                        />
                                        {formData.matches === 'Email' && (
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="userEmail"
                                                value={formData.userEmail}
                                                onChange={handleInputChange}
                                                onFocus={() => setIsLastFocused(true)}
                                                onBlur={() => setIsLastFocused(false)}
                                                sx={{
                                                    mb: 2,
                                                    bgcolor: 'white',
                                                    borderRadius: '15px',
                                                    '& .MuiInputLabel-asterisk': {
                                                        color: 'red',
                                                        fontSize: '20px'
                                                    },
                                                    mt: 1
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        color: isLastFocused || formData.userEmail ? 'transparent' : '#8C8C8C',
                                                        fontSize: isLastFocused || formData.userEmail ? '0' : '18px',
                                                        transition: 'all 0.3s ease',
                                                    }
                                                }}
                                                InputProps={{
                                                    sx: {
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none'
                                                        },
                                                        backgroundColor: 'white',
                                                        borderRadius: '15px',
                                                        fontSize: isMobile ? '16px' : '18px'
                                                    }
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            value="Both"
                                            control={<Radio />}
                                            label="C. Both Text and Email"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '18px',
                                                        fontWeight: 550,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                color: formData.matches === 'Both' ? '#800080' : 'inherit',
                                            }}
                                        />
                                        {formData.matches === 'Both' && (
                                            <Box>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label="Mobile number"
                                                    name="mobileNumber"
                                                    value={formData.mobileNumber}
                                                    onChange={handleInputChange}
                                                    onFocus={() => setIsFirstFocused(true)}
                                                    onBlur={() => setIsFirstFocused(false)}
                                                    onKeyPress={(e) => {
                                                        if (!/[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    sx={{ bgcolor: 'white', borderRadius: '15px', ml: 2 }}
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: isFirstFocused || formData.mobileNumber ? 'transparent' : '#8C8C8C',
                                                            fontSize: isFirstFocused || formData.mobileNumber ? '0' : '18px',
                                                            transition: 'all 0.3s ease',
                                                            
                                                        }
                                                    }}
                                                    InputProps={{
                                                        sx: {
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            },
                                                            fontSize: isMobile ? '16px' : '18px',
                                                            fontFamily: 'Times New Roman, serif'
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Email"
                                                    name="userEmail"
                                                    value={formData.userEmail}
                                                    onChange={handleInputChange}
                                                    onFocus={() => setIsLastFocused(true)}
                                                    onBlur={() => setIsLastFocused(false)}
                                                    sx={{
                                                        mb: 2,
                                                        bgcolor: 'white',
                                                        borderRadius: '15px',
                                                        '& .MuiInputLabel-asterisk': {
                                                            color: 'red',
                                                            fontSize: '20px'
                                                        },
                                                        ml: 2,
                                                        mt: 2
                                                    }}
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: isLastFocused || formData.userEmail ? 'transparent' : '#8C8C8C',
                                                            fontSize: isLastFocused || formData.userEmail ? '0' : '18px',
                                                            transition: 'all 0.3s ease',
                                                        }
                                                    }}
                                                    InputProps={{
                                                        sx: {
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none'
                                                            },
                                                            backgroundColor: 'white',
                                                            borderRadius: '15px',
                                                            fontSize: isMobile ? '16px' : '18px'
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Grid>
                                </RadioGroup>


                            </Box>
                        </>
                    )}

                    {steps[activeStep] === 'Review' && (
                        <>
                            <Box width={{ xs: '60%', md: '40%' }}
                                bgcolor='#E7E1F0' sx={{
                                    display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', pt: isMobile ? 10 : 10,
                                }}>

                                <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'  >
                                    <Typography variant="h6" sx={{ fontSize: isMobile ? '25px' : '35px', fontWeight: 600, color: '#3C2565' }}>
                                        Review Your response
                                    </Typography>
                                    <Typography variant="h6" sx={{ mb: 4, fontSize: isMobile ? '25px' : '35px', fontWeight: 600, color: '#3C2565' }}>
                                        before submitting
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="flex-start" bgcolor='white' borderRadius={10} width={{ xs: '90%', md: '60%' }}>
                                    <Box display="flex" flexDirection="column" gap={2} sx={{ padding: isMobile ? 2 : 5 }}>
                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                1. Hello! what should we call you?
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                {formData.firstName} {formData.lastName}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                2.1. Awesome! Let's begin with your mobile number, which will serve as your platform ID*.
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                {formData.countryCode} - {formData.mobileNumber}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                2.2. Please provide your email address as well so we can update your information.
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                {formData.userEmail}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                3. What job position are you looking for today?
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                Selected Job Positions: {formData.jobPosition}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                4. What interests you most in a job?
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                {formData.intrest}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                5. How many years of experience do you have?
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                            {formData.experience} {formData.training ? `- ${formData.training}` : ''}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                6. When are you able to start?
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                {formData.joiningDate}   {formData.specificDate}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                7. Where would you like to work?
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: '16px', fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                {formData.city}, {formData.jobState}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                8. What are your skills/Specialities?
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Child and Maternal Health:</strong> {renderExpertiseValues(formData.expertise.childAndMaternalHealth)}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Community Representation:</strong> {renderExpertiseValues(formData.expertise.communityRepresentation)}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Accessibility and Equity:</strong> {renderExpertiseValues(formData.expertise.accessibilityAndEquity)}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Health and Wellness:</strong> {renderExpertiseValues(formData.expertise.healthAndWellness)}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Social Justice and Inclusion:</strong> {renderExpertiseValues(formData.expertise.socialJusticeAndInclusion)}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Youth Services:</strong> {renderExpertiseValues(formData.expertise.youthServices)}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                9. Which certificate(s), certification(s), or license(s) do you hold, list which state?
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Healthcare:</strong> {renderCertificationValues(formData.certification.healthcare)}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Specialized Care Certifications:</strong> {renderCertificationValues(formData.certification.specializedCareCertifications)}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Mental Health and Peer Support:</strong> {renderCertificationValues(formData.certification.mentalHealthandPeerSupport)}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>State:</strong> {formData.certification.state}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 1, ml: 2, fontSize: isMobile ? '12px' : '16px', fontWeight: 500, color: '#8C8C8C' }}>
                                                <strong>Other Options:</strong> {formData.certification.otherOptions}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                10. Mentor
                                            </Typography>
                                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                                {formData.mentor}
                                            </Typography>

                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }}>
                                                11. Matches
                                            </Typography>
                                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                                {
                                                    formData.matches === 'Text'
                                                        ? formData.mobileNumber
                                                        : formData.matches === 'Email'
                                                            ? formData.userEmail
                                                            : formData.matches === 'Both'
                                                                ? `${formData.mobileNumber} , ${formData.userEmail}`
                                                                : ''
                                                }

                                            </Typography>

                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="center" width="100%" pt={2} pb={2}>
                                        <Button
                                            variant="outlined"

                                            onClick={() => setActiveStep(activeStep - 12)}
                                            sx={{

                                                backgroundColor: '#FEE4CC',

                                                color: '#F57C20',
                                                marginRight: 3,
                                                '&:hover': {
                                                    backgroundColor: '#ffffff',
                                                },
                                                borderRadius: 8,
                                                width: isMobile ? '120px' : '200px',
                                                height: isMobile ? '30px' : '40px',
                                                fontWeight: 500,
                                                fontSize: isMobile ? '10px' : '16px',
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: 'none',
                                                textTransform: 'none'

                                            }}
                                        >
                                            Edit the answers
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={handleSubmit}
                                            sx={{
                                                backgroundColor: '#FF6900',
                                                color: 'white',

                                                '&:hover': {
                                                    backgroundColor: '#3c1767',
                                                },
                                                borderRadius: 8,

                                                width: isMobile ? '120px' : '200px',
                                                height: isMobile ? '30px' : '40px',
                                                fontWeight: 500,
                                                fontSize: isMobile ? '10px' : '16px',
                                                textTransform: 'none'
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>

                                </Box>

                            </Box>
                        </>
                    )}





                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'start' }} width={{ xs: '90%', md: '40%' }} >

                    {activeStep !== steps.length - 1 && (
                        <Box>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{
                                    backgroundColor: '#D8D1E3',
                                    color: '#3C2565',
                                    marginRight: 3,

                                    '&:hover': {
                                        backgroundColor: '#ffffff',
                                    },
                                    borderRadius: 8,
                                    width: '120px',
                                    height: '40px',
                                    fontWeight: 500, // Increased font weight
                                    fontSize: '16px',
                                    textTransform: 'none'
                                }}
                            >
                                {/* {activeStep === steps.length - 2 ? 'Edit' : 'Back'} */}
                                Back
                            </Button>
                            <Button
                                onClick={handleNextWithValidation}
                                sx={{
                                    backgroundColor: '#3C2565',
                                    color: '#ffffff',
                                    marginRight: 3,
                                    '&:hover': {
                                        backgroundColor: '#3c1767',
                                    },
                                    borderRadius: 8,
                                    width: '120px',
                                    height: '40px',

                                    fontSize: '16px',
                                    fontWeight: 500,
                                    textTransform: 'none'
                                }}
                            >
                                {/* {activeStep === steps.length - 2 ? 'Submit' : 'Continue'} */}
                                Continue
                            </Button>


                        </Box>
                    )}


                </Box>


            </Box >
            <Box display='flex' justifyContent='center' alignItems='center'>
                {/* {steps[activeStep] !== 'Review' && (
                    <LinearDeterminate activeStep={activeStep} totalSteps={totalSteps} />
                )} */}
                {steps[activeStep] !== 'Review' && steps[activeStep] !== 'Verification code' && (
                    <LinearDeterminate activeStep={activeStep} totalSteps={totalSteps} />
                )}


            </Box>


            {/* </Box > */}
        </>
    );
};

export default OnBoardingNew;
