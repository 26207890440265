import { Box, Typography, Breadcrumbs, IconButton } from '@mui/material'
import React from 'react'
import TalentDrawer from './TalentDrawer'
import BoxCardTalent from './BoxCardTalent'
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';

const TalentDashboard = () => {
  const location = useLocation();
  const { talentId } = location.state || {};


  return (
    <Box display='flex' >
      <TalentDrawer talentId={talentId} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100%', marginTop: "50px", justifyContent: 'center', bgcolor: '#E7E1F0', minHeight: '100vh' }}>
        {/* <Box mb={2} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant="body1" component="span" fontSize='24px' fontWeight={400}>
            Dashboard
          </Typography>
        </Box> */}
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
          <Link component={RouterLink} to="/talentdashboard" state={{ talentId }} underline="hover">
            <IconButton size="small">
              <HomeIcon fontSize="small" color='primary' />
            </IconButton>
          </Link>
          <Link component={RouterLink} underline="hover" color="inherit">
            Dashboard
          </Link>
        </Breadcrumbs>
        <BoxCardTalent talentId={talentId} />
      </Box>
    </Box>
  )
}

export default TalentDashboard