import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { Avatar, Box, Button, Typography , IconButton } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate , useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutline';
import EditCalendarOtlinedIcon from '@mui/icons-material/EditCalendar';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { AppBar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TalentDrawer from './TalentDrawer';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSnackbar } from 'notistack';



export const OrganizationDetails = () => {


    // const { phoneNumber } = useParams();
    const location = useLocation();
    const { talentId , organizationid } = location.state || {};
    
    

    // const location = useLocation();
    // const { talentId } = location.state || {};
    // const { id } = useParams();
    const [hiringDetails, setHiringDetails] = useState(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchHiringDetails = async () => {
            const id = organizationid
           
            
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/api/hiring/${id}`);
                setHiringDetails(response.data);
            } catch (error) {
                console.error('Error fetching talent details:', error);
                enqueueSnackbar('Error fetching hiring details!', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
            }
        };

        if (organizationid) {
            fetchHiringDetails();
        }
    }, [organizationid]);

    if (!hiringDetails) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }



    


    const handleBack = () => {
        // navigate('/hiringdata')
        navigate('/organizationoffer', { state: { talentId } });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }



    



    return (
        <Box sx={{ display: 'flex' }}>
                       <TalentDrawer talentId={talentId}  />
           
            <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' , bgcolor: '#E7E1F0' , minHeight:'100vh' }} marginTop={3}>
            <Box mb={2} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
                    <Box display='flex' alignItems='center' onClick={handleBack} sx={{
                        '&:hover': {
                            cursor: 'pointer',
                            '& .MuiTypography-root': {
                                color: '#FF6900'
                            },
                            '& .MuiIconButton-root': {
                                color: '#FF6900'
                            }
                        }
                    }}>
                        <IconButton onClick={handleBack}>
                            <ArrowBackIosNewIcon sx={{ color: '#FF6900' }} />
                        </IconButton>
                        <Typography variant="body1" component="span" fontSize={isMobile ? '16px' : '18px'} fontWeight={400}>
                            Back
                        </Typography>
                    </Box>
                </Box>
                
                <Typography sx={{ fontSize: '35px', fontWeight: 600, color: '#3C2565', mb: 2 }}>
                    Organization Data
                </Typography>
                <Box width='90%' p={5}  bgcolor='white' borderRadius={5} mb={5}>




                    <Box  mb={2}>
                        {/* <Box width='100%' display='flex' alignItems='center' justifyContent='space-between' flexDirection='row'>
                            <Button
                                sx={{
                                    height: '30px',
                                    width: '150px',
                                    backgroundColor: 'rgba(144, 238, 144, 0.5)', // light green with transparency
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: 'rgba(144, 238, 144, 0.7)', // slightly darker on hover
                                    },
                                    borderRadius: 5,
                                    mb: 3
                                }}
                                startIcon={<FiberManualRecordIcon />}
                            >
                                In Progress
                            </Button>
                        </Box> */}
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={1.5} color='FF6900' mt={2}>
                                1. Organization Details
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1.5}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Organization Name
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {hiringDetails?.companyName || ''}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1.5}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Organization Type
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {hiringDetails?.organizationType || ''}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1.5}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Representative Name
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {hiringDetails?.firstName || ''}  {hiringDetails?.lastName || ''}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1.5}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Work Email
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {hiringDetails?.workEmail || ''}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Work Phone
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {hiringDetails?.workPhone || ''}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Zip Code
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {hiringDetails?.zipCode || ''}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Number of employees
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {hiringDetails?.numEmployees || ''}
                            </Typography>
                        </Box>


                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Frontline public health partners are you intrest
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                {/* {hiringDetails?.jobPosition || ''} */}
                                : {Array.isArray(hiringDetails?.jobPosition) ? hiringDetails.jobPosition.join(', ') : hiringDetails?.jobPosition || ''}
                            </Typography>
                        </Box>

                       

                       
                    </Box>

                    {/* <Box  mt={7} display='flex' justifyContent='center' alignItems='center'>
                        <Button

                            onClick={handleBack}
                            sx={{
                                backgroundColor: '#3C2565',
                                color: '#ffffff',
                                marginRight: 3,
                                '&:hover': {
                                    backgroundColor: '#3c1767',
                                },
                                borderRadius: 8,
                                width: '300px',
                                height: '40px',

                                fontSize: '16px',
                                fontWeight: 500,
                                textTransform: 'none',
                                mb: 5
                            }}
                        >

                            Back to Dashboard
                        </Button>

                        <Button

                            onClick={handleEditClick}
                            sx={{
                                backgroundColor: '#F77E09',
                                color: '#ffffff',
                                marginRight: 3,
                                '&:hover': {
                                    backgroundColor: '#F77E09',
                                },
                                borderRadius: 8,
                                width: '300px',
                                height: '40px',

                                fontSize: '16px',
                                fontWeight: 500,
                                textTransform: 'none',
                                mb: 5
                            }}
                        >

                            Edit
                        </Button>
                    </Box> */}



                </Box>

            </Box>
        </Box>
    )
}
export default OrganizationDetails;
