import React, { useState, useEffect, useRef } from 'react';
// import { Box, Button, Typography, TextField, useMediaQuery, Checkbox, Select, MenuItem } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import { FlagIcon } from 'react-flag-kit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, TextField, useMediaQuery, Button, Paper } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Swal from 'sweetalert2';

import Grid from '@mui/material/Grid';
import axios from 'axios';
import FormGroup from '@mui/material/FormGroup';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import DrawerHeader from '../../../components/DrawerHeader';
import { useNavigate } from 'react-router-dom';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';



const countryCodes = [
    { code: '+1', country: 'US', flag: 'US' },
    { code: '+7', country: 'Russia', flag: 'RU' },
    { code: '+20', country: 'Egypt', flag: 'EG' },
    { code: '+27', country: 'South Africa', flag: 'ZA' },
    { code: '+30', country: 'Greece', flag: 'GR' },
    { code: '+31', country: 'Netherlands', flag: 'NL' },
    { code: '+32', country: 'Belgium', flag: 'BE' },
    { code: '+33', country: 'France', flag: 'FR' },
    { code: '+34', country: 'Spain', flag: 'ES' },
    { code: '+36', country: 'Hungary', flag: 'HU' },
    { code: '+39', country: 'Italy', flag: 'IT' },
    { code: '+40', country: 'Romania', flag: 'RO' },
    { code: '+44', country: 'UK', flag: 'GB' },
    { code: '+46', country: 'Sweden', flag: 'SE' },
    { code: '+48', country: 'Poland', flag: 'PL' },
    { code: '+49', country: 'Germany', flag: 'DE' },
    { code: '+51', country: 'Peru', flag: 'PE' },
    { code: '+52', country: 'Mexico', flag: 'MX' },
    { code: '+54', country: 'Argentina', flag: 'AR' },
    { code: '+55', country: 'Brazil', flag: 'BR' },
    { code: '+56', country: 'Chile', flag: 'CL' },
    { code: '+58', country: 'Venezuela', flag: 'VE' },
    { code: '+60', country: 'Malaysia', flag: 'MY' },
    { code: '+62', country: 'Indonesia', flag: 'ID' },
    { code: '+63', country: 'Philippines', flag: 'PH' },
    { code: '+65', country: 'Singapore', flag: 'SG' },
    { code: '+66', country: 'Thailand', flag: 'TH' },
    { code: '+81', country: 'Japan', flag: 'JP' },
    { code: '+82', country: 'South Korea', flag: 'KR' },
    { code: '+86', country: 'China', flag: 'CN' },
    { code: '+91', country: 'India', flag: 'IN' },
    { code: '+92', country: 'Pakistan', flag: 'PK' },
    { code: '+94', country: 'Sri Lanka', flag: 'LK' },
    { code: '+98', country: 'Iran', flag: 'IR' },
    { code: '+212', country: 'Morocco', flag: 'MA' },
    { code: '+351', country: 'Portugal', flag: 'PT' },
    { code: '+353', country: 'Ireland', flag: 'IE' },
    { code: '+354', country: 'Iceland', flag: 'IS' },
    { code: '+357', country: 'Cyprus', flag: 'CY' },
    { code: '+358', country: 'Finland', flag: 'FI' },
    { code: '+372', country: 'Estonia', flag: 'EE' },
    { code: '+375', country: 'Belarus', flag: 'BY' },
    { code: '+376', country: 'Andorra', flag: 'AD' },
    { code: '+377', country: 'Monaco', flag: 'MC' },
    { code: '+380', country: 'Ukraine', flag: 'UA' },
    { code: '+381', country: 'Serbia', flag: 'RS' },
    { code: '+386', country: 'Slovenia', flag: 'SI' },
    { code: '+387', country: 'Bosnia and Herzegovina', flag: 'BA' },
    { code: '+420', country: 'Czech Republic', flag: 'CZ' },
    { code: '+421', country: 'Slovakia', flag: 'SK' },
    { code: '+423', country: 'Liechtenstein', flag: 'LI' },
    { code: '+505', country: 'Nicaragua', flag: 'NI' },
    { code: '+507', country: 'Panama', flag: 'PA' },
    { code: '+598', country: 'Uruguay', flag: 'UY' },
    { code: '+880', country: 'Bangladesh', flag: 'BD' },
    { code: '+964', country: 'Iraq', flag: 'IQ' },
    { code: '+971', country: 'United Arab Emirates', flag: 'AE' },
    { code: '+972', country: 'Israel', flag: 'IL' },
    { code: '+977', country: 'Nepal', flag: 'NP' },
    { code: '+994', country: 'Azerbaijan', flag: 'AZ' },
    { code: '+995', country: 'Georgia', flag: 'GE' },
];

const usStates = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii',
    'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const educationCourses = [
    'Bachelor of Arts in Education (BA)',
    'Bachelor of Science in Education (BSEd)',
    'Elementary Education',
    'Secondary Education',
    'Special Education',
    'Early Childhood Education',
    'Physical Education',
    'Educational Technology',
    'Mathematics Education',
    'Science Education',
    'Master of Education (MEd)',
    'Master of Arts in Teaching (MAT)',
    'Master of Science in Education (MSEd)',
    'Master of Arts in Education (MAEd)',
    'Educational Leadership',
    'Curriculum and Instruction',
    'Educational Administration',
    'School Counseling',
    'Reading and Literacy',
    'Doctor of Education (EdD)',
    'Doctor of Philosophy in Education (PhD)',
    'Doctor of Education in Educational Leadership (EdD)',
    'Doctor of Education in Curriculum and Instruction (EdD)',
    'Doctor of Philosophy in Educational Psychology (PhD)',
    'Doctor of Philosophy in Higher Education Administration (PhD)',
    'Doctor of Education in Organizational Leadership (EdD)',
    'Teaching English as a Second Language (TESOL)',
    'Bilingual Education',
    'Instructional Design',
    'Gifted and Talented Education',
    'Special Needs Education',
    'School Principal Certification',
    'Superintendent Certification',
    'Educational Policy and Leadership',
    'Inclusive Education'
];


const childandMaternalHealthOptions = ['At-risk children', 'At-risk mothers', ' Pregnant/postpartum individuals'];
const communityRepresentationOptions = ['American Indian', 'Alaskan Native', 'Asian', ' Black or African American', 'Hispanic or Latino', 'Native Hawaiian or Other Pacific Islander', 'Rural residents']
const accessibilityandEquityOptions = ['Disability community', 'Economically disadvantaged', ' Farm workers', 'Individuals with limited English proficiency or literacy']
const healthandWellnessOptions = ['Individuals with chronic health conditions', 'Individuals with mental illness']
const socialJusticeandInclusionOptions = ['Justice-involved individuals', ' LGBTQIA+ community', 'Older adults (over 65 years of age)', 'People uninsured or underinsured']
const youthServicesOptions = ['At-risk youth', 'Youth mental health'];
const healthcareOptions = ['CHW (Community Health Worker)', 'HHA (Home Health Aide)', 'CNA (Certified Nursing Assistant)', 'CHES (Certified Health Education Specialist)'];
const specializedCareCertificationsOptions = [' CD (Certified Doula) [DONA]', 'CLD (Certified Lactation Doula)', 'ICBD (International Certified Birth Doula)', 'CHPNA (Certified Hospice and Palliative Nursing Assistant)'];
const mentalHealthandPeerSupportOptions = ['NCPS (National Certified Peer Specialist)', 'CPSS (Certified Peer Support Specialist)', 'NBC-HWC (National Board Certified Health & Wellness Coach)', 'CHWC (Certified Health and Wellness Coach)'];
const otherOptions = ['Not certified ', 'Other']

export const FormEditTalent = () => {



    const [isFirstFocused, setIsFirstFocused] = useState(false);
    const [isLastFocused, setIsLastFocused] = useState(false);
    const [isMobileFocused, setIsMobileFocused] = useState(false);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [selectedValue, setSelectedValue] = useState('');
    const [errors, setErrors] = useState({});
    const [cities, setCities] = useState([]);
    const [citiesFound, setCitiesFound] = useState(true);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [emailError, setEmailError] = useState(false);
    const [showWarningAlert, setShowWarningAlert] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        countryCode: countryCodes[0].code,
        mobileNumber: '',
        mobileVerification: false,
        userEmail: '',
        jobPosition: [],
        intrest: '',
        experience: '',
        joiningDate: '',
        specificDate: '',
        city: '',
        jobState: '',
        expertise: {
            childAndMaternalHealth: [],
            communityRepresentation: [],
            accessibilityAndEquity: [],
            healthAndWellness: [],
            socialJusticeAndInclusion: [],
            youthServices: [],
        },
        certification: {
            healthcare: [],
            specializedCareCertifications: [],
            mentalHealthandPeerSupport: [],
            otherOptions: '',
            state: '',

        },

        mentor: '',
        matches: '',
        training: '',

    });




    const validateForm = () => {
        let tempErrors = {};
        if (!formData.firstName) tempErrors.firstName = 'First Name is required';
        if (!formData.lastName) tempErrors.lastName = 'Last Name is required';
        if (!formData.mobileNumber) tempErrors.mobileNumber = 'Mobile Number is required';
        if (!formData.jobState) tempErrors.jobState = 'State is required';
        if (!formData.city) tempErrors.city = 'City is required';
        if (!formData.certification.state) tempErrors['certification.state'] = 'State is required';
        if (formData.matches === 'Both') {
            if (!formData.mobileNumber) tempErrors.mobileNumber = 'Mobile Number is required';
            if (!formData.userEmail) tempErrors.userEmail = 'Email is required';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleMobileCheck = async (e) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/check-talent`, formData);
            if (response.data === 'present') {
                console.log('User is already present');
                setLoading(false);
                //handleUserExist()
                enqueueSnackbar('Data with mobile number is already present!', {
                    variant: 'warning',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
                
            } else if (response.data === 'new') {
                console.log('User data added successfully', response);

                addUserData();
            } else {
                setLoading(false);
                enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
            }
        } catch (err) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            })
        }
    };


    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     setShowWarningAlert(false);
    //     try {
    //         if (validateForm()) {
    //             handleMobileCheck()

    //         } else {
    //             console.log('Form data is invalid');
    //             setLoading(false);
    //         }
    //     } catch {
    //         setLoading(false);
    //         handleError()

    //     }
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setShowWarningAlert(false); // Hide the warning alert before validation

        try {
            if (validateForm()) {
                handleMobileCheck();
               
                
            } else {
                console.log('Form data is invalid');
                // setShowWarningAlert(true);
                enqueueSnackbar('Form is incomplete', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
                setLoading(false); 
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setLoading(false); // Stop loading in case of error
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            }) // Handle the error, e.g., showing an error alert
        }
    };



    const addUserData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/onboardingdata`, {
                firstName: formData.firstName,
                lastName: formData.lastName,
                countryCode: formData.countryCode,
                mobileNumber: formData.mobileNumber,
                mobileVerification: formData.mobileVerification,
                userEmail: formData.userEmail,
                jobPosition: formData.jobPosition,
                intrest: formData.intrest,
                experience: formData.experience,
                joiningDate: formData.joiningDate,
                specificDate: formData.specificDate,
                city: formData.city,
                jobState: formData.jobState,
                expertise: formData.expertise,
                certification: formData.certification,
                mentor: formData.mentor,
                matches: formData.matches,
                training: formData.training
            });
            console.log(response);

            if (response.data === 'present') {
                console.log('User is already present');
                setLoading(false);
                handleUserExist()
            } else if (response.data === 'new') {
                console.log('User data added successfully', response);
                setLoading(false);
                handleSucces();
            } else {
                setLoading(false);
                enqueueSnackbar('There was an error adding in talent details!', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
            }
        } catch (error) {
            console.error('Error adding user data:', error);
            setLoading(false);
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            })
        }
    };


    const handleSucces = () => {
       
        setTimeout(() => {
            enqueueSnackbar('Information saved successfully', {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            })
            navigate('/talentdata');
        }, 3000); // Hide after 5 seconds
    };









    const handleUserExist = () => {
        Swal.fire({
            title: "Error",
            text: "Data with mobile number is already present!!",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {




            }
        });
    };








    // const renderExpertiseValues = (expertiseCategory) => {
    //     return expertiseCategory.length > 0 ? expertiseCategory.join(', ') : 'None';
    // };

    // const renderCertificationValues = (certificationCategory) => {
    //     return certificationCategory.length > 0 ? certificationCategory.join(', ') : 'None';
    // };

    // const getSelectedJobPositions = (jobPosition) => {
    //     return Object.keys(jobPosition)
    //         .filter((key) => jobPosition[key])
    //         .map((key) => key.replace(/([A-Z])/g, ' $1').toLowerCase()) // Convert camelCase to regular text
    //         .join(', ');
    // };

    // const selectedJobPositions = getSelectedJobPositions(formData.jobPosition);





    const handleJoiningDateChange = (event) => {
        setFormData({
            ...formData,
            joiningDate: event.target.value,
            specificDate: event.target.value !== 'Specific date' ? '' : formData.specificDate
        });
    };



    const handleMentorChange = (event) => {
        const value = event.target.value;
        const updatedFormData = { ...formData, mentor: value };
        setFormData(updatedFormData);
    };



    const handleMatchesChange = (value) => {
        setFormData({ ...formData, matches: value });
    };


    const handleCertificationChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            certification: {
                ...prevFormData.certification,
                [name]: value,
                specifyOption: name === "otherOptions" && value !== "Please Specify" ? '' : prevFormData.certification.specifyOption
            },
        }));
    };

    const handleOtherChange = (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            certification: {
                ...formData.certification,
                otherOptions: value
            }
        });
    };



    const handleCourseChange = (event) => {
        const selectedCourse = event.target.value;
        setSelectedValue(selectedCourse);
        setFormData({
            ...formData,
            training: selectedCourse
        });
    };


    const handleInterestChange = (event) => {
        setFormData({
            ...formData,
            intrest: event.target.value,
        });
    };

    const handleExperienceChange = (event) => {
        setFormData({
            ...formData,
            experience: event.target.value,
        });
    };



    const [emailError, setEmailError] = useState('');

    // const validateEmail = (email) => {
    //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     return emailRegex.test(email);
    // };

    // const handleEmailChange = (e) => {
    //     const { value } = e.target;
    //     if (!validateEmail(value)) {
    //         setEmailError('Invalid email address');
    //     } else {
    //         setEmailError('');
    //     }
    //     handleInputChange(e);
    // };

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, userEmail: value });

        // Validate email
        setEmailError(!emailRegex.test(value));
    };









    const handleExpertiseChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            expertise: {
                ...prevFormData.expertise,
                [name]: value,
            },
        }));
    };


    const handleJobChange = (event) => {
        const { value } = event.target;
        const updatedJobPosition = [...formData.jobPosition]; // Create a copy of the current jobPosition array

        if (updatedJobPosition.includes(value)) {
            // If the selected value is already in the array, remove it
            updatedJobPosition.splice(updatedJobPosition.indexOf(value), 1);
        } else {
            // Otherwise, add it to the array
            updatedJobPosition.push(value);
        }

        setFormData({
            ...formData,
            jobPosition: updatedJobPosition // Update the jobPosition array in formData
        });
    };

    const handleNameChange = (e) => {
        const { name, value } = e.target;
        const lettersOnly = value.replace(/[^a-zA-Z]/g, '');
        console.log(lettersOnly);
        setFormData({ ...formData, [name]: lettersOnly });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDateChange = (date) => {
        if (date) {
            // Date is valid
            console.log(date);
            setFormData({ ...formData, specificDate: date.format('DD MMMM YYYY') });
        } else {
            // Date is null (cancel was clicked)
            setFormData({ ...formData, specificDate: '' }); // Or any default value you prefer
        }
    };

    const defaultDate = formData.specificDate ? dayjs(formData.specificDate) : null;
    const minDate = dayjs();




    useEffect(() => {
        if (formData.jobState) {
            const data = {
                country: 'United States',
                state: formData.jobState,
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', data, config)
                .then((response) => {
                    console.log(response.data);
                    setCities(response.data.data);
                    setCitiesFound(true);
                })
                .catch((error) => {
                    console.error(error);
                    setCities([]);
                    setCitiesFound(false);
                });
        }
    }, [formData.jobState]);












    return (
        <Box display='flex'>
            <DrawerHeader />

            <Grid container xs={12} md={12} lg={12} sx={{ justifyContent: 'center' }}>
                {/* <Box mt={10} width='100%' display='flex' alignItems='center' bgcolor='white'>
                    <Typography variant="body1" component="span" fontSize='24px' fontWeight={400}>
                        Dashboard
                    </Typography>
                </Box> */}
                <Paper elevation={10} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', paddingBottom: 20, bgcolor: '#E7E1F0' }}>
                    <Grid container xs={11} md={10} lg={10} p={{ xs: 2, lg: 5 }} justifyContent='center' mt={10} sx={{ bgcolor: '#E7E1F0', borderRadius: '20px' }}>
                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '24px', fontWeight: 500, color: '#3C2565' }}>
                                1. Hello! What should we call you?
                            </Typography>
                            <Grid container xs={12} md={12} lg={12} spacing={2}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        onFocus={() => setIsFirstFocused(true)}
                                        onBlur={() => setIsFirstFocused(false)}
                                        error={!!errors.firstName}
                                        // helperText={errors.firstName ? 'First Name is required' : ''}
                                        onKeyPress={(e) => {
                                            if (/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}

                                        sx={{
                                            mb: 2,
                                            bgcolor: 'white',
                                            borderRadius: '15px',
                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: isFirstFocused || formData.firstName ? 'transparent' : '#8C8C8C',
                                                fontSize: isFirstFocused || formData.firstName ? '0' : '18px',
                                                transition: 'all 0.3s ease',



                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                },
                                                backgroundColor: 'white',
                                                borderRadius: '15px', // Ensure border radius is applied

                                                fontSize: isMobile ? '16px' : '18px'
                                            }
                                        }}
                                    />


                                    {errors.firstName && (
                                        <Typography variant="body2" color="error" mb={2} mt={-1}>
                                            First Name is required
                                        </Typography>

                                    )}
                                </Grid>

                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        onFocus={() => setIsLastFocused(true)}
                                        onBlur={() => setIsLastFocused(false)}
                                        error={!!errors.lastName}
                                        onKeyPress={(e) => {
                                            if (/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}

                                        sx={{ mb: 2, bgcolor: 'white', borderRadius: '15px' }}
                                        InputLabelProps={{
                                            sx: {
                                                color: isLastFocused || formData.lastName ? 'transparent' : '#8C8C8C',
                                                fontSize: isLastFocused || formData.lastName ? '0' : '18px',
                                                transition: 'all 0.3s ease',



                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                },
                                                backgroundColor: 'white',
                                                borderRadius: '15px',
                                                fontSize: isMobile ? '16px' : '18px'
                                            }
                                        }}
                                    />

                                    {errors.lastName && (
                                        <Typography variant="body2" color="error" mb={2} mt={-1}>
                                            Last Name is required
                                        </Typography>
                                    )}
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} mb={2}>
                            <Typography variant="h6" sx={{ mt: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, color: '#3C2565' }}>
                                2. Awesome! Let's begin with your mobile number which will be served as a Platform ID.
                            </Typography>
                            <Grid container xs={12} md={12} lg={12} mt={2} display="flex" alignItems="left" sx={{ mb: 2 }} justifyContent='left'>
                                <Grid container xs={12} md={6} lg={6} justifyContent='space-between' paddingRight={2}>
                                    <Grid xs={2} md={2} lg={2}>
                                        <Select
                                            name="countryCode"
                                            value={formData.countryCode}
                                            onChange={handleInputChange}
                                            displayEmpty
                                            defaultValue={+1}
                                            error={!!errors.countryCode}
                                            inputProps={{ 'aria-label': 'Country code' }}
                                            sx={{ bgcolor: 'white', borderRadius: '15px' }}

                                        >
                                            {countryCodes.map((country) => (
                                                <MenuItem key={country.code} value={country.code} >
                                                    <Box display="flex" alignItems="center">
                                                        <FlagIcon code={country.flag} size={27} style={{ marginRight: 8 }} />
                                                        {country.code}
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>



                                    <Grid item xs={7} md={9} lg={9} alignItems='left'>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Mobile number"
                                            name="mobileNumber"
                                            value={formData.mobileNumber}
                                            onChange={handleInputChange}

                                            onFocus={() => setIsMobileFocused(true)}
                                            onBlur={() => setIsMobileFocused(false)}
                                            error={!!errors.mobileNumber}
                                            onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}


                                            sx={{

                                                bgcolor: 'white',
                                                borderRadius: '15px',
                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                },

                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    color: isMobileFocused || formData.mobileNumber ? 'transparent' : '#8C8C8C',
                                                    fontSize: isMobileFocused || formData.mobileNumber ? '0' : '18px',
                                                    transition: 'all 0.3s ease',




                                                    '& .MuiInputLabel-asterisk': {
                                                        color: 'red',
                                                        fontSize: '20px'
                                                    }
                                                }
                                            }}
                                            InputProps={{
                                                sx: {
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none'
                                                    },
                                                    fontSize: isMobile ? '16px' : '18px',
                                                    fontFamily: 'Times New Roman, serif',
                                                    borderRadius: '15px',

                                                }
                                            }}
                                        />

                                        {errors.mobileNumber && (
                                            <Typography variant="body2" color="error" mb={2} mt={1}>
                                                Mobile number is required
                                            </Typography>

                                        )}



                                    </Grid>
                                </Grid>

                                <Grid xs={12} md={6} lg={6} mt={{ xs: 2, lg: 0 }}>
                                    <TextField

                                        fullWidth
                                        label="Email"
                                        name="userEmail"
                                        value={formData.userEmail}
                                        onChange={handleEmailChange}
                                        onFocus={() => setIsEmailFocused(true)}
                                        onBlur={() => setIsEmailFocused(false)}
                                        sx={{

                                            bgcolor: 'white',
                                            borderRadius: '15px',
                                            '& .MuiInputLabel-asterisk': {
                                                color: 'red',
                                                fontSize: '20px'
                                            },

                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: isEmailFocused || formData.userEmail ? 'transparent' : '#8C8C8C',
                                                fontSize: isEmailFocused || formData.userEmail ? '0' : '18px',
                                                transition: 'all 0.3s ease',



                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                },
                                                backgroundColor: 'white',
                                                borderRadius: '15px', // Ensure border radius is applied

                                                fontSize: isMobile ? '16px' : '18px'
                                            }
                                        }}
                                    />
                                    {emailError && (
                                        <Typography variant="body2" color="error" mb={2} mt={1}>
                                            Invalid email
                                        </Typography>
                                    )}

                                </Grid>

                            </Grid>





                        </Grid>

                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Box display={'flex'} flexDirection={'column'}>
                                <Typography variant="h6" sx={{ mt: 2, mb: 1, fontSize: '25px', fontWeight: 400 }}>
                                    3. What is your current job position?
                                </Typography>
                                <FormGroup component={Grid} container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.jobPosition.includes("Community Health Worker/ Promotor de Salud/ Community Health Representative")}
                                                    onChange={handleJobChange}
                                                    value="Community Health Worker/ Promotor de Salud/ Community Health Representative"
                                                />
                                            }
                                            label="A. Community Health Worker/ Promotor de Salud/ Community Health Representative"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 500,
                                                        color: formData.jobPosition.includes("Community Health Worker/ Promotor de Salud/ Community Health Representative") ? '#3C2565' : 'inherit'
                                                    }
                                                }
                                            }}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    display: 'inline-block',
                                                },
                                                '& .MuiFormControlLabel-root': {
                                                    cursor: 'pointer',
                                                },
                                                '& .MuiCheckbox-root': {
                                                    marginTop: '-22px', // Adjust if needed to align the checkbox correctly
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={formData.jobPosition.includes("doula")}
                                                onChange={handleJobChange}
                                                value="doula"
                                            />}
                                            label="B. Doula"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 500,
                                                        color: formData.jobPosition.includes("doula") ? '#3C2565' : 'inherit'
                                                    }
                                                }
                                            }}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    display: 'inline-block',
                                                },
                                                '& .MuiFormControlLabel-root': {
                                                    cursor: 'pointer',
                                                },
                                                '& .MuiCheckbox-root': {
                                                    // marginTop: '-6px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={formData.jobPosition.includes("peerSupportSpecialist")}
                                                onChange={handleJobChange}
                                                value="peerSupportSpecialist"
                                            />}
                                            label="C. Peer support specialist"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 500,
                                                        color: formData.jobPosition.includes("peerSupportSpecialist") ? '#3C2565' : 'inherit'
                                                    }
                                                }
                                            }}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    display: 'inline-block',
                                                },
                                                '& .MuiFormControlLabel-root': {
                                                    cursor: 'pointer',
                                                },
                                                '& .MuiCheckbox-root': {
                                                    // marginTop: '-6px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={formData.jobPosition.includes("wellnessCoach")}
                                                onChange={handleJobChange}
                                                value="wellnessCoach"
                                            />}
                                            label="D. Wellness Coach"
                                            componentsProps={{
                                                typography: {
                                                    sx: {
                                                        fontSize: isMobile ? '13px' : '16px',
                                                        fontWeight: 500,
                                                        color: formData.jobPosition.includes("wellnessCoach") ? '#3C2565' : 'inherit'
                                                    }
                                                }
                                            }}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    display: 'inline-block',
                                                },
                                                '& .MuiFormControlLabel-root': {
                                                    cursor: 'pointer',
                                                },
                                                '& .MuiCheckbox-root': {
                                                    // marginTop: '-6px', // Adjust if needed to align the checkbox correctly
                                                },
                                            }}
                                        />
                                    </Grid>
                                </FormGroup>
                            </Box>

                        </Grid>

                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Typography variant="h6" sx={{ mt: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block', color: '#3C2565' }}>
                                4. What interests you most in a job? 
                            </Typography>


                            <RadioGroup value={formData.intrest} onChange={handleInterestChange} component={Grid} container spacing={2}>
                                <Grid item xs={12} sm={6} lg={12}>
                                    <FormControlLabel
                                        value="Career Advancement"
                                        control={<Radio />}
                                        label="A. Career Advancement"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.intrest === 'Career Advancement' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Opportunity to get hired"
                                        control={<Radio />}
                                        label="B. Opportunity to get hired"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.intrest === 'Opportunity to get hired' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Compensation"
                                        control={<Radio />}
                                        label="C. Compensation"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.intrest === 'Compensation' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Typography variant="h6" sx={{ mt: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block', color: '#3C2565' }}>
                                5. How many years of experience do you have?
                            </Typography>

                            <RadioGroup value={formData.experience} onChange={handleExperienceChange} component={Grid} container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Currently in training"
                                        control={<Radio />}
                                        label="A. Currently in training"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.experience === 'Currently in training' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                    {formData.experience === 'Currently in training' && (
                                        <Box sx={{ marginTop: '10px' }}>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="education-course-select-label"
                                                    id="education-course-select"
                                                    value={formData.training}
                                                    onChange={handleCourseChange}
                                                    displayEmpty
                                                    sx={{ bgcolor: 'white', borderRadius: 5 }}
                                                    renderValue={(selected) => {
                                                        if (!selected) {
                                                            return (
                                                                <Box display="flex" alignItems="center">
                                                                    <AccountBalanceIcon color="action" style={{ marginRight: 8 }} />
                                                                    <Typography color="textSecondary" fontWeight={700} fontSize={isMobile ? '12px' : '13px'}>
                                                                        Select a school or program name
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        }
                                                        return (
                                                            <Box display="flex" alignItems="center">
                                                                <AccountBalanceIcon color="action" style={{ marginRight: 8 }} />
                                                                {selected}
                                                            </Box>
                                                        );
                                                    }}
                                                >
                                                    {educationCourses.map((course) => (
                                                        <MenuItem key={course} value={course}>
                                                            {course}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Under 1 year"
                                        control={<Radio />}
                                        label="B. Under 1 year"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.experience === 'Under 1 year' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="1-2 years"
                                        control={<Radio />}
                                        label="C. 1-2 years"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.experience === '1-2 years' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="3-4 years"
                                        control={<Radio />}
                                        label="D. 3-4 years"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.experience === '3-4 years' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Above 5 years"
                                        control={<Radio />}
                                        label="E. Above 5 years"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.experience === 'Above 5 years' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Typography variant="h6" sx={{ mt: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block', color: '#3C2565' }}>
                                6. When are you able to start?
                            </Typography>
                            <RadioGroup value={formData.joiningDate} onChange={handleJoiningDateChange} component={Grid} container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Not sure"
                                        control={<Radio />}
                                        label="1. Not sure"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.joiningDate === 'Not sure' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="In few weeks"
                                        control={<Radio />}
                                        label="2. In few weeks"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.joiningDate === 'In few weeks' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="In few months"
                                        control={<Radio />}
                                        label="3. In few months"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.joiningDate === 'In few months' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Specific date"
                                        control={<Radio />}
                                        label="4. Specific date"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '16px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.joiningDate === 'Specific date' ? '#3C2565' : 'inherit',
                                        }}
                                    />
                                </Grid>
                            </RadioGroup>
                            {formData.joiningDate === 'Specific date' && (

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        name="specificDate"
                                        value={defaultDate}
                                        onChange={handleDateChange}
                                        minDate={minDate}
                                        sx={{ mt: 2, mb: 2, width: '320px', bgcolor: 'white' }}

                                    />
                                </LocalizationProvider>
                            )}


                        </Grid>

                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Typography variant="h6" sx={{ mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block', color: '#3C2565' }}>
                                7. Where would you like to work?
                            </Typography>

                            <Grid container xs={12} md={12} lg={12} spacing={2}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth sx={{ mb: 2, bgcolor: 'white', borderRadius: '15px' }}>
                                        <InputLabel
                                            required
                                            sx={{
                                                color: isLastFocused || formData.jobState ? 'transparent' : '#8C8C8C',
                                                fontSize: isLastFocused || formData.jobState ? '0' : '18px',
                                                transition: 'all 0.3s ease',
                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }}
                                        >
                                            State
                                        </InputLabel>
                                        <Select
                                            name="jobState"
                                            value={formData.jobState}
                                            onChange={handleNameChange}
                                            onFocus={() => setIsLastFocused(true)}
                                            onBlur={() => setIsLastFocused(false)}
                                            error={!!errors.jobState}
                                            sx={{
                                                fontSize: isMobile ? '16px' : '18px',
                                                borderRadius: '15px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }}
                                        >
                                            {usStates.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {errors.jobState && (
                                        <Typography variant="body2" color="error" mb={2} mt={-1}>
                                            State is required
                                        </Typography>
                                    )}

                                </Grid>


                                <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth error={!!errors.city}>
                                        <Select

                                            value={formData.city}
                                            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                            onBlur={() => setIsFirstFocused(false)}
                                            sx={{
                                                bgcolor: 'white',
                                                borderRadius: '15px',
                                                height: '60px',
                                                borderColor: 'white',
                                                fontSize: '16px'
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem disabled value="">
                                                Select City
                                            </MenuItem>
                                            {citiesFound ? (
                                                cities.map((city) => (
                                                    <MenuItem key={city} value={city}>
                                                        {city}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem value="">
                                                    <em>No cities found, please check state name</em>
                                                </MenuItem>
                                            )}
                                        </Select>
                                        {errors.city && (
                                            <Typography variant="body2" color="error" mb={2} mt={1}>
                                                City is required
                                            </Typography>
                                        )}
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Typography variant="h6" sx={{ mt: isMobile ? 1 : 1, mb: isMobile ? 0 : 1, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block', color: '#3C2565' }}>
                                {/* 8. What are your expertise and community management? */}
                                8. What is your area of expertise, and which communities have you worked with?
                            </Typography>
                            <Grid container xs={12} md={12} lg={12} spacing={2}>
                                <Grid item xs={12} lg={10} md={12}>
                                    <TextField
                                        name="childAndMaternalHealth"
                                        label={formData.expertise.childAndMaternalHealth.length === 0 ? "Child and Maternal Health" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.childAndMaternalHealth}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.childAndMaternalHealth.length > 0 ? '#a675e0' : 'white', // Change bgcolor based on selection
                                            color: formData.expertise.childAndMaternalHealth.length > 0 ? 'white' : 'black', // Change text color based on selection
                                            borderRadius: 5,
                                            height: formData.expertise.childAndMaternalHealth.length > 2 ? 'auto' : '56px', // Adjust height
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.childAndMaternalHealth.length > 0 ? 'white' : 'black', // Change dropdown text color
                                            },
                                            fontSize: formData.expertise.childAndMaternalHealth.length > 0 ? '20px' : '25px', // Adjust font size
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Child and Maternal Health: ${selected.join(', ')}`;
                                            },
                                            multiple: true, // Enable multiple selection
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',
                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {childandMaternalHealthOptions.map((option) => {
                                            const isSelected = formData.expertise.childAndMaternalHealth.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%" >
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px' // Change text color to green if selected
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>



                                <Grid item xs={12} lg={10} md={12}>
                                    <TextField
                                        name="communityRepresentation"
                                        label={formData.expertise.communityRepresentation.length === 0 ? "Community Representation" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.communityRepresentation}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.communityRepresentation.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.communityRepresentation.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.communityRepresentation.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Community Representation: ${selected.join(', ')}`;
                                            },
                                            multiple: true,
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {communityRepresentationOptions.map((option) => {
                                            const isSelected = formData.expertise.communityRepresentation.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'

                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} lg={10} md={12}>
                                    <TextField
                                        name="accessibilityAndEquity"
                                        label={formData.expertise.accessibilityAndEquity.length === 0 ? "Accessibility and Equity" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.accessibilityAndEquity}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.accessibilityAndEquity.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.accessibilityAndEquity.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.accessibilityAndEquity.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Accessibility and Equity: ${selected.join(', ')}`;
                                            },
                                            multiple: true,
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {accessibilityandEquityOptions.map((option) => {
                                            const isSelected = formData.expertise.accessibilityAndEquity.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'

                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} lg={10} md={12}>
                                    <TextField
                                        name="healthAndWellness"
                                        label={formData.expertise.healthAndWellness.length === 0 ? "Health and Wellness" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.healthAndWellness}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.healthAndWellness.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.healthAndWellness.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.healthAndWellness.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Health and Wellness: ${selected.join(', ')}`;
                                            },
                                            multiple: true,
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {healthandWellnessOptions.map((option) => {
                                            const isSelected = formData.expertise.healthAndWellness.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'

                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} lg={10} md={12}>
                                    <TextField
                                        name="socialJusticeAndInclusion"
                                        label={formData.expertise.socialJusticeAndInclusion.length === 0 ? "Social Justice and Inclusion" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.socialJusticeAndInclusion}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.socialJusticeAndInclusion.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.socialJusticeAndInclusion.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.socialJusticeAndInclusion.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Social Justice and Inclusion: ${selected.join(', ')}`;
                                            },
                                            multiple: true, // Enable multiple selection
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {socialJusticeandInclusionOptions.map((option) => {
                                            const isSelected = formData.expertise.socialJusticeAndInclusion.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} lg={10} md={12}>
                                    <TextField
                                        name="youthServices"
                                        label={formData.expertise.youthServices.length === 0 ? "Youth Services" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.expertise.youthServices}
                                        onChange={handleExpertiseChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.expertise.youthServices.length > 0 ? '#a675e0' : 'white',
                                            color: formData.expertise.youthServices.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.expertise.youthServices.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Youth Services: ${selected.join(', ')}`;
                                            },
                                            multiple: true, // Enable multiple selection
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >


                                        {youthServicesOptions.map((option) => {
                                            const isSelected = formData.expertise.youthServices.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>


                            </Grid>
                        </Grid>


                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block', color: '#3C2565' }}>
                                9. What certificates, certifications, or licenses do you currently hold, and in which state(s) are they valid? 
                            </Typography>

                            <Grid container xs={12} md={12} lg={12} spacing={2}>

                                <Grid item xs={12} lg={10} md={12} >
                                    <TextField
                                        name="healthcare"
                                        label={formData.certification.healthcare.length === 0 ? "Healthcare" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.certification.healthcare}
                                        onChange={handleCertificationChange}
                                        select
                                        sx={{
                                            minWidth: isMobile ? '100%' : 600,
                                            // height: 800,
                                            bgcolor: formData.certification.healthcare.length > 0 ? '#a675e0' : 'white',
                                            color: formData.certification.healthcare.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.certification.healthcare.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Healthcare: ${selected.join(', ')}`;
                                            },
                                            multiple: true,
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {healthcareOptions.map((option) => {
                                            const isSelected = formData.certification.healthcare.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%" >
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} lg={10} md={12} >
                                    <TextField
                                        name="specializedCareCertifications"
                                        label={formData.certification.specializedCareCertifications.length === 0 ? "Specialized Care Certifications" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.certification.specializedCareCertifications}
                                        onChange={handleCertificationChange}
                                        select
                                        sx={{
                                            // minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.certification.specializedCareCertifications.length > 0 ? '#a675e0' : 'white',
                                            color: formData.certification.specializedCareCertifications.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.certification.specializedCareCertifications.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Specialized Care Certifications: ${selected.join(', ')}`;
                                            },
                                            multiple: true,
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {specializedCareCertificationsOptions.map((option) => {
                                            const isSelected = formData.certification.specializedCareCertifications.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} lg={10} md={12} >
                                    <TextField
                                        name="mentalHealthandPeerSupport"
                                        label={formData.certification.mentalHealthandPeerSupport.length === 0 ? "Mental Health and Peer Support" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.certification.mentalHealthandPeerSupport}
                                        onChange={handleCertificationChange}
                                        select
                                        sx={{
                                            // minWidth: isMobile ? '100%' : 400,
                                            bgcolor: formData.certification.mentalHealthandPeerSupport.length > 0 ? '#a675e0' : 'white',
                                            color: formData.certification.mentalHealthandPeerSupport.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.certification.mentalHealthandPeerSupport.length > 0 ? 'white' : 'black',
                                            }
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Select a course';
                                                }
                                                return `Mental Health and Peer Support: ${selected.join(', ')}`;
                                            },
                                            multiple: true,

                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',

                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    >
                                        {mentalHealthandPeerSupportOptions.map((option) => {
                                            const isSelected = formData.certification.mentalHealthandPeerSupport.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>


                                <Grid item xs={12} lg={10} md={12} >
                                    <TextField
                                        name="otherOptions"
                                        label={formData.certification.otherOptions.length === 0 ? "Other Option" : ""}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formData.certification.otherOptions}
                                        onChange={handleOtherChange}
                                        select
                                        sx={{
                                            // minWidth: isMobile ? '100%' : 600,
                                            // width: isMobile ? '100%' : 600,
                                            
                                            bgcolor: formData.certification.otherOptions.length > 0 ? '#a675e0' : 'white',
                                            color: formData.certification.otherOptions.length > 0 ? 'white' : 'black',
                                            borderRadius: 5,
                                            '& .MuiSelect-select': {
                                                color: formData.certification.otherOptions.length > 0 ? 'white' : 'black',
                                            },
                                            height: formData.certification.otherOptions.length > 2 ? 'auto' : '56px',
                                        }}
                                        SelectProps={{
                                            renderValue: (selected) => {
                                                if (!selected || selected.length === 0) {
                                                    return 'Other Options';
                                                }
                                                return `Other Options: ${selected}`;
                                            },
                                            multiple: false,

                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#8C8C8C',
                                                fontSize: '18px',
                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                },

                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                   
                                                }
                                            }
                                            
                                        }}
                                    >
                                        {otherOptions.map((option) => {
                                            const isSelected = formData.certification.otherOptions.indexOf(option) > -1;
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    <Box display="flex" alignItems="center" width="100%">
                                                        {isSelected && (
                                                            <CheckOutlinedIcon sx={{ color: '#3c1767', marginRight: '8px' }} />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                flexGrow: 1,
                                                                color: isSelected ? '#3c1767' : 'inherit',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} lg={10} md={12} >
                                    <TextField
                                        required
                                        fullWidth
                                        label="State"
                                        name="state"
                                        size='normal'
                                        value={formData.certification.state}
                                        onChange={handleCertificationChange}
                                        sx={{ mt: 2, mb: 2, bgcolor: 'white', borderRadius: 5}}
                                        onFocus={() => setIsFirstFocused(true)}
                                        onBlur={() => setIsFirstFocused(false)}
                                        error={!!errors['certification.state']}
                                        onKeyPress={(e) => {
                                            if (/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}


                                        InputLabelProps={{
                                            sx: {
                                                color: isFirstFocused || formData.certification.state ? 'transparent' : '#8C8C8C',
                                                fontSize: isFirstFocused || formData.certification.state ? '0' : '18px',
                                                transition: 'all 0.3s ease',


                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                }, fontSize: isMobile ? '16px' : '18px',
                                                borderRadius: '15px',
                                                bgcolor: 'white'
                                            }
                                        }}
                                    />

                                    {errors['certification.state'] && (
                                        <Typography variant="body2" color="error" mb={2} mt={-1}>
                                            State is required
                                        </Typography>
                                    )}
                                </Grid>

                            </Grid>

                        </Grid>


                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block', color: '#3C2565' }}>
                                10. Are you interested in being a mentor?
                            </Typography>

                            <RadioGroup value={formData.mentor} onChange={handleMentorChange} component={Grid} container spacing={2} sx={{ width: '20%' }}>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="A.Yes"
                                        componentsProps={{
                                            typography: {
                                                sx: { fontSize: isMobile ? '13px' : '18px', fontWeight: 500, color: selectedValue === 'Yes' ? 'red' : 'inherit' }
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="B.No"
                                        componentsProps={{
                                            typography: {
                                                sx: { fontSize: isMobile ? '13px' : '18px', fontWeight: 500, color: selectedValue === 'Yes' ? 'red' : 'inherit' }
                                            }
                                        }}
                                    />
                                </Grid>

                            </RadioGroup>
                        </Grid>


                        <Grid item xs={12} lg={12} md={12} mb={2}>
                            <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: isMobile ? '20px' : '25px', fontWeight: 400, display: 'inline-block', color: '#3C2565' }}>
                                11. Where would you like your matches sent?
                            </Typography>


                            <RadioGroup value={formData.matches} onChange={(e) => handleMatchesChange(e.target.value)} component={Grid} container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Text"
                                        control={<Radio />}
                                        label="A. Text"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '18px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.matches === 'Text' ? '#800080' : 'inherit',
                                        }}
                                    />
                                    {formData.matches === 'Text' && (
                                        <TextField
                                            required
                                            fullWidth
                                            label="Mobile number"
                                            name="mobileNumber"
                                            value={formData.mobileNumber}
                                            onChange={handleInputChange}
                                            onFocus={() => setIsFirstFocused(true)}
                                            onBlur={() => setIsFirstFocused(false)}
                                            onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            sx={{ bgcolor: 'white', borderRadius: '15px', ml: 2, mb: 2 }}
                                            InputLabelProps={{
                                                sx: {
                                                    color: isFirstFocused || formData.mobileNumber ? 'transparent' : '#8C8C8C',
                                                    fontSize: isFirstFocused || formData.mobileNumber ? '0' : '18px',
                                                    transition: 'all 0.3s ease',
                                                    '& .MuiInputLabel-asterisk': {
                                                        color: 'red',
                                                        fontSize: '20px'
                                                    }
                                                }
                                            }}
                                            InputProps={{
                                                sx: {
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none'
                                                    },
                                                    fontSize: isMobile ? '16px' : '18px',
                                                    fontFamily: 'Times New Roman, serif'
                                                }
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Email"
                                        control={<Radio />}
                                        label="B. Email"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '18px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.matches === 'Email' ? '#800080' : 'inherit',
                                        }}
                                    />
                                    {formData.matches === 'Email' && (
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="userEmail"
                                            value={formData.userEmail}
                                            onChange={handleInputChange}
                                            onFocus={() => setIsLastFocused(true)}
                                            onBlur={() => setIsLastFocused(false)}
                                            sx={{
                                                mb: 2,
                                                bgcolor: 'white',
                                                borderRadius: '15px',
                                                '& .MuiInputLabel-asterisk': {
                                                    color: 'red',
                                                    fontSize: '20px'
                                                },
                                                mt: 1
                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    color: isLastFocused || formData.userEmail ? 'transparent' : '#8C8C8C',
                                                    fontSize: isLastFocused || formData.userEmail ? '0' : '18px',
                                                    transition: 'all 0.3s ease',
                                                }
                                            }}
                                            InputProps={{
                                                sx: {
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none'
                                                    },
                                                    backgroundColor: 'white',
                                                    borderRadius: '15px',
                                                    fontSize: isMobile ? '16px' : '18px'
                                                }
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="Both"
                                        control={<Radio />}
                                        label="C. Both Text and Email"
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontSize: isMobile ? '13px' : '18px',
                                                    fontWeight: 550,
                                                },
                                            },
                                        }}
                                        sx={{
                                            color: formData.matches === 'Both' ? '#800080' : 'inherit',
                                        }}
                                    />
                                    {formData.matches === 'Both' && (
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Mobile number"
                                                name="mobileNumber"
                                                value={formData.mobileNumber}
                                                onChange={handleInputChange}
                                                onFocus={() => setIsFirstFocused(true)}
                                                onBlur={() => setIsFirstFocused(false)}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                sx={{ bgcolor: 'white', borderRadius: '15px', ml: 2 }}
                                                InputLabelProps={{
                                                    sx: {
                                                        color: isFirstFocused || formData.mobileNumber ? 'transparent' : '#8C8C8C',
                                                        fontSize: isFirstFocused || formData.mobileNumber ? '0' : '18px',
                                                        transition: 'all 0.3s ease',
                                                        '& .MuiInputLabel-asterisk': {
                                                            color: 'red',
                                                            fontSize: '20px'
                                                        }
                                                    }
                                                }}
                                                InputProps={{
                                                    sx: {
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none'
                                                        },
                                                        fontSize: isMobile ? '16px' : '18px',
                                                        fontFamily: 'Times New Roman, serif'
                                                    }
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="userEmail"
                                                value={formData.userEmail}
                                                onChange={handleInputChange}
                                                onFocus={() => setIsLastFocused(true)}
                                                onBlur={() => setIsLastFocused(false)}
                                                sx={{
                                                    mb: 2,
                                                    bgcolor: 'white',
                                                    borderRadius: '15px',
                                                    '& .MuiInputLabel-asterisk': {
                                                        color: 'red',
                                                        fontSize: '20px'
                                                    },
                                                    ml: 2,
                                                    mt: 2
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        color: isLastFocused || formData.userEmail ? 'transparent' : '#8C8C8C',
                                                        fontSize: isLastFocused || formData.userEmail ? '0' : '18px',
                                                        transition: 'all 0.3s ease',
                                                    }
                                                }}
                                                InputProps={{
                                                    sx: {
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none'
                                                        },
                                                        backgroundColor: 'white',
                                                        borderRadius: '15px',
                                                        fontSize: isMobile ? '16px' : '18px'
                                                    }
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Grid>
                            </RadioGroup>
                        </Grid>


                        <Grid item xs={12} lg={12} md={12} mb={2} mt={2}>
                            <Grid container xs={12} md={12} lg={12} justifyContent='center'>

                                <Button
                                    onClick={handleSubmit}
                                    sx={{
                                        backgroundColor: '#3C2565',
                                        color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#3c1767',
                                        },
                                        borderRadius: 8,
                                        width: '200px',
                                        height: '40px',

                                        fontSize: '16px',
                                        fontWeight: 500,
                                        textTransform: 'none'
                                    }}
                                >

                                    Submit
                                </Button>

                                {showSuccessAlert && (
                                    <Alert severity="success" style={{ marginLeft: '20px' }}>Data saved successfully</Alert>
                                )}

                                {showWarningAlert && (
                                    <Alert severity="warning" style={{ marginLeft: '20px' }}>Form is incomplete.</Alert>
                                )}


                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={loading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>


                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>

            </Grid >

        </Box>
    )
}


