
import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, useContext } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Input from '@mui/material/Input';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://dshgsonic.com/">
        DSHGSonic
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export const ForgotPassOrganization = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [signInType, setSignInType] = useState('first');
  const navigate = useNavigate();
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  const [otpTimestamp, setOtpTimestamp] = useState(0);
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [userotp, setUserOtp] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  

  const handleSwitchSignIn = () => {
    setSignInType(signInType === 'first' ? 'second' : 'first');
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Validate that password and confirm password match
    if (password !== confirmPassword) {
      enqueueSnackbar('Passwords do not match', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      return;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateorganizationpassword`, {
        workEmail: email,
        newPassword: password,
      });
  
      if (response.status === 200) {
        enqueueSnackbar('Password changed successfully!', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        navigate('/organizationlogin');
      } else {
        enqueueSnackbar('Failed to change password', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    } catch (error) {
      console.error('Error changing password:', error.response ? error.response.data : error.message);
      enqueueSnackbar('An error occurred. Please try again.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  };
  

  const handleCheckEmail = async (event) => {
    event.preventDefault();


    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/organizationforgotpassword`, {
        workEmail: email,
      });
  

      if (response.status === 200) {
        setOtpSent(true);
        setOtp(response.data.otp);
        setOtpTimestamp(Date.now());
        enqueueSnackbar('OTP sent successfully!', { variant: 'sucess', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        console.error('Failed to send OTP:', response.data.message);
      }
    } catch (error) {
      console.error('Error sending OTP:', error.response ? error.response.data : error.message);
    }
  };

  const handleSubmitOtp = () => {
    const currentTime = Date.now();
    const otpValidityDuration = 5 * 60 * 1000;

    if (!userotp) {
      enqueueSnackbar('Please fill in the OTP field', { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      return;
    }

    if (otp === userotp) {
      if (currentTime - otpTimestamp <= otpValidityDuration) {
        setOtpVerified(true);
        enqueueSnackbar('Verification code verified successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        enqueueSnackbar('Verification code expired. Resend the code.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    } else {
      enqueueSnackbar('Incorrect verification code.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  };

  return (
    <Grid
      container
      sx={{
        background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
        // display: 'flex',
        minHeight: '100vh'
      }}
    >
      <Grid item xs={12} sm={8} md={6} lg={6} sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? 0 : 2,
        margin: 'auto',
        flexDirection:'column'
      }}>
        <CssBaseline />
        <Paper elevation={10} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 5,
          borderRadius: "25px",
          width: { xs: '90%', md: '60%' }
        }}  >
          <Grid container direction="column" alignItems="center" sx={{
            borderRadius: "20px 20px 0 0"
          }} xs={12}>
            <Grid item>
              <img
                src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                alt="Logo"
                style={{ width: '100px', marginRight: '5px' }}
              />
            </Grid>
            <Grid item>
              <Typography component="h1" variant="h3" marginTop={2}>
                Reset Your Password
              </Typography>
            </Grid>
          </Grid>
          <Grid container component="form" justifyContent='center' noValidate sx={{ mt: 1 }} xs={12}>
            <Grid item xs={9}>
              <TextField
                margin="normal"
                variant='standard'
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon color='primary' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 2, mb: 2, borderRadius: 4 }}
                onClick={handleCheckEmail}
                disabled={otpVerified} 
              >
                {otpSent ? "Resend Code" : "Send Code"}
              </Button>
            </Grid>

            {otpSent && (
              <>
                <Grid xs={9}>
                  <TextField
                    required
                    fullWidth
                    id="outlined-required"
                    label="OTP"
                    placeholder="Enter OTP"
                    margin="2"
                    onChange={(e) => setUserOtp(e.target.value)}
                    disabled={otpVerified} 
                  />
                </Grid>

                <Grid xs={9}>
                  <Button
                    variant="contained"
                    sx={{ mt: 2, mb: 2, borderRadius: 4, bgcolor: '#ecb61e' }}
                    onClick={handleSubmitOtp}
                    disabled={otpVerified} 
                  >
                    Verify Code
                  </Button>
                </Grid>
              </>
            )}

            {otpVerified && (
              <>
                <Grid item xs={9}>
                  <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={handlePasswordChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={9}>
                  <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                    <Input
                      id="outlined-adornment-password"
                      type='password'
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      // endAdornment={
                      //   <InputAdornment position="end">
                      //     <IconButton
                      //       aria-label="toggle password visibility"
                      //       onClick={handleClickShowPassword}
                      //       edge="end"
                      //     >
                      //       {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                      //     </IconButton>
                      //   </InputAdornment>
                      // }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={9}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2, borderRadius: 4 }}
                    onClick={handleSubmit}
                  >
                    Change Password
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
        <Grid item xs={12} sm={12} md={12} lg={12} pt={4}>
          <Copyright />
        </Grid>
      </Grid>


    </Grid>
  );
};
