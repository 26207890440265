import React from 'react';
import { Box, Button, Paper, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DrawerHeader from '../../../components/DrawerHeader'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';

const Admins = () => {
  const [adminData, setAdminData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newAdmin, setNewAdmin] = useState({ userName: '', password: '' });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/admins`);

        setAdminData(response.data);
      } catch (error) {
        console.error('Error fetching admin data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdminData();
  }, []);



  const handleAdminDelete = async (adminId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_URL}/api/deleteadmin/${adminId}`);
      enqueueSnackbar('Admin deleted successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      })
    } catch (error) {
      enqueueSnackbar('Something went wrong!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      })
    }
  };


  const handleAddAdmin = () => {
    navigate('/addadmin')
  };


  const isOnlyAdmin = adminData.length === 1;


  return (
    <Box sx={{ display: 'flex' }}>
      <DrawerHeader />
      <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100%', marginTop: "100px", justifyContent: 'center' }}>
        <Paper elevation={20} style={{ padding: 16, height: '100%' }}>
          <Grid container xs={12} md={12} lg={12} justifyContent='space-between' >
            <Typography variant="h4" gutterBottom justifyContent={'center'} mb={3}>
              Admins
            </Typography>



            <Button
              variant='contained'
              sx={{ backgroundColor: '#3C2565', color: '#fff', width: '180px', height: '40px' }}
              onClick={handleAddAdmin}
            >
              Add Admin
            </Button>


          </Grid>
          {adminData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: '16px' }}>Admin Name</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminData.map((admin, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: '14px' }}>{admin.userName}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleAdminDelete(admin._id)}
                          disabled={isOnlyAdmin}
                        >
                          <DeleteIcon sx={{ mr: 1 }} />
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', height: '100vh' }}>
              {/* <CircularProgress /> */}
              <Typography>
                No admin available
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>


  )
}

export default Admins