import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { Avatar, Box, Button, Typography, IconButton, Grid } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutline';
import EditCalendarOtlinedIcon from '@mui/icons-material/EditCalendar';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { AppBar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DrawerOrganization from './DrawerOrganization';



export const JobDetailsOrganization = () => {


    const location = useLocation();
    const { id, jobid } = location.state || {};
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchJobDetails = async () => {
            
            try {
                const response = await axios.post(`${process.env.REACT_APP_URL}/api/jobdataorganization`, { jobid });
                const jobListing = response.data;
                


                if (jobListing && jobListing.length > 0) {
                    setJob(jobListing[0]);

                } else {
                    setJob(null);
                }

                setLoading(false);
                // console.log("59^^^^^^^^^^^", response.data);
            } catch (error) {
                console.error("There was an error fetching the job listing!", error);
                setLoading(false);
            }
        };

        fetchJobDetails();
    }, [jobid]);

    // console.log(JobListing.jobTitle);



    // if (!hiringDetails) {
    //     return (
    //         <Box
    //             sx={{
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //                 height: '100vh',
    //             }}
    //         >
    //             <CircularProgress />
    //         </Box>
    //     );
    // }



    // console.log(hiringDetails);


    const handleBack = () => {
        navigate(`/organizationdashboard`, { state: { id } });
    }

    const handleEditClick = () => {


        navigate(`/editjoborganization`, { state: { id, jobid } });

       
        window.scrollTo({ top: 0, behavior: 'smooth' });

    };

    return (
        <Box sx={{ display: 'flex' , bgcolor:'#E7E1F0' }}>
            <DrawerOrganization id={id} />
            <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', width: '100%'  }} marginTop={10}>


                <Typography sx={{ fontSize: '35px', fontWeight: 600, color: '#3C2565', mb: 2 }}>
                    Job Details
                </Typography>
                <Box width='90%' p={5} borderRadius={5} bgcolor='white'>






                    <Grid container xs={12} md={12} lg={12} mb={2} >
                        <Grid item xs={12} md={12} lg={12} sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                1. Job listing details
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Job title
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.jobTitle || ''}
                            </Typography>
                        </Grid>


                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Company Overview
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.companyOverview || ''}
                            </Typography>
                        </Box> */}
                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Company Overview
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.companyOverview || ''}
                            </Typography>
                        </Grid>



                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Job Description
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.jobDescription || ''}                            </Typography>
                        </Box> */}

                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Job Description
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.jobDescription || ''}
                            </Typography>
                        </Grid>

                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Qualifications and Experience
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.qualifications || ''}                            </Typography>
                        </Box> */}
                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Qualifications and Experience
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.qualifications || ''}
                            </Typography>
                        </Grid>



                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Skills Required
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.skills || ''}
                            </Typography>
                        </Box> */}
                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Skills Required
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.skills || ''}
                            </Typography>
                        </Grid>

                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Language SKills
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.languageSkills || ''}
                            </Typography>
                        </Box> */}

                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Language SKills
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.languageSkills || ''}
                            </Typography>
                        </Grid>

                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Job Type
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.jobType || ''}
                            </Typography>
                        </Box> */}

                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Job Type
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.jobType || ''}
                            </Typography>
                        </Grid>

                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Location
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.location || ''}
                            </Typography>
                        </Box> */}
                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Location
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.location || ''}
                            </Typography>
                        </Grid>

                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Working Hours/Type of Employment
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.employmentType || ''}
                            </Typography>
                        </Box> */}

                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Working Hours/Type of Employment
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.employmentType || ''}
                            </Typography>
                        </Grid>

                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Salary/Compensation
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.salary || ''}
                            </Typography>
                        </Box> */}

                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Salary/Compensation
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.salary || ''}
                            </Typography>
                        </Grid>


                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Training and Support
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.training || ''}
                            </Typography>
                        </Box> */}

                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Training and Support
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.training || ''}
                            </Typography>
                        </Grid>

                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Community Engagement
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.communityEngagement || ''}
                            </Typography>
                        </Box> */}
                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Community Engagement
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.communityEngagement || ''}
                            </Typography>
                        </Grid>

                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Application Deadline
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.applicationDeadline || ''}
                            </Typography>
                        </Box> */}

                        <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                            <Typography fontSize={18} fontWeight={500}>
                                Application Deadline
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.applicationDeadline || ''}
                            </Typography>
                        </Grid>

                        {/* <Box display='flex' justifyContent='center' marginBottom={2}>
                            <Typography width='40%' fontSize={18} fontWeight={500}>
                                Diversity Statement
                            </Typography>

                            <Typography width='60%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :{job?.diversityStatement || ''}
                            </Typography>
                        </Box> */}

                        {job?.diversityStatement && (
                            <>
                                <Grid item xs={12} md={3} lg={3} marginBottom={2}>
                                    <Typography fontSize={18} fontWeight={500}>
                                        Diversity Statement
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={9} lg={9}>
                                    <Typography fontWeight={300} fontSize={18} color='#8C8C8C'>
                                        :{job.diversityStatement}
                                    </Typography>
                                </Grid>
                            </>
                        )}







                    </Grid>







                    <Box mt={12} display='flex' justifyContent='center' alignItems='center'>
                        <Button

                            onClick={handleBack}
                            sx={{
                                backgroundColor: '#3C2565',
                                color: '#ffffff',
                                marginRight: 3,
                                '&:hover': {
                                    backgroundColor: '#3c1767',
                                },
                                borderRadius: 8,
                                width: '300px',
                                height: '40px',

                                fontSize: '16px',
                                fontWeight: 500,
                                textTransform: 'none',
                                mb: 5
                            }}
                        >

                            Back to Dashboard
                        </Button>

                        <Button

                            onClick={handleEditClick}
                            sx={{
                                backgroundColor: '#F77E09',
                                color: '#ffffff',
                                marginRight: 3,
                                '&:hover': {
                                    backgroundColor: '#3c1767',
                                },
                                borderRadius: 8,
                                width: '300px',
                                height: '40px',

                                fontSize: '16px',
                                fontWeight: 500,
                                textTransform: 'none',
                                mb: 5
                            }}
                        >

                            Edit
                        </Button>
                    </Box>



                </Box>

            </Box>
        </Box>
    )
}
export default JobDetailsOrganization;
