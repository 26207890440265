import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from '@mui/material';

const DigitalSignature = () => {
  const sigCanvas = useRef({});


  const clearSignature = () => {
    sigCanvas.current.clear();
  };


  const saveSignature = () => {
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    console.log(dataURL);
  };

  return (
    <div>
      <h2>Digital Signature</h2>
      <SignatureCanvas 
        ref={sigCanvas} 
        penColor="black" 
        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} 
      />
      <div>
        <Button variant="contained" color="primary" onClick={saveSignature}>
          Save Signature
        </Button>
        <Button variant="contained" color="secondary" onClick={clearSignature}>
          Clear Signature
        </Button>
      </div>
    </div>
  );
};

export default DigitalSignature;

// npm install react-native-signature-canvas
// import React, { useRef } from 'react';
// import { View, Button } from 'react-native';
// import SignatureCapture from 'react-native-signature-canvas';

// const DigitalSignature = () => {
//   const signRef = useRef(null);

//   // Called when the signature is saved
//   const handleOK = (signature) => {
//     console.log(signature); // base64 encoded png
//   };

//   // Called when the clear button is pressed
//   const handleClear = () => {
//     signRef.current.clearSignature();
//   };

//   return (
//     <View style={{ flex: 1 }}>
//       <SignatureCapture
//         ref={signRef}
//         onOK={handleOK}
//         onEmpty={() => console.log('Empty')}
//         descriptionText="Sign"
//         clearText="Clear"
//         confirmText="Save"
//         webStyle={`
//           .m-signature-pad {
//             box-shadow: none;
//             border: none;
//           }
//           .m-signature-pad--body {
//             border: none;
//           }
//           .m-signature-pad--footer {
//             display: none;
//           }
//         `}
//       />
//       <Button title="Clear Signature" onPress={handleClear} />
//     </View>
//   );
// };

// export default DigitalSignature;
