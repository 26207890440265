import { Box, Typography } from '@mui/material'
import React from 'react'

import { useLocation } from 'react-router-dom'
import DrawerOrganization from './DrawerOrganization';
import BoxCardOrganization from './BoxCardOrganization';

const OrganizationDashboard = () => {
  const location = useLocation();
  const { id } = location.state || {};



  return (
    <Box display='flex'>
      <DrawerOrganization id={id} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100%', marginTop: "50px", display:'flex', justifyContent: 'center' ,alignItems:'start' ,  bgcolor:'#E7E1F0' , minHeight:'100vh'}}>
        <BoxCardOrganization id={id} />
      </Box>

    </Box>
  )
}

export default OrganizationDashboard