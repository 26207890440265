import { Grid, Paper } from '@mui/material'
import { tokens } from "../../theme";
import React from 'react'
import { CardContent, Typography, CardActions, Button, Tooltip } from "@mui/material"
// import { useTheme } from '@emotion/react';
import { useTheme } from '@mui/material/styles'
//import { useContext } from 'react';
import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import axios from 'axios';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';


export const BoxCard = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //const colorMode = useContext(ColorModeContext);
  const [hiringCount, setHiringCunt] = useState(0);
  const [talentCount, setTalentCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  const [jobListingsCount, setJobListingsCount] = useState(0);
  const [activeJobListingsCount, setActiveJobListingsCount] = useState(0);
  const navigate = useNavigate();






  useEffect(() => {
    userNoHiring()
    userNoTalent()
    fetchJobListingsCount()
    fetchActiveJobListingsCount()
  }, []);





  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the window after clicking "OK"
        window.location.reload();
      }
    });
  }




  const userNoTalent = () => {
    fetch(`${process.env.REACT_APP_URL}/nooftalent`, {
      method: "POST",
      headers: { "Content-type": "application/json" }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const userTalentCount = data.count;
        localStorage.setItem('usertalentCount', userTalentCount)
        setTalentCount(userTalentCount)
        // Log the count received from the backend
      })
      .catch(error => {
        console.error('Error fetching count:', error);
      });
  }


  const userNoHiring = () => {
    fetch(`${process.env.REACT_APP_URL}/noofhiring`, {
      method: "POST",
      headers: { "Content-type": "application/json" }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const userHiringCount = data.count;
        localStorage.setItem('userhiringCount', userHiringCount)
        setHiringCunt(userHiringCount)

      })
      .catch(error => {
        console.error('Error fetching count:', error);
      });
  }


  const fetchJobListingsCount = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/job-listings-count`);
      setJobListingsCount(response.data.totalJobListings);

    } catch (err) {
      console.error('Error fetching count:', err);
    }
  };


  const fetchActiveJobListingsCount = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/active-job-listings-count`);
      setActiveJobListingsCount(response.data.totalActiveJobs);

    } catch (err) {
      console.error('Error fetching count:', err);
    }
  };


  const handleHiringData = () => {
    navigate('/hiringdata')
  }


  const handleTalentdata = () => {
    navigate('/talentdata')
  }




  return (



    <Grid xs={12} sm={12} md={12} lg={12} container marginTop="20px" spacing={3}>



      <Grid xs={12} sm={6} md={3} lg={3} item display="flex" margin="auto" >
        <Paper elevation={8} style={{ backgroundColor: '#e0e0e0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {/* <Card style={{ margin: "5px", height: '55px' }} >
            <CardContent style={{ display: 'flex', flexDirection: 'row' }}  >

              <Grid xs={10} lg={10} md={10} justifyContent='start' display='flex' flexDirection='row'>
                <PersonIcon sx={{ mr: 2 , color: '#FF6900' }} />
                <Typography variant="h4" >
                  Talent Onboarding Data
                </Typography>
              </Grid>
              <Typography variant="h3" component="div" justifyContent='end' fontFamily='Times New Roman, serif'>
                {talentCount}
              </Typography>
            </CardContent>
          </Card> */}
          <Card sx={{ backgroundColor: '#17A2B8', height: "100%" }}>
            <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="h5"
                color="text.secondary"
                gutterBottom
                sx={{ textAlign: 'center', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >

                <PersonIcon
                  sx={{
                    marginLeft: 'auto',
                    // marginTop: '9px',
                    marginRight:'10px',
                    fontSize: 30,
                    transition: 'color 0.3s, transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      color: 'yellow',
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                />

                Talent Data
              </Typography>
              <Typography
                sx={{
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                }}
                component="div"
              >
                {talentCount}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'center',
                '&:hover': {
                  '& > button': {
                    backgroundColor: '#3C2565',
                  },
                },
              }}
            >
              <Button
                fullWidth
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,.30)',
                }}
                onClick={handleTalentdata}
              >
                More info
                <ArrowCircleRightRoundedIcon />
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>

      <Grid xs={12} sm={6} md={3} lg={3} item display="flex" margin="auto">
        <Paper elevation={8} style={{ backgroundColor: '#e0e0e0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {/* <Card style={{ margin: "5px", height: '55px' }} >
            <CardContent style={{ display: 'flex', flexDirection: 'row' }}  >

              <Grid xs={10} lg={10} md={10} justifyContent='start' display='flex' flexDirection='row'>
                <HomeWorkIcon sx={{ mr: 2, color: '#FF6900' }} />
                <Typography variant="h4" >
                  Organization Data
                </Typography>
              </Grid>
              <Typography variant="h3" component="div" fontFamily='Times New Roman, serif'>
                {hiringCount}
              </Typography>
            </CardContent>
          </Card> 
          color: '#FF6900' */}

          <Card sx={{ backgroundColor: '#17A2B8', height: "100%" }}>
            <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="h5"
                color="text.secondary"
                gutterBottom
                sx={{ textAlign: 'center', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >

                <HomeWorkIcon
                  sx={{
                    marginLeft: 'auto',
                    // marginTop: '9px',
                    marginRight:'10px',
                    fontSize: 30,
                    transition: 'color 0.3s, transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      color: 'yellow',
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                />

                Hiring Data
              </Typography>
              <Typography
                sx={{
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                }}
                component="div"
              >
                {hiringCount}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'center',
                '&:hover': {
                  '& > button': {
                    backgroundColor: '#3C2565',
                  },
                },
              }}
            >
              <Button
                fullWidth
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,.30)',
                }}
                onClick={handleHiringData}
              >
                More info
                <ArrowCircleRightRoundedIcon />
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>

      <Grid xs={12} sm={6} md={3} lg={3} item display="flex" margin="auto">
        <Paper elevation={8} style={{ backgroundColor: '#e0e0e0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {/* <Card style={{ margin: "5px", height: '55px' }} >
            <CardContent style={{ display: 'flex', flexDirection: 'row' }}  >

              <Grid xs={10} lg={10} md={10} justifyContent='start' display='flex' flexDirection='row'>
                <WorkIcon sx={{ mr: 2, color: '#FF6900' }} />
                <Typography variant="h4" >
                  All Jobs
                </Typography>
              </Grid>
              <Typography variant="h3" component="div" fontFamily='Times New Roman, serif'>
                {jobListingsCount}
              </Typography>
            </CardContent>
          </Card> */}
          <Card sx={{ backgroundColor: '#17A2B8', height: "100%" }}>
            <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="h5"
                color="text.secondary"
                gutterBottom
                sx={{ textAlign: 'center', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >

                <WorkIcon
                  sx={{
                    marginLeft: 'auto',
                    // marginTop: '9px',
                    marginRight:'10px',
                    fontSize: 30,
                    transition: 'color 0.3s, transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      color: 'yellow',
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                />

                All Jobs
              </Typography>
              <Typography
                sx={{
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                }}
                component="div"
              >
                {jobListingsCount}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'center',
                '&:hover': {
                  '& > button': {
                    backgroundColor: '#3C2565',
                  },
                },
              }}
            >
              <Button
                fullWidth
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,.30)',
                }}
                // onClick={handleTalentdata}
              >
                More info
                <ArrowCircleRightRoundedIcon />
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>

      <Grid xs={12} sm={6}  md={3} lg={3} item display="flex" margin="auto">
        <Paper elevation={8} style={{ backgroundColor: '#e0e0e0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {/* <Card style={{ margin: "5px", height: '55px' }} >
            <CardContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}  >

              <Grid xs={10} lg={10} md={10} justifyContent='start' display='flex' flexDirection='row' >
                <WorkHistoryIcon sx={{ mr: 2, color: '#FF6900' }} />
                <Typography variant="h4" >
                  Active Jobs
                </Typography>
              </Grid>
              <Typography variant="h3" component="div" fontFamily='Times New Roman, serif'>
                {activeJobListingsCount}
              </Typography>
            </CardContent>

          </Card> */}
          <Card sx={{ backgroundColor: '#17A2B8', height: "100%" }}>
            <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="h5"
                color="text.secondary"
                gutterBottom
                sx={{ textAlign: 'center', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >

                <WorkHistoryIcon
                  sx={{
                    marginLeft: 'auto',
                    // marginTop: '9px',
                    marginRight:'10px',
                    fontSize: 30,
                    transition: 'color 0.3s, transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      color: 'yellow',
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                />

                Active Jobs
              </Typography>
              <Typography
                sx={{
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                }}
                component="div"
              >
                {activeJobListingsCount}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'center',
                '&:hover': {
                  '& > button': {
                    backgroundColor: '#3C2565',
                  },
                },
              }}
            >
              <Button
                fullWidth
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,.30)',
                }}
                // onClick={handleTalentdata}
              >
                More info
                <ArrowCircleRightRoundedIcon />
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>


    </Grid>

  )

}


export default BoxCard;
