import { Box, Grid, Typography, InputAdornment, TextField, FormControl, FormControlLabel, InputLabel, IconButton, OutlinedInput, Button, Input, Avatar } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';



const defaultTheme = createTheme();
const SignUp = () => {

    const [useremail, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showForm, setShowForm] = useState('talent')
    const [organizationName, setOrganizationName] = useState('');
    const [representativeName, setRepresentativeName] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [emailError, setEmailError] = useState(false);
    const [error, setError] = useState('');
    const [selectedTalentImage, setSelectedTalentImage] = useState(null);
    const [selectedOrganizationImage, setSelectedOrganizationImage] = useState(null);
    const [talentFile, setTalentFile] = useState(null);
    const [organizationFile, setOrganizationFile] = useState(null);
    const [loading, setLoading] = useState(false);
    



    const handleTalentImageChange = (event) => {
        const file = event.target.files[0];
        let errors = {};

        if (file) {
            if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
                // errors.file = 'Only JPEG, PNG, and GIF image formats are allowed';
                setFormErrors((prevErrors) => ({ ...prevErrors, talentFile: 'Only JPEG, PNG, and GIF image formats are allowed' }));
                // setFormErrors(errors);
            } else {
                setFormErrors((prevErrors) => ({ ...prevErrors, file: '' }));
                setSelectedTalentImage(URL.createObjectURL(file));
                setTalentFile(file)
            }
        } else {
            // errors.file = 'Profile image is required';
            setFormErrors((prevErrors) => ({ ...prevErrors, talentFile: 'Profile image is required' }));
            // setFormErrors(errors);
        }
    };



    const handleOrganizationImageChange = (event) => {
        const file = event.target.files[0];
        let errors = {};

        if (file) {
            // Check for valid image formats
            const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (validImageTypes.includes(file.type)) {
                setSelectedOrganizationImage(URL.createObjectURL(file));
                setOrganizationFile(file)
            } else {
                // errors.file = 'Only JPEG, PNG, and GIF image formats are allowed';
                setFormErrors((prevErrors) => ({ ...prevErrors, organizationFile: 'Only JPEG, PNG, and GIF image formats are allowed' }));
            }
        } else {
            // errors.file = 'Organization image is required';
            setFormErrors((prevErrors) => ({ ...prevErrors, talentFile: 'Profile image is required' }));
        }

        // Set errors for image
        setFormErrors(prevErrors => ({ ...prevErrors, ...errors }));
    };

    // const [formErrors, setFormErrors] = useState({});


    const [formErrors, setFormErrors] = useState({});
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('md'));

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateTalentForm = () => {
        let errors = {};
        if(!talentFile) errors.selectedTalentImage = 'Profile image is required';
        if (!firstName.trim()) errors.firstName = 'First Name is required';
        if (!lastName.trim()) errors.lastName = 'Last Name is required';
        if (!useremail.trim()) errors.useremail = 'Email is required';
        else if (!validateEmail(useremail)) errors.useremail = 'Please enter a valid email address';
        if (!mobileNumber.trim()) errors.mobileNumber = 'Mobile Number is required';
        if (!password.trim()) errors.password = 'Password is required';
        else if (password.length < 8) errors.password = 'Password must be at least 8 characters long';
        else if (!isValidPassword(password)) {
            errors.password = 'Password must contain at least one uppercase, one lowercase, one digit, and one special character.';
        }
        if (!confirmPassword.trim()) errors.confirmPassword = 'Confirm Password is required';
        else if (password !== confirmPassword) errors.confirmPassword = 'Passwords do not match';

        if (!selectedTalentImage) {
            errors.file = 'Profile image is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0; // return true if no errors
    }

    const validateOrganizationForm = () => {
        let errors = {};
        if (!organizationName.trim()) errors.organizationName = 'organizationName is required';
        if(!organizationFile) errors.organizationFile = 'Profile image is required';
        if (!firstName.trim()) errors.firstName = 'First Name is required';
        if (!lastName.trim()) errors.lastName = 'Last Name is required';
        if (!useremail.trim()) errors.useremail = 'Email is required';
        else if (!validateEmail(useremail)) errors.useremail = 'Please enter a valid email address';
        if (!mobileNumber.trim()) errors.mobileNumber = 'Mobile Number is required';
        if (!password.trim()) errors.password = 'Password is required';
        else if (password.length < 8) errors.password = 'Password must be at least 8 characters long';
        if (!confirmPassword.trim()) errors.confirmPassword = 'Confirm Password is required';
        else if (password !== confirmPassword) errors.confirmPassword = 'Passwords do not match';

        if (!selectedOrganizationImage) {
            errors.selectedOrganizationImage = 'Profile image is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0; // return true if no errors
    }



    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Remove email error as the user starts typing
        if (value === '') {
            // setEmailError(false);
            setFormErrors((prevErrors) => ({ ...prevErrors, useremail: '' }));
        } else if (!validateEmail(value)) {
            // setEmailError(true);
            setFormErrors((prevErrors) => ({ ...prevErrors, useremail: 'Please enter a valid email address.' }));
        } else {
            // setEmailError(false);
            setFormErrors((prevErrors) => ({ ...prevErrors, useremail: '' }));
        }
    };




    const navigate = useNavigate();



    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        if (formErrors.password) {
            setFormErrors((prevErrors) => ({ ...prevErrors, password: '' }));
        }
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (formErrors.password) {
            setFormErrors((prevErrors) => ({ ...prevErrors, password: '' }));
        }
    };

    const isValidPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/;
        return regex.test(password);
    };









    // const handleTalentSubmit = async (event) => {
    //     event.preventDefault();
    //     const isFormValid = validateTalentForm();
      
    //     if (isFormValid) {
    //       setLoading(true);
    //       try {
    //         // Step 1: Register user with file upload (changed order)
    //         const formData = new FormData();
    //         formData.append('userName', useremail);
    //         formData.append('password', password);
    //         formData.append('firstName', firstName);
    //         formData.append('lastName', lastName);
    //         formData.append('mobileNumber', mobileNumber);
    //         formData.append('userEmail', useremail);
    //         formData.append('talentFile', talentFile);
      
    //         const registerResponse = await axios.post(
    //           `${process.env.REACT_APP_URL}/api/signuptalent`,
    //           formData,
    //           {
    //             headers: {
    //               'Content-Type': 'multipart/form-data',
    //             },
    //           }
    //         );
      
    //         if (registerResponse.status === 201) {
    //           // Step 2: Email verification (changed order)
    //           const verificationResponse = await axios.post(`${process.env.REACT_APP_URL}/api/emailverification`, {
    //             firstName: firstName,
    //             lastName: lastName,
    //             userEmail: useremail,
    //             mobileNumber,
    //           });
      
    //           if (verificationResponse.status === 200) {
    //             const otp = verificationResponse.data.otp;
                
    //             setLoading(false);
    //             // Navigate to email verification page with user type
    //             navigate('/emailverification', {
    //               state: {
    //                 userType: 'talent',
    //                 otp,
    //                 userName: useremail,
    //                 firstName,
    //                 lastName,
    //                 mobileNumber,
    //                 useremail,
    //               }
    //             });
    //           }
    //         }
    //       } catch (error) {
    //         if (error.response && error.response.status === 409) {
    //           enqueueSnackbar('Talent already present! Please Sign In', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //           });
    //         } else if (error.response?.data?.message === "Failed to send verification email. Please check the email address and try again.") {
    //           enqueueSnackbar('Invalid Email!', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //           });
    //         } else {
    //           enqueueSnackbar('Something went wrong', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //           });
    //         }
    //         setError('');
    //       } finally {
    //         setLoading(false);
    //       }
    //     }
    //   };


      
    //   const handleOrganizationSubmit = async (event) => {
    //     event.preventDefault();
    //     const isFormValid = validateOrganizationForm();
        
    //     if (isFormValid) {
    //       setLoading(true);
    //       try {
    //         // Step 1: Email verification
    //         const verificationResponse = await axios.post(`${process.env.REACT_APP_URL}/api/organiationemailverification`, {
    //           companyName: organizationName,
    //           firstName: firstName,
    //           lastName: lastName,
    //           workPhone: mobileNumber,
    //           workEmail: useremail,
    //         });
            
    //         if (verificationResponse.status === 200) {
    //           const otp = verificationResponse.data.otp;
              
    //           // Step 2: Create organization with file upload
    //           const formData = new FormData();
    //           formData.append('companyName', organizationName);
    //           formData.append('organizationType', 'default'); // Add a default or get from form
    //           formData.append('firstName', firstName);
    //           formData.append('lastName', lastName);
    //           formData.append('workEmail', useremail);
    //           formData.append('workPhone', mobileNumber);
    //           formData.append('zipCode', ''); // Add if available
    //           formData.append('numEmployees', ''); // Add if available
    //           formData.append('jobPosition', ''); // Add if available
    //           formData.append('referance', ''); // Add if available
    //           formData.append('otherReferal', ''); // Add if available
    //           formData.append('password', password);
    //           formData.append('organizationFile', organizationFile);
      
    //           const createOrgResponse = await axios.post(
    //             `${process.env.REACT_APP_URL}/api/createorganization`,
    //             formData,
    //             {
    //               headers: {
    //                 'Content-Type': 'multipart/form-data',
    //               },
    //             }
    //           );
              
    //           if (createOrgResponse.status === 201) {
    //             setLoading(false);
    //             // Navigate to email verification page with user type
    //             navigate('/emailverification', {
    //               state: {
    //                 userType: 'organization',
    //                 otp,
    //                 companyName: organizationName,
    //                 firstName: firstName,
    //                 lastName: lastName,
    //                 workPhone: mobileNumber,
    //                 useremail: useremail,
    //               }
    //             });
    //           }
    //         }
    //       } catch (error) {
    //         if (error.response && error.response.status === 409) {
    //           enqueueSnackbar('Organization already present! Please Sign In', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //           });
    //         } else if (error.response?.data?.message === "Failed to send verification email. Please check the email address and try again.") {
    //           enqueueSnackbar('Invalid Email!', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //           });
    //         } else {
    //           enqueueSnackbar('Something went wrong!', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //           });
    //         }
    //         setError('');
    //       } finally {
    //         setLoading(false);
    //       }
    //     }
    //   };

    const handleTalentSubmit = async (event) => {
        event.preventDefault();
        const isFormValid = validateTalentForm();
      
        if (isFormValid) {
          setLoading(true);
          try {
            // Step 1: Register user with file upload
            const formData = new FormData();
            formData.append('userName', useremail);
            formData.append('password', password);
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('mobileNumber', mobileNumber);
            formData.append('userEmail', useremail);
            formData.append('talentFile', talentFile);
      
            const registerResponse = await axios.post(
              `${process.env.REACT_APP_URL}/api/signuptalent`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
      
            // The server will return 201 in two cases:
            // 1. New talent created successfully
            // 2. Talent exists but email is not verified
            if (registerResponse.status === 201) {
              // Step 2: Email verification
              const verificationResponse = await axios.post(`${process.env.REACT_APP_URL}/api/emailverification`, {
                firstName: firstName,
                lastName: lastName,
                userEmail: useremail,
                mobileNumber,
              });
      
              if (verificationResponse.status === 200) {
                const otp = verificationResponse.data.otp;
                
                setLoading(false);
                // Navigate to email verification page with user type
                navigate('/emailverification', {
                  state: {
                    userType: 'talent',
                    otp,
                    userName: useremail,
                    firstName,
                    lastName,
                    mobileNumber,
                    useremail,
                  }
                });
              }
            }
          } catch (error) {
            // This will only happen when talent exists AND emailVerification is true
            if (error.response && error.response.status === 409) {
              enqueueSnackbar('Talent already present! Please Sign In', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
              });
            } else if (error.response?.data?.message === "Failed to send verification email. Please check the email address and try again.") {
              enqueueSnackbar('Invalid Email!', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
              });
            } else {
              enqueueSnackbar('Something went wrong', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
              });
            }
            setError('');
          } finally {
            setLoading(false);
          }
        }
      };


    // const handleOrganizationSubmit = async (event) => {
    //     event.preventDefault();
    //     const isFormValid = validateOrganizationForm();
        
    //     if (isFormValid) {
    //       setLoading(true);
    //       try {
    //         // Step 1: Create organization with file upload (changed order)
    //         const formData = new FormData();
    //         formData.append('companyName', organizationName);
    //         formData.append('organizationType', 'default'); // Add a default or get from form
    //         formData.append('firstName', firstName);
    //         formData.append('lastName', lastName);
    //         formData.append('workEmail', useremail);
    //         formData.append('workPhone', mobileNumber);
    //         formData.append('zipCode', ''); // Add if available
    //         formData.append('numEmployees', ''); // Add if available
    //         formData.append('jobPosition', ''); // Add if available
    //         formData.append('referance', ''); // Add if available
    //         formData.append('otherReferal', ''); // Add if available
    //         formData.append('password', password);
    //         formData.append('organizationFile', organizationFile);
      
    //         const createOrgResponse = await axios.post(
    //           `${process.env.REACT_APP_URL}/api/createorganization`,
    //           formData,
    //           {
    //             headers: {
    //               'Content-Type': 'multipart/form-data',
    //             },
    //           }
    //         );
            
    //         if (createOrgResponse.status === 201) {
    //           // Step 2: Email verification (changed order)
    //           const verificationResponse = await axios.post(`${process.env.REACT_APP_URL}/api/organiationemailverification`, {
    //             companyName: organizationName,
    //             firstName: firstName,
    //             lastName: lastName,
    //             workPhone: mobileNumber,
    //             workEmail: useremail,
    //           });
              
    //           if (verificationResponse.status === 200) {
    //             const otp = verificationResponse.data.otp;
                
    //             setLoading(false);
    //             // Navigate to email verification page with user type
    //             navigate('/emailverification', {
    //               state: {
    //                 userType: 'organization',
    //                 otp,
    //                 companyName: organizationName,
    //                 firstName: firstName,
    //                 lastName: lastName,
    //                 workPhone: mobileNumber,
    //                 useremail: useremail,
    //               }
    //             });
    //           }
    //         }
    //       } catch (error) {
    //         if (error.response && error.response.status === 409) {
    //           enqueueSnackbar('Organization already present! Please Sign In', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //           });
    //         } else if (error.response?.data?.message === "Failed to send verification email. Please check the email address and try again.") {
    //           enqueueSnackbar('Invalid Email!', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //           });
    //         } else {
    //           enqueueSnackbar('Something went wrong!', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //           });
    //         }
    //         setError('');
    //       } finally {
    //         setLoading(false);
    //       }
    //     }
    //   };



    const handleOrganizationSubmit = async (event) => {
        event.preventDefault();
        const isFormValid = validateOrganizationForm();
        
        if (isFormValid) {
          setLoading(true);
          try {
            // Step 1: Create organization with file upload
            const formData = new FormData();
            formData.append('companyName', organizationName);
            formData.append('organizationType', 'default'); // Add a default or get from form
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('workEmail', useremail);
            formData.append('workPhone', mobileNumber);
            formData.append('zipCode', ''); // Add if available
            formData.append('numEmployees', ''); // Add if available
            formData.append('jobPosition', ''); // Add if available
            formData.append('referance', ''); // Add if available
            formData.append('otherReferal', ''); // Add if available
            formData.append('password', password);
            formData.append('organizationFile', organizationFile);
      
            const createOrgResponse = await axios.post(
              `${process.env.REACT_APP_URL}/api/createorganization`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            
            // Server returns 201 in two cases:
            // 1. New organization created successfully
            // 2. Organization exists but email is not verified
            if (createOrgResponse.status === 201) {
              // Step 2: Email verification
              const verificationResponse = await axios.post(`${process.env.REACT_APP_URL}/api/organiationemailverification`, {
                companyName: organizationName,
                firstName: firstName,
                lastName: lastName,
                workPhone: mobileNumber,
                workEmail: useremail,
              });
              
              if (verificationResponse.status === 200) {
                const otp = verificationResponse.data.otp;
                
                setLoading(false);
                // Navigate to email verification page with user type
                navigate('/emailverification', {
                  state: {
                    userType: 'organization',
                    otp,
                    companyName: organizationName,
                    firstName: firstName,
                    lastName: lastName,
                    workPhone: mobileNumber,
                    useremail: useremail,
                  }
                });
              }
            }
          } catch (error) {
            if (error.response && error.response.status === 409) {
              // Check if the error response contains "Phone" or "Email"
              if (error.response.data === "Phone") {
                enqueueSnackbar('Phone number already registered! Please Sign In', {
                  variant: 'error',
                  anchorOrigin: { vertical: 'top', horizontal: 'right' }
                });
              } else if (error.response.data === "Email") {
                enqueueSnackbar('Email already registered! Please Sign In', {
                  variant: 'error',
                  anchorOrigin: { vertical: 'top', horizontal: 'right' }
                });
              } else {
                enqueueSnackbar('Organization already present! Please Sign In', {
                  variant: 'error',
                  anchorOrigin: { vertical: 'top', horizontal: 'right' }
                });
              }
            } else if (error.response?.data?.message === "Failed to send verification email. Please check the email address and try again.") {
              enqueueSnackbar('Invalid Email!', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
              });
            } else {
              enqueueSnackbar('Something went wrong!', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
              });
            }
            setError('');
          } finally {
            setLoading(false);
          }
        }
      };

    return (
        <Box display='flex' >
            <Grid container xs={12} md={12} lg={12} justifyContent='center' alignItems='center' sx={{
                background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
                height: '100%',
                display: 'flex',
                minHeight: '100vh',
                padding: isMobile ? 1 : 3,
                // p: 3
            }}>
                <Grid container xs={10} md={5} lg={5} sx={{ bgcolor: 'white', borderRadius: 5 }} justifyContent='center' p={3}>
                    <Grid container direction="column" alignItems="center" sx={{
                        borderRadius: "20px 20px 0 0"
                    }} xs={12}>
                        <Grid item>
                            <img
                                src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                alt="Logo"
                                style={{ width: '100px', marginRight: '5px' }}
                            />
                        </Grid>

                    </Grid>

                    <Grid container xs={10} md={8} lg={8} pt={3} pb={3}>
                        <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant={showForm === 'talent' ? 'contained' : 'outlined'}
                                fullWidth
                                onClick={() => setShowForm('talent')}
                                color={showForm === 'talent' ? 'success' : 'primary'}
                                sx={{
                                    borderRadius: '20px 0px 0px 20px',
                                    backgroundColor: showForm === 'talent' ? '#48216D' : 'transparent',
                                    color: showForm === 'talent' ? '#FFFFFF' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: showForm === 'talent' ? '#48216D' : 'inherit',
                                    }

                                }}
                            >
                                Talent
                            </Button>

                        </Grid>

                        <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant={showForm === 'organization' ? 'contained' : 'outlined'} fullWidth onClick={() => setShowForm('organization')}
                                color={showForm === 'organization' ? 'success' : 'primary'}
                                sx={{
                                    borderRadius: '0px 20px 20px 0px',
                                    backgroundColor: showForm === 'organization' ? '#48216D' : 'transparent',
                                    color: showForm === 'organization' ? '#FFFFFF' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: showForm === 'organization' ? '#48216D' : 'inherit',
                                    }
                                }}>
                                Organization
                            </Button>
                        </Grid>
                    </Grid>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>


                    {showForm === 'talent' && (
                        <Grid container xs={12} md={12} lg={12} justifyContent='center' >
                            <Grid item xs={10} md={8} lg={8} display="flex" flexDirection="column" alignItems="center" pt={2}>
                                {/* Avatar showing uploaded image or default */}
                                <Avatar
                                    alt="User Avatar"
                                    src={selectedTalentImage} // If image is selected, show it
                                    sx={{ width: 100, height: 100 }}
                                />
                                {formErrors.selectedTalentImage && <Typography color="error" fontSize='10px'>{formErrors.selectedTalentImage}</Typography>}

                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{ marginTop: 2, bgcolor: '#ecb61e', borderRadius: 5 }}
                                >
                                    {selectedTalentImage ? 'Change Image' : 'Upload Image'} {/* Change button label */}
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={handleTalentImageChange} // Handle file change
                                    />
                                </Button>
                            </Grid>


                            <Grid container xs={12} md={8} lg={8} gap={1} justifyContent={isMobile ? "center" : 'space-between'} >
                                <Grid item xs={10} md={5.5} lg={5.5}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="firstname"
                                        label="First Name"
                                        name="firstname"
                                        autoComplete="firstname"
                                        autoFocus
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                            // Clear error for First Name when the user starts typing
                                            if (formErrors.firstName) {
                                                setFormErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only alphabetic characters
                                            if (!/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formErrors.firstName && <Typography color="error" fontSize='10px'>{formErrors.firstName}</Typography>}
                                </Grid>

                                <Grid item xs={10} md={5.5} lg={5.5}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lastName"
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            if (formErrors.lastName) {
                                                setFormErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only alphabetic characters
                                            if (!/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formErrors.lastName && <Typography color="error" fontSize='10px'>{formErrors.lastName}</Typography>}
                                </Grid>
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    onChange={handleEmailChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {formErrors.useremail && <Typography color="error" fontSize='10px'>{formErrors.useremail}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="mobileNumber"
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    autoComplete="tel"
                                    // value={mobileNumber}
                                    // onChange={(e) => setMobileNumber(e.target.value)}
                                    onChange={(e) => {
                                        setMobileNumber(e.target.value);
                                        if (formErrors.mobileNumber) {
                                            setFormErrors((prevErrors) => ({ ...prevErrors, mobileNumber: '' }));
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                        inputMode: 'numeric', // Ensures numeric keyboard on mobile devices
                                    }}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}

                                />
                                {formErrors.mobileNumber && <Typography color="error" fontSize='10px'>{formErrors.mobileNumber}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <FormControl required variant='standard' fullWidth margin='normal'>
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    <Input
                                        id="standard-password-input"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}

                                        onChange={handlePasswordChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                                {formErrors.password && <Typography color="error" fontSize='10px'>{formErrors.password}</Typography>}
                            </Grid>


                            <Grid item xs={10} md={8} lg={8} >
                                <FormControl required variant="standard" fullWidth margin='normal'>
                                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                                    <Input
                                        id="outlined-adornment-confirm-password"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        label="Confirm Password"
                                    />
                                </FormControl>
                                {formErrors.confirmPassword && <Typography color="error" fontSize='10px'>{formErrors.confirmPassword}</Typography>}
                                <Typography variant="caption" color="textSecondary" >
                                    Note : Password should contain at least one special character, one digit, one uppercase letter, and one lowercase letter
                                </Typography>

                            </Grid>

                            <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
                                <Button variant='contained' sx={{ bgcolor: '#ecb61e', borderRadius: 5 }} onClick={handleTalentSubmit} fullWidth>
                                    Submit
                                </Button>
                            </Grid>

                            <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
                                <Typography>
                                    Already have account?
                                </Typography>
                            </Grid>
                            <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
                                <Link sx={{ cursor: 'pointer' }} to='/talentlogin'>Talent Login</Link>
                            </Grid>

                            <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
                                <Link sx={{ cursor: 'pointer' }} to='/organizationlogin'>Organization Login</Link>
                            </Grid>
                        </Grid>
                    )}

                    {showForm === 'organization' && (
                        <Grid container xs={12} md={12} lg={12} justifyContent='center' >
                            <Grid item xs={10} md={8} lg={8} display="flex" flexDirection="column" alignItems="center" pt={2}>
                                {/* Avatar showing uploaded image or default */}
                                <Avatar
                                    alt="User Avatar"
                                    src={selectedOrganizationImage} // If image is selected, show it
                                    sx={{ width: 100, height: 100 }}
                                />
                                {formErrors.selectedOrganizationImage && <Typography color="error" fontSize='10px'>{formErrors.selectedOrganizationImage}</Typography>}

                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{ marginTop: 2, bgcolor: '#ecb61e', borderRadius: 5 }}
                                >
                                    {selectedOrganizationImage ? 'Change Image' : 'Upload Image'} {/* Change button label */}
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={handleOrganizationImageChange} // Handle file change
                                    />
                                </Button>
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="organizationName"
                                    label="Organization Name"
                                    name="organizationName"
                                    autoComplete="organizationName"
                                    autoFocus
                                    // onChange={(e) => setOrganizationName(e.target.value)}
                                    onChange={(e) => {
                                        setOrganizationName(e.target.value);
                                        // Clear error for First Name when the user starts typing
                                        if (formErrors.organizationName) {
                                            setFormErrors((prevErrors) => ({ ...prevErrors, organizationName: '' }));
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <HomeRepairServiceIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {formErrors.organizationName && <Typography color="error" fontSize='10px'>{formErrors.organizationName}</Typography>}
                            </Grid>
                            <Grid container xs={12} md={8} lg={8} gap={1} justifyContent={isMobile ? "center" : 'space-between'} >

                                <Grid item xs={10} md={5.5} lg={5.5}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="firstname"
                                        label="First Name"
                                        name="firstname"
                                        autoComplete="firstname"
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                            // Clear error for First Name when the user starts typing
                                            if (formErrors.firstName) {
                                                setFormErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only alphabetic characters
                                            if (!/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formErrors.firstName && <Typography color="error" fontSize='10px'>{formErrors.firstName}</Typography>}
                                </Grid>

                                <Grid item xs={10} md={5.5} lg={5.5}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lastName"
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            if (formErrors.lastName) {
                                                setFormErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only alphabetic characters
                                            if (!/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formErrors.lastName && <Typography color="error" fontSize='10px'>{formErrors.lastName}</Typography>}
                                </Grid>
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="email"
                                    label="Work Email"
                                    name="email"
                                    autoComplete="email"
                                    onChange={handleEmailChange}
                                    // error={emailError} // Shows the error state if the email is invalid
                                    // helperText={emailError ? "Please enter a valid email address." : ""}
                                    // onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                {formErrors.useremail && <Typography color="error" fontSize='10px'>{formErrors.useremail}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="mobileNumber"
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    autoComplete="tel"
                                    // value={mobileNumber}
                                    // onChange={(e) => setMobileNumber(e.target.value)}
                                    onChange={(e) => {
                                        setMobileNumber(e.target.value);
                                        // Clear error for First Name when the user starts typing
                                        if (formErrors.mobileNumber) {
                                            setFormErrors((prevErrors) => ({ ...prevErrors, mobileNumber: '' }));
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                        inputMode: 'numeric', // Ensures numeric keyboard on mobile devices
                                    }}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}

                                />

                                {formErrors.mobileNumber && <Typography color="error" fontSize='10px'>{formErrors.mobileNumber}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8}>
                                <FormControl required variant='standard' fullWidth margin='normal'>
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    <Input
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}

                                        onChange={handlePasswordChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>

                                {formErrors.password && <Typography color="error" fontSize='10px'>{formErrors.password}</Typography>}
                            </Grid>


                            <Grid item xs={10} md={8} lg={8} >
                                <FormControl required variant='standard' fullWidth margin='normal'>
                                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                                    <Input
                                        id="outlined-adornment-confirm-password"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        label="Confirm Password"
                                    />
                                </FormControl>
                                <Typography variant="caption" color="textSecondary" >
                                    Note : Password should contain at least one special character, one digit, one uppercase letter, and one lowercase letter
                                </Typography>
                                {formErrors.confirmPassword && <Typography color="error" fontSize='10px'>{formErrors.confirmPassword}</Typography>}
                            </Grid>

                            <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
                                <Button onClick={handleOrganizationSubmit} variant='contained' sx={{ bgcolor: '#ecb61e', borderRadius: 5 }} fullWidth>
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
                                <Typography>
                                    Already have account?
                                </Typography>
                            </Grid>
                            <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
                                <Link sx={{ cursor: 'pointer' }} to='/talentlogin'>Talent Login</Link>
                            </Grid>

                            <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
                                <Link sx={{ cursor: 'pointer' }} to='/organizationlogin'>Organization Login</Link>
                            </Grid>
                        </Grid>
                    )}
                </Grid>



            </Grid>
        </Box>
    )
}

export default SignUp








// import { Box, Grid, Typography, InputAdornment, TextField, FormControl, FormControlLabel, InputLabel, IconButton, OutlinedInput, Button, Input, Avatar } from '@mui/material'
// import React from 'react'
// import { useState } from 'react';
// import EmailIcon from '@mui/icons-material/Email';
// import PhoneIcon from '@mui/icons-material/Phone';
// import PersonIcon from '@mui/icons-material/Person';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
// import { Link } from 'react-router-dom';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { Backdrop, CircularProgress } from '@mui/material';
// import { motion, AnimatePresence } from 'framer-motion';



// const defaultTheme = createTheme();
// const SignUp = () => {

//     const [useremail, setEmail] = useState('');
//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [showPassword, setShowPassword] = useState(false);
//     const [showForm, setShowForm] = useState('talent')
//     const [organizationName, setOrganizationName] = useState('');
//     const [representativeName, setRepresentativeName] = useState('');
//     const { enqueueSnackbar } = useSnackbar();
//     const [emailError, setEmailError] = useState(false);
//     const [error, setError] = useState('');
//     const [selectedTalentImage, setSelectedTalentImage] = useState(null);
//     const [selectedOrganizationImage, setSelectedOrganizationImage] = useState(null);
//     const [talentFile, setTalentFile] = useState(null);
//     const [organizationFile, setOrganizationFile] = useState(null);
//     const [loading, setLoading] = useState(false);



//     const pageVariants = {
//         initial: {
//             rotateY: -180,
//             opacity: 0
//         },
//         animate: {
//             rotateY: 0,
//             opacity: 1,
//             transition: {
//                 duration: 0.6,
//                 ease: "easeInOut"
//             }
//         },
//         exit: {
//             rotateY: 180,
//             opacity: 0,
//             transition: {
//                 duration: 0.6,
//                 ease: "easeInOut"
//             }
//         }
//     };

//     // Styled components for form sections
//     const FormSection = ({ children, visible }) => (
//         <AnimatePresence mode="wait">
//             {visible && (
//                 <motion.div
//                     key={showForm}
//                     initial="initial"
//                     animate="animate"
//                     exit="exit"
//                     variants={pageVariants}
//                     style={{ 
//                         width: '100%',
//                         perspective: 1000
//                     }}
//                 >
//                     {children}
//                 </motion.div>
//             )}
//         </AnimatePresence>
//     );
    



//     const handleTalentImageChange = (event) => {
//         const file = event.target.files[0];
//         let errors = {};

//         if (file) {
//             if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
//                 // errors.file = 'Only JPEG, PNG, and GIF image formats are allowed';
//                 setFormErrors((prevErrors) => ({ ...prevErrors, talentFile: 'Only JPEG, PNG, and GIF image formats are allowed' }));
//                 // setFormErrors(errors);
//             } else {
//                 setFormErrors((prevErrors) => ({ ...prevErrors, file: '' }));
//                 setSelectedTalentImage(URL.createObjectURL(file));
//                 setTalentFile(file)
//             }
//         } else {
//             // errors.file = 'Profile image is required';
//             setFormErrors((prevErrors) => ({ ...prevErrors, talentFile: 'Profile image is required' }));
//             // setFormErrors(errors);
//         }
//     };



//     const handleOrganizationImageChange = (event) => {
//         const file = event.target.files[0];
//         let errors = {};

//         if (file) {
//             // Check for valid image formats
//             const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
//             if (validImageTypes.includes(file.type)) {
//                 setSelectedOrganizationImage(URL.createObjectURL(file));
//                 setOrganizationFile(file)
//             } else {
//                 // errors.file = 'Only JPEG, PNG, and GIF image formats are allowed';
//                 setFormErrors((prevErrors) => ({ ...prevErrors, organizationFile: 'Only JPEG, PNG, and GIF image formats are allowed' }));
//             }
//         } else {
//             // errors.file = 'Organization image is required';
//             setFormErrors((prevErrors) => ({ ...prevErrors, talentFile: 'Profile image is required' }));
//         }

//         // Set errors for image
//         setFormErrors(prevErrors => ({ ...prevErrors, ...errors }));
//     };

//     // const [formErrors, setFormErrors] = useState({});


//     const [formErrors, setFormErrors] = useState({});
//     const isMobile = useMediaQuery(defaultTheme.breakpoints.down('md'));

//     const validateEmail = (email) => {
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         return emailRegex.test(email);
//     };

//     const validateTalentForm = () => {
//         let errors = {};
//         if(!talentFile) errors.selectedTalentImage = 'Profile image is required';
//         if (!firstName.trim()) errors.firstName = 'First Name is required';
//         if (!lastName.trim()) errors.lastName = 'Last Name is required';
//         if (!useremail.trim()) errors.useremail = 'Email is required';
//         else if (!validateEmail(useremail)) errors.useremail = 'Please enter a valid email address';
//         if (!mobileNumber.trim()) errors.mobileNumber = 'Mobile Number is required';
//         if (!password.trim()) errors.password = 'Password is required';
//         else if (password.length < 8) errors.password = 'Password must be at least 8 characters long';
//         else if (!isValidPassword(password)) {
//             errors.password = 'Password must contain at least one uppercase, one lowercase, one digit, and one special character.';
//         }
//         if (!confirmPassword.trim()) errors.confirmPassword = 'Confirm Password is required';
//         else if (password !== confirmPassword) errors.confirmPassword = 'Passwords do not match';

//         if (!selectedTalentImage) {
//             errors.file = 'Profile image is required';
//         }

//         setFormErrors(errors);
//         return Object.keys(errors).length === 0; // return true if no errors
//     }

//     const validateOrganizationForm = () => {
//         let errors = {};
//         if (!organizationName.trim()) errors.organizationName = 'organizationName is required';
//         if(!organizationFile) errors.organizationFile = 'Profile image is required';
//         if (!firstName.trim()) errors.firstName = 'First Name is required';
//         if (!lastName.trim()) errors.lastName = 'Last Name is required';
//         if (!useremail.trim()) errors.useremail = 'Email is required';
//         else if (!validateEmail(useremail)) errors.useremail = 'Please enter a valid email address';
//         if (!mobileNumber.trim()) errors.mobileNumber = 'Mobile Number is required';
//         if (!password.trim()) errors.password = 'Password is required';
//         else if (password.length < 8) errors.password = 'Password must be at least 8 characters long';
//         if (!confirmPassword.trim()) errors.confirmPassword = 'Confirm Password is required';
//         else if (password !== confirmPassword) errors.confirmPassword = 'Passwords do not match';

//         if (!selectedOrganizationImage) {
//             errors.selectedOrganizationImage = 'Profile image is required';
//         }

//         setFormErrors(errors);
//         return Object.keys(errors).length === 0; // return true if no errors
//     }



//     const handleEmailChange = (e) => {
//         const value = e.target.value;
//         setEmail(value);

//         // Remove email error as the user starts typing
//         if (value === '') {
//             // setEmailError(false);
//             setFormErrors((prevErrors) => ({ ...prevErrors, useremail: '' }));
//         } else if (!validateEmail(value)) {
//             // setEmailError(true);
//             setFormErrors((prevErrors) => ({ ...prevErrors, useremail: 'Please enter a valid email address.' }));
//         } else {
//             // setEmailError(false);
//             setFormErrors((prevErrors) => ({ ...prevErrors, useremail: '' }));
//         }
//     };




//     const navigate = useNavigate();



//     const handleClickShowPassword = () => setShowPassword((show) => !show);

//     const handlePasswordChange = (event) => {
//         setPassword(event.target.value);
//         if (formErrors.password) {
//             setFormErrors((prevErrors) => ({ ...prevErrors, password: '' }));
//         }
//     };

//     const handleConfirmPasswordChange = (event) => {
//         setConfirmPassword(event.target.value);
//         if (formErrors.password) {
//             setFormErrors((prevErrors) => ({ ...prevErrors, password: '' }));
//         }
//     };

//     const isValidPassword = (password) => {
//         const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/;
//         return regex.test(password);
//     };




//     const handleTalentSubmit = async (event) => {
//         event.preventDefault();
//         const isFormValid = validateTalentForm();

//         if (isFormValid) {
//             // Validate form inputs here if necessary
//             setLoading(true);
//             try {
//                 const response = await axios.post(`${process.env.REACT_APP_URL}/api/emailverification`, {
//                     firstName: firstName,
//                     lastName: lastName,
//                     userEmail: useremail,
//                     mobileNumber,
//                 });

//                 if (response.status === 200) {
//                     setLoading(false);
//                     console.log(response.data.otp);
//                     const otp = response.data.otp

//                     console.log('Successful');
//                     navigate('/emailverification', {
//                         state: {
//                             userName: useremail,
//                             password,
//                             firstName,
//                             lastName,
//                             mobileNumber,
//                             useremail,
//                             otp,
//                             talentFile
//                         }
//                     });

//                 }
//             } catch (error) {
//                 if (error.response && error.response.status === 409) {


//                     enqueueSnackbar('Talent already present! Please Sign In', {
//                         variant: 'error',
//                         anchorOrigin: { vertical: 'top', horizontal: 'right' }
//                     })

//                 } else if (error.response.data.message === "Failed to send verification email. Please check the email address and try again.") {
//                     enqueueSnackbar('Invalid Email!', {
//                         variant: 'error',
//                         anchorOrigin: { vertical: 'top', horizontal: 'right' }
//                     })
//                 } else {

//                     enqueueSnackbar('Something went wrong', {
//                         variant: 'error',
//                         anchorOrigin: { vertical: 'top', horizontal: 'right' }
//                     })
//                 }




//                 setError('');



//             } finally {
//                 setLoading(false); // Stop loading after response
//             };
//         }


//     }


//     const handleOrganizationSubmit = async (event) => {
//         event.preventDefault();
//         const isFormValid = validateOrganizationForm()
//         if (isFormValid) {
//             setLoading(true);
//             try {
//                 const response = await axios.post(`${process.env.REACT_APP_URL}/api/organiationemailverification`, {
//                     companyName: organizationName,
//                     firstName: firstName,
//                     lastName: lastName,
//                     workPhone: mobileNumber,
//                     workEmail: useremail,

//                 });


//                 if (response.status === 200) {
//                     setLoading(false);
//                     console.log(response.data.otp);
//                     const otp = response.data.otp

//                     console.log('Successful');
//                     navigate('/emailverification', {
//                         state: {
//                             companyName: organizationName,
//                             firstName: firstName,
//                             lastName: lastName,
//                             workPhone: mobileNumber,
//                             useremail: useremail,
//                             password,
//                             otp,
//                             organizationFile
//                         }
//                     });

//                 }
//             } catch (error) {
//                 if (error.response && error.response.status === 409) {

//                     enqueueSnackbar('Orgaization already present ! Please Sign In', {
//                         variant: 'error',
//                         anchorOrigin: { vertical: 'top', horizontal: 'right' }
//                     })


//                 } else if (error.response.data.message === "Failed to send verification email. Please check the email address and try again.") {
//                     enqueueSnackbar('Invalid Email!', {
//                         variant: 'error',
//                         anchorOrigin: { vertical: 'top', horizontal: 'right' }
//                     })
//                 } else {

//                     enqueueSnackbar('Something went wrong!', {
//                         variant: 'error',
//                         anchorOrigin: { vertical: 'top', horizontal: 'right' }
//                     })
//                 }




//                 setError('');



//             }finally {
//                 setLoading(false); // Stop loading after response
//             };
//         }
//         // else {
//         //     enqueueSnackbar('Please fill all fields', {
//         //         variant: 'error',
//         //         anchorOrigin: { vertical: 'top', horizontal: 'right' }
//         //     })
//         // }

//     }





//     return (
//         <Box display='flex'>
//             <Grid 
//                 container 
//                 xs={12} 
//                 md={12} 
//                 lg={12} 
//                 justifyContent='center' 
//                 alignItems='center' 
//                 sx={{
//                     background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
//                     height: '100%',
//                     display: 'flex',
//                     minHeight: '100vh',
//                     padding: isMobile ? 1 : 3,
//                 }}
//             >
//                 <Grid 
//                     container 
//                     xs={10} 
//                     md={5} 
//                     lg={5} 
//                     sx={{ 
//                         bgcolor: 'white', 
//                         borderRadius: 5,
//                         boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
//                         transition: 'all 0.3s ease'
//                     }} 
//                     justifyContent='center' 
//                     p={3}
//                 >
//                     {/* Logo Section */}
//                     <Grid container direction="column" alignItems="center" sx={{
//                         borderRadius: "20px 20px 0 0"
//                     }} xs={12}>
//                         <Grid item>
//                             <motion.img
//                                 initial={{ scale: 0.8 }}
//                                 animate={{ scale: 1 }}
//                                 transition={{ duration: 0.5 }}
//                                 src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
//                                 alt="Logo"
//                                 style={{ width: '100px', marginRight: '5px' }}
//                             />
//                         </Grid>
//                     </Grid>

//                     {/* Toggle Buttons */}
//                     <Grid container xs={10} md={8} lg={8} pt={3} pb={3}>
//                         <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
//                             <Button
//                                 variant={showForm === 'talent' ? 'contained' : 'outlined'}
//                                 fullWidth
//                                 onClick={() => setShowForm('talent')}
//                                 sx={{
//                                     borderRadius: '20px 0px 0px 20px',
//                                     backgroundColor: showForm === 'talent' ? '#48216D' : 'transparent',
//                                     color: showForm === 'talent' ? '#FFFFFF' : 'inherit',
//                                     transition: 'all 0.3s ease',
//                                     '&:hover': {
//                                         backgroundColor: showForm === 'talent' ? '#48216D' : 'rgba(72, 33, 109, 0.1)',
//                                         transform: 'translateY(-2px)'
//                                     }
//                                 }}
//                             >
//                                 Talent
//                             </Button>
//                         </Grid>

//                         <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
//                             <Button 
//                                 variant={showForm === 'organization' ? 'contained' : 'outlined'}
//                                 fullWidth 
//                                 onClick={() => setShowForm('organization')}
//                                 sx={{
//                                     borderRadius: '0px 20px 20px 0px',
//                                     backgroundColor: showForm === 'organization' ? '#48216D' : 'transparent',
//                                     color: showForm === 'organization' ? '#FFFFFF' : 'inherit',
//                                     transition: 'all 0.3s ease',
//                                     '&:hover': {
//                                         backgroundColor: showForm === 'organization' ? '#48216D' : 'rgba(72, 33, 109, 0.1)',
//                                         transform: 'translateY(-2px)'
//                                     }
//                                 }}
//                             >
//                                 Organization
//                             </Button>
//                         </Grid>
//                     </Grid>

//                     <Backdrop
//                         sx={{ 
//                             color: '#fff', 
//                             zIndex: (theme) => theme.zIndex.drawer + 1,
//                             backdropFilter: 'blur(4px)'
//                         }}
//                         open={loading}
//                     >
//                         <CircularProgress color="inherit" />
//                     </Backdrop>


//                     <FormSection visible={showForm === 'talent'}>
//                         <Grid container xs={12} md={12} lg={12} justifyContent='center' >
//                             <Grid item xs={10} md={8} lg={8} display="flex" flexDirection="column" alignItems="center" pt={2}>
//                                 {/* Avatar showing uploaded image or default */}
//                                 <Avatar
//                                     alt="User Avatar"
//                                     src={selectedTalentImage} // If image is selected, show it
//                                     sx={{ width: 100, height: 100 }}
//                                 />
//                                 {formErrors.selectedTalentImage && <Typography color="error" fontSize='10px'>{formErrors.selectedTalentImage}</Typography>}

//                                 <Button
//                                     variant="contained"
//                                     component="label"
//                                     sx={{ marginTop: 2, bgcolor: '#ecb61e', borderRadius: 5 }}
//                                 >
//                                     {selectedTalentImage ? 'Change Image' : 'Upload Image'} {/* Change button label */}
//                                     <input
//                                         type="file"
//                                         hidden
//                                         accept="image/*"
//                                         onChange={handleTalentImageChange} // Handle file change
//                                     />
//                                 </Button>
//                             </Grid>


//                             <Grid container xs={12} md={8} lg={8} gap={1} justifyContent={isMobile ? "center" : 'space-between'} >
//                                 <Grid item xs={10} md={5.5} lg={5.5}>
//                                     <TextField
//                                         margin="normal"
//                                         variant='standard'
//                                         required
//                                         fullWidth
//                                         id="firstname"
//                                         label="First Name"
//                                         name="firstname"
//                                         autoComplete="firstname"
//                                         autoFocus
//                                         onChange={(e) => {
//                                             setFirstName(e.target.value);
//                                             // Clear error for First Name when the user starts typing
//                                             if (formErrors.firstName) {
//                                                 setFormErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
//                                             }
//                                         }}
//                                         onKeyPress={(e) => {
//                                             // Allow only alphabetic characters
//                                             if (!/[a-zA-Z]/.test(e.key)) {
//                                                 e.preventDefault();
//                                             }
//                                         }}
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position="end">
//                                                     <PersonIcon color='primary' />
//                                                 </InputAdornment>
//                                             ),
//                                         }}
//                                     />
//                                     {formErrors.firstName && <Typography color="error" fontSize='10px'>{formErrors.firstName}</Typography>}
//                                 </Grid>

//                                 <Grid item xs={10} md={5.5} lg={5.5}>
//                                     <TextField
//                                         margin="normal"
//                                         variant='standard'
//                                         required
//                                         fullWidth
//                                         id="lastName"
//                                         label="Last Name"
//                                         name="lastName"
//                                         autoComplete="lastName"
//                                         onChange={(e) => {
//                                             setLastName(e.target.value);
//                                             if (formErrors.lastName) {
//                                                 setFormErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
//                                             }
//                                         }}
//                                         onKeyPress={(e) => {
//                                             // Allow only alphabetic characters
//                                             if (!/[a-zA-Z]/.test(e.key)) {
//                                                 e.preventDefault();
//                                             }
//                                         }}
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position="end">
//                                                     <PersonIcon color='primary' />
//                                                 </InputAdornment>
//                                             ),
//                                         }}
//                                     />
//                                     {formErrors.lastName && <Typography color="error" fontSize='10px'>{formErrors.lastName}</Typography>}
//                                 </Grid>
//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8}>
//                                 <TextField
//                                     margin="normal"
//                                     variant='standard'
//                                     required
//                                     fullWidth
//                                     id="email"
//                                     label="Email"
//                                     name="email"
//                                     autoComplete="email"
//                                     onChange={handleEmailChange}
//                                     InputProps={{
//                                         endAdornment: (
//                                             <InputAdornment position="end">
//                                                 <EmailIcon color='primary' />
//                                             </InputAdornment>
//                                         ),
//                                     }}
//                                 />
//                                 {formErrors.useremail && <Typography color="error" fontSize='10px'>{formErrors.useremail}</Typography>}
//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8}>
//                                 <TextField
//                                     margin="normal"
//                                     variant='standard'
//                                     required
//                                     fullWidth
//                                     id="mobileNumber"
//                                     label="Mobile Number"
//                                     name="mobileNumber"
//                                     autoComplete="tel"
//                                     // value={mobileNumber}
//                                     // onChange={(e) => setMobileNumber(e.target.value)}
//                                     onChange={(e) => {
//                                         setMobileNumber(e.target.value);
//                                         if (formErrors.mobileNumber) {
//                                             setFormErrors((prevErrors) => ({ ...prevErrors, mobileNumber: '' }));
//                                         }
//                                     }}
//                                     InputProps={{
//                                         endAdornment: (
//                                             <InputAdornment position="end">
//                                                 <PhoneIcon color='primary' />
//                                             </InputAdornment>
//                                         ),
//                                         inputMode: 'numeric', // Ensures numeric keyboard on mobile devices
//                                     }}
//                                     onKeyPress={(e) => {
//                                         if (!/[0-9]/.test(e.key)) {
//                                             e.preventDefault();
//                                         }
//                                     }}

//                                 />
//                                 {formErrors.mobileNumber && <Typography color="error" fontSize='10px'>{formErrors.mobileNumber}</Typography>}
//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8}>
//                                 <FormControl required variant='standard' fullWidth margin='normal'>
//                                     <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
//                                     <Input
//                                         id="standard-password-input"
//                                         type={showPassword ? 'text' : 'password'}
//                                         value={password}

//                                         onChange={handlePasswordChange}
//                                         endAdornment={
//                                             <InputAdornment position="end">
//                                                 <IconButton
//                                                     aria-label="toggle password visibility"
//                                                     onClick={handleClickShowPassword}
//                                                     edge="end"
//                                                 >
//                                                     {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
//                                                 </IconButton>
//                                             </InputAdornment>
//                                         }
//                                         label="Password"
//                                     />
//                                 </FormControl>
//                                 {formErrors.password && <Typography color="error" fontSize='10px'>{formErrors.password}</Typography>}
//                             </Grid>


//                             <Grid item xs={10} md={8} lg={8} >
//                                 <FormControl required variant="standard" fullWidth margin='normal'>
//                                     <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
//                                     <Input
//                                         id="outlined-adornment-confirm-password"
//                                         type="password"
//                                         value={confirmPassword}
//                                         onChange={handleConfirmPasswordChange}
//                                         label="Confirm Password"
//                                     />
//                                 </FormControl>
//                                 {formErrors.confirmPassword && <Typography color="error" fontSize='10px'>{formErrors.confirmPassword}</Typography>}
//                                 <Typography variant="caption" color="textSecondary" >
//                                     Note : Password should contain at least one special character, one digit, one uppercase letter, and one lowercase letter
//                                 </Typography>

//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
//                                 <Button variant='contained' sx={{ bgcolor: '#ecb61e', borderRadius: 5 }} onClick={handleTalentSubmit} fullWidth>
//                                     Submit
//                                 </Button>
//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
//                                 <Typography>
//                                     Already have account?
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
//                                 <Link sx={{ cursor: 'pointer' }} to='/talentlogin'>Talent Login</Link>
//                             </Grid>

//                             <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
//                                 <Link sx={{ cursor: 'pointer' }} to='/organizationlogin'>Organization Login</Link>
//                             </Grid>
//                         </Grid>
//                     </FormSection>

//                     <FormSection visible={showForm === 'organization'}>
//                         <Grid container xs={12} md={12} lg={12} justifyContent='center' >
//                             <Grid item xs={10} md={8} lg={8} display="flex" flexDirection="column" alignItems="center" pt={2}>
//                                 {/* Avatar showing uploaded image or default */}
//                                 <Avatar
//                                     alt="User Avatar"
//                                     src={selectedOrganizationImage} // If image is selected, show it
//                                     sx={{ width: 100, height: 100 }}
//                                 />
//                                 {formErrors.selectedOrganizationImage && <Typography color="error" fontSize='10px'>{formErrors.selectedOrganizationImage}</Typography>}

//                                 <Button
//                                     variant="contained"
//                                     component="label"
//                                     sx={{ marginTop: 2, bgcolor: '#ecb61e', borderRadius: 5 }}
//                                 >
//                                     {selectedOrganizationImage ? 'Change Image' : 'Upload Image'} {/* Change button label */}
//                                     <input
//                                         type="file"
//                                         hidden
//                                         accept="image/*"
//                                         onChange={handleOrganizationImageChange} // Handle file change
//                                     />
//                                 </Button>
//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8}>
//                                 <TextField
//                                     margin="normal"
//                                     variant='standard'
//                                     required
//                                     fullWidth
//                                     id="organizationName"
//                                     label="Organization Name"
//                                     name="organizationName"
//                                     autoComplete="organizationName"
//                                     autoFocus
//                                     // onChange={(e) => setOrganizationName(e.target.value)}
//                                     onChange={(e) => {
//                                         setOrganizationName(e.target.value);
//                                         // Clear error for First Name when the user starts typing
//                                         if (formErrors.organizationName) {
//                                             setFormErrors((prevErrors) => ({ ...prevErrors, organizationName: '' }));
//                                         }
//                                     }}
//                                     InputProps={{
//                                         endAdornment: (
//                                             <InputAdornment position="end">
//                                                 <HomeRepairServiceIcon color='primary' />
//                                             </InputAdornment>
//                                         ),
//                                     }}
//                                 />
//                                 {formErrors.organizationName && <Typography color="error" fontSize='10px'>{formErrors.organizationName}</Typography>}
//                             </Grid>
//                             <Grid container xs={12} md={8} lg={8} gap={1} justifyContent={isMobile ? "center" : 'space-between'} >

//                                 <Grid item xs={10} md={5.5} lg={5.5}>
//                                     <TextField
//                                         margin="normal"
//                                         variant='standard'
//                                         required
//                                         fullWidth
//                                         id="firstname"
//                                         label="First Name"
//                                         name="firstname"
//                                         autoComplete="firstname"
//                                         onChange={(e) => {
//                                             setFirstName(e.target.value);
//                                             // Clear error for First Name when the user starts typing
//                                             if (formErrors.firstName) {
//                                                 setFormErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
//                                             }
//                                         }}
//                                         onKeyPress={(e) => {
//                                             // Allow only alphabetic characters
//                                             if (!/[a-zA-Z]/.test(e.key)) {
//                                                 e.preventDefault();
//                                             }
//                                         }}
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position="end">
//                                                     <PersonIcon color='primary' />
//                                                 </InputAdornment>
//                                             ),
//                                         }}
//                                     />
//                                     {formErrors.firstName && <Typography color="error" fontSize='10px'>{formErrors.firstName}</Typography>}
//                                 </Grid>

//                                 <Grid item xs={10} md={5.5} lg={5.5}>
//                                     <TextField
//                                         margin="normal"
//                                         variant='standard'
//                                         required
//                                         fullWidth
//                                         id="lastName"
//                                         label="Last Name"
//                                         name="lastName"
//                                         autoComplete="lastName"
//                                         onChange={(e) => {
//                                             setLastName(e.target.value);
//                                             if (formErrors.lastName) {
//                                                 setFormErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
//                                             }
//                                         }}
//                                         onKeyPress={(e) => {
//                                             // Allow only alphabetic characters
//                                             if (!/[a-zA-Z]/.test(e.key)) {
//                                                 e.preventDefault();
//                                             }
//                                         }}
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position="end">
//                                                     <PersonIcon color='primary' />
//                                                 </InputAdornment>
//                                             ),
//                                         }}
//                                     />
//                                     {formErrors.lastName && <Typography color="error" fontSize='10px'>{formErrors.lastName}</Typography>}
//                                 </Grid>
//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8}>
//                                 <TextField
//                                     margin="normal"
//                                     variant='standard'
//                                     required
//                                     fullWidth
//                                     id="email"
//                                     label="Work Email"
//                                     name="email"
//                                     autoComplete="email"
//                                     onChange={handleEmailChange}
//                                     // error={emailError} // Shows the error state if the email is invalid
//                                     // helperText={emailError ? "Please enter a valid email address." : ""}
//                                     // onChange={(e) => setEmail(e.target.value)}
//                                     InputProps={{
//                                         endAdornment: (
//                                             <InputAdornment position="end">
//                                                 <EmailIcon color='primary' />
//                                             </InputAdornment>
//                                         ),
//                                     }}
//                                 />

//                                 {formErrors.useremail && <Typography color="error" fontSize='10px'>{formErrors.useremail}</Typography>}
//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8}>
//                                 <TextField
//                                     margin="normal"
//                                     variant='standard'
//                                     required
//                                     fullWidth
//                                     id="mobileNumber"
//                                     label="Mobile Number"
//                                     name="mobileNumber"
//                                     autoComplete="tel"
//                                     // value={mobileNumber}
//                                     // onChange={(e) => setMobileNumber(e.target.value)}
//                                     onChange={(e) => {
//                                         setMobileNumber(e.target.value);
//                                         // Clear error for First Name when the user starts typing
//                                         if (formErrors.mobileNumber) {
//                                             setFormErrors((prevErrors) => ({ ...prevErrors, mobileNumber: '' }));
//                                         }
//                                     }}
//                                     InputProps={{
//                                         endAdornment: (
//                                             <InputAdornment position="end">
//                                                 <PhoneIcon color='primary' />
//                                             </InputAdornment>
//                                         ),
//                                         inputMode: 'numeric', // Ensures numeric keyboard on mobile devices
//                                     }}
//                                     onKeyPress={(e) => {
//                                         if (!/[0-9]/.test(e.key)) {
//                                             e.preventDefault();
//                                         }
//                                     }}

//                                 />

//                                 {formErrors.mobileNumber && <Typography color="error" fontSize='10px'>{formErrors.mobileNumber}</Typography>}
//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8}>
//                                 <FormControl required variant='standard' fullWidth margin='normal'>
//                                     <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
//                                     <Input
//                                         id="outlined-adornment-password"
//                                         type={showPassword ? 'text' : 'password'}
//                                         value={password}

//                                         onChange={handlePasswordChange}
//                                         endAdornment={
//                                             <InputAdornment position="end">
//                                                 <IconButton
//                                                     aria-label="toggle password visibility"
//                                                     onClick={handleClickShowPassword}
//                                                     edge="end"
//                                                 >
//                                                     {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
//                                                 </IconButton>
//                                             </InputAdornment>
//                                         }
//                                         label="Password"
//                                     />
//                                 </FormControl>

//                                 {formErrors.password && <Typography color="error" fontSize='10px'>{formErrors.password}</Typography>}
//                             </Grid>


//                             <Grid item xs={10} md={8} lg={8} >
//                                 <FormControl required variant='standard' fullWidth margin='normal'>
//                                     <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
//                                     <Input
//                                         id="outlined-adornment-confirm-password"
//                                         type="password"
//                                         value={confirmPassword}
//                                         onChange={handleConfirmPasswordChange}
//                                         label="Confirm Password"
//                                     />
//                                 </FormControl>
//                                 <Typography variant="caption" color="textSecondary" >
//                                     Note : Password should contain at least one special character, one digit, one uppercase letter, and one lowercase letter
//                                 </Typography>
//                                 {formErrors.confirmPassword && <Typography color="error" fontSize='10px'>{formErrors.confirmPassword}</Typography>}
//                             </Grid>

//                             <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
//                                 <Button onClick={handleOrganizationSubmit} variant='contained' sx={{ bgcolor: '#ecb61e', borderRadius: 5 }} fullWidth>
//                                     Submit
//                                 </Button>
//                             </Grid>
//                             <Grid item xs={10} md={8} lg={8} display='flex' justifyContent='center' pt={2}>
//                                 <Typography>
//                                     Already have account?
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
//                                 <Link sx={{ cursor: 'pointer' }} to='/talentlogin'>Talent Login</Link>
//                             </Grid>

//                             <Grid item xs={10} md={6} lg={6} display='flex' justifyContent='center' pt={2}>
//                                 <Link sx={{ cursor: 'pointer' }} to='/organizationlogin'>Organization Login</Link>
//                             </Grid>
//                         </Grid>
//                     </FormSection>
//                 </Grid>



//             </Grid>
//         </Box>
//     )
// }

// export default SignUp