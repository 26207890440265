


import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, useEffect } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Input from '@mui/material/Input';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../../../hooks/useAuth';
import { Link } from 'react-router-dom';

const defaultTheme = createTheme();

export const OrganizationLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const { save } = useAuth();

    // Load remembered email and password from localStorage if available
    useEffect(() => {
        const rememberedEmail = localStorage.getItem('organizationRememberedEmail');
        const rememberedPassword = localStorage.getItem('organizationRememberedPassword');
        if (rememberedEmail && rememberedPassword) {
            setEmail(rememberedEmail);
            setPassword(rememberedPassword);
            setRememberMe(true);
        }
    }, []);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handlePasswordChange = (event) => setPassword(event.target.value);
    const handleRememberMeChange = (event) => setRememberMe(event.target.checked);

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
        
    //     if (!email || !password) {
    //         enqueueSnackbar('Please enter both fields', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //         });
    //         return;
    //     }

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_URL}/api/organizationlogin`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ workEmail: email, password }),
    //         });

    //         const data = await response.json();

    //         if (response.ok) {
    //             const id = data.id;
    //             save({}, data.token);

    //             // Save email and password in localStorage if "Remember Me" is checked
    //             if (rememberMe) {
    //                 localStorage.setItem('organizationRememberedEmail', email);
    //                 localStorage.setItem('organizationRememberedPassword', password);
    //             } else {
    //                 localStorage.removeItem('organizationRememberedEmail');
    //                 localStorage.removeItem('organizationRememberedPassword');
    //             }

    //             navigate('/organizationdashboard', { state: { id } });
    //         } else if (response.status === 401) {
    //             enqueueSnackbar('Wrong username or password', {
    //                 variant: 'error',
    //                 anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //             });
    //         } else {
    //             enqueueSnackbar('Something went wrong!', {
    //                 variant: 'error',
    //                 anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //             });
    //         }
    //     } catch (error) {
    //         enqueueSnackbar('Something went wrong!', {
    //             variant: 'error',
    //             anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //         });
    //     }
    // };


    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!email || !password) {
            enqueueSnackbar('Please enter both fields', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            });
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/organizationlogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ workEmail: email, password }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                const id = data.id;
                save({}, data.token);
    
                // Save email and password in localStorage if "Remember Me" is checked
                if (rememberMe) {
                    localStorage.setItem('organizationRememberedEmail', email);
                    localStorage.setItem('organizationRememberedPassword', password);
                } else {
                    localStorage.removeItem('organizationRememberedEmail');
                    localStorage.removeItem('organizationRememberedPassword');
                }
    
                navigate('/organizationdashboard', { state: { id } });
            } else if (response.status === 401) {
                enqueueSnackbar('Wrong username or password', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                });
            } else if (response.status === 403) {
                // Handle the unverified email case
                enqueueSnackbar('Email not verified. Please check your inbox and verify your email by sign up.', {
                    variant: 'warning',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                });
                
                // You could add a button or link to request a new verification email
                // setShowResendOption(true);
            } else {
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong!', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            });
        }
    };

    return (
        <Grid
            container
            sx={{
                background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
                display: 'flex',
                minHeight: '100vh'
            }}
        >
            <Grid item xs={12} sm={8} md={8} lg={6} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: isMobile ? 0 : 2,
                margin: 'auto'
            }}>
                <CssBaseline />
                <Paper elevation={10} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p:isMobile ? 2 : 5,
                    borderRadius: "25px",
                    width: { xs: '90%', md: '60%' }
                }}>
                    <Grid container direction="column" alignItems="center" sx={{
                        borderRadius: "20px 20px 0 0"
                    }} xs={12}>
                        <Grid item>
                            <img
                                src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                alt="Logo"
                                style={{ width: '100px', marginRight: '5px' }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography component="h1" variant="h5" marginTop={2}>
                                Organization Sign In
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container component="form" justifyContent='center' noValidate sx={{ mt: 1 }} xs={12}>
                        <Grid item xs={9}>
                            <TextField
                                margin="normal"
                                variant='standard'
                                required
                                fullWidth
                                id="email"
                                label="Username"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon color='primary' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={9} mt={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={handleRememberMeChange}
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleSubmit}
                            >
                                Sign In
                            </Button>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={6} md={6} lg={6}>
                                <Link sx={{ cursor: 'pointer' }} to='/organizationforgotpassword'>Forgot Password</Link>
                            </Grid>

                            <Grid item xs={6} md={6} lg={6} display='flex' justifyContent='end'>
                                <Link sx={{ cursor: 'pointer' }} to="/signup">Sign Up</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default OrganizationLogin;

