import { Box } from '@mui/material'
import React from 'react'
import DrawerOrganization from './DrawerOrganization'
import { useLocation } from 'react-router-dom'

const SendOffers = () => {

    const location = useLocation();
    const { id } = location.state || {};

  return (
    <Box display='flex'>
        <DrawerOrganization id={id}/>

    
    </Box>
  )
}

export default SendOffers