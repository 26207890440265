import * as React from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { BorderAllRounded, Height, Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Swal from 'sweetalert2';
import DrawerHeader from '../../../components/DrawerHeader';
import { useSnackbar } from 'notistack';
import Input from '@mui/material/Input';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://dshgsonic.com/">
                DSHGSonic
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export const AddAdmin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
    const [verificationSent, setVerificationSent] = useState(false); // Track whether the code has been sent
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [code, setCode] = useState('');
    const [userCode, setUserCode] = useState('');

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    // const handleClickShowPassword = () => setShowPassword((show) => !show);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSendCode = async () => {
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addadminverificationcode`, { userName: email });

            if (response.status === 200) {
                enqueueSnackbar('Verification code sent successfully', { variant: 'success' });

                const otp = response.data.otp;
                setCode(otp)

            }
        } catch (error) {
            enqueueSnackbar('Failed to send verification code', { variant: 'error' });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!password) {
            setPasswordError('Password is required');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addAdmin`, {
                userName: email,
                password,
            });

            if (response.status === 201) {
                enqueueSnackbar('Admin added successfully', { variant: 'success' });
                navigate('/admins'); // Navigate to dashboard after successful creation
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong!', { variant: 'error' });
        }
    };


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSubmitOtp = async (event) => {

        event.preventDefault();

        if (!userCode) {
            enqueueSnackbar('Please enter verification code', { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            return;
        }

        if (userCode !== code) {
            enqueueSnackbar('Invalid verification code', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            return;
        }

        if (userCode === code) {
            enqueueSnackbar('verification successful', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            setVerificationSent(true);
        }
    }


    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_URL}/api/addAdmin`, {
    //             userName: email,
    //             password
    //         });

    //         if (response.status === 201) {
    //             enqueueSnackbar('Admin added successfully', {
    //                 variant: 'success',
    //                 anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //             })

    //         }
    //     } catch (error) {
    //         if (error.response && error.response.status === 409) {
    //             enqueueSnackbar('Admin already present', {
    //                 variant: 'error',
    //                 anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //             })
    //         } else {
    //             enqueueSnackbar('Something went wrong!', {
    //                 variant: 'error',
    //                 anchorOrigin: { vertical: 'top', horizontal: 'right' }
    //             })
    //         }
    //     }
    // };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerHeader />
            {/* <Grid
                container
                sx={{
                    height: '100%',
                    display: 'flex',
                    minHeight: '100vh'
                }}
            >
                <Grid item xs={12} sm={8} md={6} lg={5} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMobile ? 0 : 2,
                    margin: 'auto'
                }}>
                    <Paper elevation={10} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 5,
                        borderRadius: "25px",
                        width: { xs: '90%', md: '80%' }
                    }}  >
                        <Grid container direction="column" alignItems="center" sx={{
                            borderRadius: "25px 25px 0 0"
                        }} xs={12}>
                            <Grid item>
                                <Typography component="h1" variant="h5" marginTop={2}>
                                    Add Admin
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant='standard'
                                    id="email"
                                    label="Username"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon color='primary'/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} pt={2}>
                            <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input
                                    id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff color='primary'/> : <Visibility color='primary'/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid> */}
            <Grid
                item
                xs={12}
                sm={8}
                md={6}
                lg={5}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto',
                    minHeight: '100vh'
                }}
            >
                <Paper elevation={10} sx={{ p: 5, borderRadius: '25px', width: { xs: '90%', md: '80%' }, maxWidth: '500px' }}>
                    <Typography component="h1" variant="h3" marginTop={2}>
                        Add Admin
                    </Typography>

                    <Grid container component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                variant='standard'
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError('');
                                }}
                                error={!!emailError}
                                helperText={emailError}
                                disabled={verificationSent} // Disable after sending code
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon color='primary' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {!verificationSent && (
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2, mb: 2 }}
                                    onClick={handleSendCode}
                                >
                                    Send Verification Code
                                </Button>
                            </Grid>
                        )}

                        {code && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        autoFocus
                                        id="verificationcode"
                                        label="Enter Verification Code"
                                        disabled={verificationSent}
                                        onChange={(e) => setUserCode(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        // color="success"
                                        disabled={verificationSent}
                                        sx={{ mt: 2, mb: 2 }}
                                        onClick={handleSubmitOtp}
                                    >
                                        Verify
                                    </Button>
                                </Grid>
                            </>
                        )}

                        {verificationSent && (
                            <>
                                {/* Show email field again, disabled */}
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="emailDisabled"
                                        label="Email"
                                        value={email}
                                        disabled
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EmailIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                {/* Password field */}
                                <Grid item xs={12}>
                                    <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
                                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                        <Input
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                setPasswordError('');
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                            error={!!passwordError}
                                        />
                                        {passwordError && (
                                            <Typography color="error" variant="body2">
                                                {passwordError}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Add Admin
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </Box>
    );
}

export default AddAdmin;
