import { Box, Grid, Typography, TextField, Button, Paper, InputAdornment } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';


const defaultTheme = createTheme();
const EmailVrification = () => {

    const [email, setEmail] = useState('')
    const [userotp, setUserOtp] = useState('')
    const [talentId, setTalentId] = useState('')
    const location = useLocation();
    const { userName, password, firstName, lastName, mobileNumber, useremail, otp, companyName, workPhone, talentFile, organizationFile, userType } = location.state || {};
    // console.log(userType);
    const navigate = useNavigate()
    const { save } = useAuth()
    const { enqueueSnackbar } = useSnackbar();
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));





    const handleSubmitOtp = async (event) => {

        event.preventDefault();

        if (!userotp) {
            enqueueSnackbar('Please enter verification code', { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            return;
        }

        if (userotp !== otp) {
            enqueueSnackbar('Invalid verification code', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            return;
        }

        if (userotp === otp) {
            if (userType === 'talent') {
                try {
                    // Create form data with user information
                    const formData = new FormData();
                    formData.append('userName', userName);
                    formData.append('firstName', firstName);
                    formData.append('lastName', lastName);
                    formData.append('mobileNumber', mobileNumber);
                    formData.append('userEmail', useremail);
                    formData.append('emailVerification', 'true');
            
                    const response = await axios.post(`${process.env.REACT_APP_URL}/api/talentemailverification`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
            
                    // Handle successful response (status 200 for email verification updated)
                    if (response.status === 200) {
                        const talentId = response.data.talentId;
                        // Save the JWT token
                        save({}, response.data.token);
                        // Show success message to user
                        enqueueSnackbar('Email verification successful!', {
                            variant: 'success',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' }
                        });
                        // Navigate to talent profile page
                        navigate('/talentprofile', { state: { talentId } });
                    }
                } catch (error) {
                    if (error.response) {
                        // Handle different error status codes
                        if (error.response.status === 404) {
                            // User not found
                            enqueueSnackbar('User not found. Please register first.', {
                                variant: 'error',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' }
                            });
                            // Redirect to registration page
                            navigate('/register');
                        } else if (error.response.status === 400) {
                            // Bad request - missing required fields
                            enqueueSnackbar('Missing required information. Please fill all fields.', {
                                variant: 'error',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' }
                            });
                        } else if (error.response.status === 500) {
                            // Server error
                            enqueueSnackbar('Server error. Please try again later.', {
                                variant: 'error',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' }
                            });
                        } else {
                            // Any other error
                            enqueueSnackbar(error.response.data.message || 'An error occurred', {
                                variant: 'error',
                                anchorOrigin: { vertical: 'top', horizontal: 'right' }
                            });
                        }
                    } else if (error.request) {
                        // Network error - request made but no response received
                        enqueueSnackbar('Network error. Please check your connection.', {
                            variant: 'error',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' }
                        });
                    } else {
                        // Something else went wrong
                        enqueueSnackbar('An unexpected error occurred.', {
                            variant: 'error',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' }
                        });
                    }
                    
                    // Log the error for debugging
                    console.error('Email verification error:', error);
                }
            } 
            // else if (userType === 'organization') {
            //     try {
            //         // Create a FormData object
            //         const formData = new FormData();
            //         formData.append('companyName', companyName);
            //         formData.append('firstName', firstName);
            //         formData.append('lastName', lastName);
            //         formData.append('workPhone', workPhone);
            //         formData.append('workEmail', useremail);




            //         // Send the POST request with FormData
            //         const response = await axios.post(`${process.env.REACT_APP_URL}/api/createorganization`, formData, {
            //             headers: {
            //                 'Content-Type': 'multipart/form-data',
            //             },
            //         });

            //         console.log(response.data);

            //         if (response.data === 'Phone') {
            //             console.log('User is already present with mobile');
            //         } else if (response.data === 'Email') {
            //             console.log('User is already present with email');
            //         } else if (response.status === 201) {
            //             console.log('Successful');
            //             const id = response.data.id;
            //             save({}, response.data.token);
            //             navigate('/organizationprofile', { state: { id } });
            //         } else {
            //             enqueueSnackbar('Internal server error', {
            //                 variant: 'error',
            //                 anchorOrigin: { vertical: 'top', horizontal: 'right' },
            //             });
            //         }
            //     } catch (error) {
            //         console.error('Error adding user data:', error);
            //         enqueueSnackbar('Internal server error', {
            //             variant: 'error',
            //             anchorOrigin: { vertical: 'top', horizontal: 'right' },
            //         });
            //     }

            // }
            if (userType === 'organization') {
                try {
                  // Create form data with organization information
                  const formData = new FormData();
                  formData.append('companyName', companyName);
                  formData.append('workEmail', useremail);
                  formData.append('workPhone', workPhone);
                  formData.append('emailVerification', 'true');
              
                  const response = await axios.post(`${process.env.REACT_APP_URL}/api/organizationemailverification`, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  });
              
                  // Handle successful response
                  if (response.status === 200) {
                    const id = response.data.organizationId;
                    // Save the JWT token
                    save({}, response.data.token);
                    // Show success message to user
                    enqueueSnackbar('Email verification successful!', {
                      variant: 'success',
                      anchorOrigin: { vertical: 'top', horizontal: 'right' }
                    });
                    // Navigate to organization profile page
                    navigate('/organizationprofile', { state: { id } });
                  }
                } catch (error) {
                  if (error.response) {
                    // Handle different error status codes
                    if (error.response.status === 404) {
                      // Organization not found
                      enqueueSnackbar('Organization not found. Please register first.', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                      });
                      // Redirect to registration page
                      navigate('/register');
                    } else if (error.response.status === 400) {
                      // Bad request - missing required fields
                      enqueueSnackbar('Missing required information. Please fill all fields.', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                      });
                    } else if (error.response.status === 500) {
                      // Server error
                      enqueueSnackbar('Server error. Please try again later.', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                      });
                    } else {
                      // Any other error
                      enqueueSnackbar(error.response.data.message || 'An error occurred', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' }
                      });
                    }
                  } else if (error.request) {
                    // Network error - request made but no response received
                    enqueueSnackbar('Network error. Please check your connection.', {
                      variant: 'error',
                      anchorOrigin: { vertical: 'top', horizontal: 'right' }
                    });
                  } else {
                    // Something else went wrong
                    enqueueSnackbar('An unexpected error occurred.', {
                      variant: 'error',
                      anchorOrigin: { vertical: 'top', horizontal: 'right' }
                    });
                  }
                  
                  // Log the error for debugging
                  console.error('Organization email verification error:', error);
                }
              }
        }

    }





    return (
        <Grid
            container
            sx={{
                background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
                display: 'flex',
                minHeight: '100vh'
            }}
        >
            <Grid item xs={12} sm={8} md={6} lg={4} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: isMobile ? 0 : 2,
                margin: 'auto'
            }}>

                <Paper elevation={10} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 5,
                    borderRadius: "25px",
                    width: { xs: '90%', md: '80%' }
                }}  >
                    <Grid container direction="column" alignItems="center" sx={{
                        borderRadius: "20px 20px 0 0"
                    }} xs={12}>
                        <Grid item>
                            <img
                                src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                alt="Logo"
                                style={{ width: '100px', marginRight: '5px' }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography component="h1" variant="h5" marginTop={2}>
                                Email Verification
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container component="form" noValidate sx={{ mt: 1 }} xs={12}>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                variant='standard'
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                value={useremail}
                                disabled
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon color='primary' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                variant='standard'
                                required
                                fullWidth
                                autoFocus
                                id="verificationcode"
                                label="Enter Verification Code"
                                onChange={(e) => setUserOtp(e.target.value)}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                // color="success"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={handleSubmitOtp}
                            >
                                Verify
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default EmailVrification