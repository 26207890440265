import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { Avatar, Box, Button, Tooltip, Typography, IconButton, Breadcrumbs } from '@mui/material'
// import Avatar from '@mui/material/Avatar';

import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TalentDrawer from './TalentDrawer';
import CreateIcon from '@mui/icons-material/Create';




export const TalentInformation = () => {


    const [talentDetails, setTalentDetails] = useState(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const location = useLocation();
    const { talentId } = location.state || {};

    // useEffect(() => {
    //     const fetchTalentDetails = async () => {
    //         try {
    //             const response = await axios.get(`${process.env.REACT_APP_URL}/talent/${phoneNumber}`);
    //             setTalentDetails(response.data);
    //         } catch (error) {
    //             console.error('Error fetching talent details:', error);
    //         }
    //     };

    //     if (phoneNumber) {
    //         fetchTalentDetails();
    //     }
    // }, [phoneNumber]);

    useEffect(() => {
        const fetchTalentDetails = async () => {


            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/api/talentprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: talentId }), // Sending talentId in the body
                });


                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();

                setTalentDetails(data)

                const skillsArray = data.skills.split(',').map(skill => skill.trim());
                setTalentDetails({ ...data, skills: skillsArray });

            } catch (error) {
                console.error('Error fetching talent details:', error);
            }
        };

        if (talentId) {
            fetchTalentDetails();
        }
    }, [talentId]);

    if (!talentDetails) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const data = talentDetails.expertise.childAndMaternalHealth

    console.log(talentDetails);


    const handleBack = () => {
        navigate('/talentdashboard', { state: { talentId } });
    }

    const handleEditClick = () => {


        navigate('/talentprofile', { state: { talentId } });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const formattedDate = talentDetails.specificDate
        ? dayjs(talentDetails.specificDate).format('YYYY-MM-DD')
        : '';

    return (
        <Box sx={{ display: 'flex', bgcolor: '#E7E1F0' }}>

            <TalentDrawer talentId={talentId} />
            <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', width: '100%', pt: 10, pb: 10 }}>
                <Box mb={2} width='100%' display='flex' alignItems='center' justifyContent='start' flexGrow={1} paddingLeft={3}>
                    {/* <Box display='flex' alignItems='center' onClick={handleBack} sx={{
                        '&:hover': {
                            cursor: 'pointer',
                            '& .MuiTypography-root': {
                                color: '#FF6900'
                            },
                            '& .MuiIconButton-root': {
                                color: '#FF6900'
                            }
                        }
                    }}> */}
                    {/* <IconButton onClick={handleBack}>
                            <ArrowBackIosNewIcon sx={{ color: '#FF6900' }} />
                        </IconButton>
                        <Typography variant="body1" component="span" fontSize={20} fontWeight={400}>
                            Back
                        </Typography> */}
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                        <Link component={RouterLink} to="/talentdashboard" state={{ talentId }} underline="hover">
                            <IconButton size="small">
                                <HomeIcon fontSize="small" color='primary' />
                            </IconButton>
                        </Link>
                        <Link component={RouterLink} underline="hover" color="inherit">
                            Details
                        </Link>
                    </Breadcrumbs>
                    {/* </Box> */}
                </Box>
                <Box width='85%' p={5} bgcolor='white' borderRadius={5} >

                    <Box borderBottom='0.5px solid black' mb={2}>

                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2// Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={1.5} mt={2}>
                                1. Personal Details
                            </Typography>

                            <Button

                                onClick={handleEditClick}
                                sx={{
                                    backgroundColor: '#F77E09',
                                    color: '#ffffff',

                                    '&:hover': {
                                        backgroundColor: '#F77E09',
                                    },
                                    borderRadius: 8,
                                    width: '130px',
                                    height: '30px',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                    


                                }}
                            >
                                <CreateIcon sx={{mr:1}}/>
                                Edit
                            </Button>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='left' marginBottom={1}>
                            <Avatar
                                alt="Talent Image"
                                src={talentDetails.talentImage}  // Use the talentImage URL from the API
                                sx={{ width: 100, height: 100 }}  // Optional: Adjust the size of the Avatar
                            />
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1.5}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                First Name
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.firstName}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1.5}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Last Name
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.lastName}
                            </Typography>
                        </Box>

                        {/* <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1.5}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Gender
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.gender}
                            </Typography>
                        </Box> */}

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1.5}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Phone Number
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.countryCode} {talentDetails.mobileNumber}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                            <Typography width='50%' fontSize={18} fontWeight={400}>
                                Email
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.userEmail}
                            </Typography>
                        </Box>



                    </Box>

                    <Box borderBottom='0.5px solid black' mb={2} mt={5}>
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                2. Job Information
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Current job position
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                {/* {talentDetails.jobPosition} */}
                                : {Array.isArray(talentDetails.jobPosition) ? talentDetails.jobPosition.join(', ') : talentDetails.jobPosition}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Interests in a job
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.intrest}
                            </Typography>
                        </Box>



                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Experience
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.experience}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Availability
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.joiningDate} {formattedDate}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Preferred Work location
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.city} , {talentDetails.jobState}
                            </Typography>
                        </Box>

                    </Box>

                    <Box borderBottom='0.5px solid black' mb={2} mt={5}>
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                3. Expertise and Community management
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Child and Maternal Health
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.expertise.childAndMaternalHealth}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Community Representation
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.expertise.communityRepresentation}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Accessibility and equity
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.expertise.accessibilityAndEquity}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Health and Awareness
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.expertise.healthAndWellness}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Social Justice and Inclusion
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.expertise.socialJusticeAndInclusion}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Youth Services
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.expertise.youthServices}
                            </Typography>
                        </Box>


                    </Box>


                    <Box borderBottom='0.5px solid black' mb={2} mt={5}>
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                4. Certificate(s), Certification(s), or license
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Healthcare
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.certification.healthcare
                                }
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Specialized Care Certifications
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.certification.specializedCareCertifications}

                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Mental Health and Peer Support
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.certification.mentalHealthandPeerSupport}

                            </Typography>
                        </Box>


                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Other
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.certification.otherOptions}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                State
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.certification.state}
                            </Typography>
                        </Box>


                    </Box>

                    <Box mb={2} mt={5}>
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                5. Interested and Matches
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Intrested in being a mentor
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.mentor}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Matches
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                :   {
                                    talentDetails.matches === 'Text'
                                        ? talentDetails.mobileNumber
                                        : talentDetails.matches === 'Email'
                                            ? talentDetails.userEmail
                                            : talentDetails.matches === 'Both'
                                                ? `${talentDetails.mobileNumber} / ${talentDetails.userEmail}`
                                                : ''
                                }

                            </Typography>

                        </Box>




                    </Box>

                    {/* 
                    <Box borderBottom='0.5px solid black' mb={2} mt={5}>
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                6. Job Preference
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Current job search status
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.currentJobStatus}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Preferred work arrangements
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.matches.text} {talentDetails.workArrangements}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Are you looking for permenent employement
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.permenentEmployement} {talentDetails.selectAllYes}{talentDetails.selectAllNo}
                            </Typography>
                        </Box>


                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Targeted Salary (Annually/Hourly)
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.targetedSalary}
                            </Typography>
                        </Box>


                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Length of experience in field
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.yearOfExperience}
                            </Typography>
                        </Box>

                       

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Preferred Language
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.preferedLanguage}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Carrier Motivation
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.carrierMotivation}
                            </Typography>
                        </Box>
                    </Box>


                    <Box borderBottom='0.5px solid black' mb={2} mt={5}>
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                7. Specialities and skills
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Specialities
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.specialities}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Skills
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                
                                : {Array.isArray(talentDetails.skills) ? talentDetails.skills.join(', ') : talentDetails.skills}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Professional affilations
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.affilations}
                            </Typography>
                        </Box>




                    </Box>

                    <Box borderBottom='0.5px solid black' mb={2} mt={5}>
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                8. Qualifications
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Certifications and Licenses
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.certificationsEdit}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Emergency and medical certifications
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.medicalCertifications}
                            </Typography>
                        </Box>




                    </Box>

                    <Box borderBottom='0.5px solid black' mb={2} mt={5}>
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                9. Educational Experience
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Highest level of education
                            </Typography>

                            <Typography width='50%' fontWeight={300} fontSize={18} color='#8C8C8C'>
                                : {talentDetails.education}
                            </Typography>
                        </Box>
                    </Box>

                    <Box borderBottom='0.5px solid black' mb={2} mt={5}>
                        <Box sx={{
                            borderLeft: '4px solid #FF6900',
                            paddingLeft: 2, // Adjust the padding as needed
                        }}>
                            <Typography fontSize={25} fontWeight={500} marginBottom={2}>
                                10. Work Experience
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Experience 1 :
                            </Typography>

                            <Typography
                                width='50%'
                                fontWeight={300}
                                fontSize={18}
                                color='#8C8C8C'
                                sx={{ marginTop: 1, lineHeight: '1.5' }} // Add lineHeight for spacing between lines
                            >
                                Position - {talentDetails.experienceEdit.experienceTalent1.position} <br />
                                Workplace - {talentDetails.experienceEdit.experienceTalent1.workplace} <br />
                                Timeline - {talentDetails.experienceEdit.experienceTalent1.timeline}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Experience 2 :
                            </Typography>

                            <Typography
                                width='50%'
                                fontWeight={300}
                                fontSize={18}
                                color='#8C8C8C'
                                sx={{ marginTop: 1, lineHeight: '1.5' }} // Add lineHeight for spacing between lines
                            >
                                Position - {talentDetails.experienceEdit.experienceTalent2.position} <br />
                                Workplace - {talentDetails.experienceEdit.experienceTalent2.workplace} <br />
                                Timeline - {talentDetails.experienceEdit.experienceTalent2.timeline}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' justifyContent='center' marginBottom={2}>
                            <Typography width='50%' fontSize={18} fontWeight={500}>
                                Experience 3 :
                            </Typography>

                            <Typography
                                width='50%'
                                fontWeight={300}
                                fontSize={18}
                                color='#8C8C8C'
                                sx={{ marginTop: 1, lineHeight: '1.5' }} // Add lineHeight for spacing between lines
                            >
                                Position - {talentDetails.experienceEdit.experienceTalent3.position} <br />
                                Workplace - {talentDetails.experienceEdit.experienceTalent3.workplace} <br />
                                Timeline - {talentDetails.experienceEdit.experienceTalent3.timeline}
                            </Typography>
                        </Box>
                    </Box> */}





                </Box>

            </Box>
        </Box>
    )
}
export default TalentInformation;
