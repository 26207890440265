import { AppBar, Box, Divider, Drawer, IconButton, List, ListItemButton, ListItem, ListItemIcon, ListItemText, Paper, Toolbar, Tooltip, Typography, Grid, Button, Avatar } from '@mui/material'
import React from 'react'
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiAppBar from '@mui/material/AppBar';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import HeaderTopbar from '../../../../components/HeaderTopbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LogoutIcon from '@mui/icons-material/Logout';
import WorkIcon from '@mui/icons-material/Work';
import LockIcon from '@mui/icons-material/Lock';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';




export const TalentDrawer = ({ talentId }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const drawerWidth = 240;
    const theme = useTheme();
    const [open, setOpen] = useState(!isMobile);
    const [profileDrawerOpen, setProfileDrawerOpen] = useState(false); // State for profile drawer
    const [state, setState] = useState({
        right: false,
    });
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setOpen(!isMobile);
    }, [isMobile]);

    const handleProfileDrawerToggle = () => {
        setProfileDrawerOpen(!profileDrawerOpen); // Toggle the profile drawer
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleChangePassword = () => {
        console.log('Change Password Clicked');
        navigate('/talentchangepassword', { state: { talentId } });
    };

    const handleLogout = () => {
        const organizationRememberedEmail = localStorage.getItem('organizationRememberedEmail');
        const organizationRememberedPassword = localStorage.getItem('organizationRememberedPassword');
        const AdminRememberedEmail = localStorage.getItem('adminRememberedEmail');
        const AdminRememberedPassword = localStorage.getItem('adminRememberedPassword');
        const talentRememberedEmail = localStorage.getItem('talentRememberedEmail');
        const talentRememberedPassword = localStorage.getItem('talentRememberPassword');

        // Clear all localStorage data
        localStorage.clear();

        // Restore the remembered credentials in localStorage
        if (talentRememberedEmail && talentRememberedPassword)  {
          
            localStorage.setItem('talentRememberedEmail', talentRememberedEmail);
            localStorage.setItem('talentRememberPassword', talentRememberedPassword);

        }

        if (AdminRememberedEmail && AdminRememberedPassword) {
            localStorage.setItem('adminRememberedEmail', AdminRememberedEmail);
            localStorage.setItem('adminRememberedPassword', AdminRememberedPassword);
        }

        if (organizationRememberedEmail && organizationRememberedPassword) {
            localStorage.setItem('organizationRememberedEmail', organizationRememberedEmail);
            localStorage.setItem('organizationRememberedPassword', organizationRememberedPassword);
        }


        navigate('/talentlogin');
    };

    const handleProfileClick = () => {
        navigate('/talentprofile', { state: { talentId } });
    };

    const handleDashboard = () => {
        navigate('/talentdashboard', { state: { talentId } });
    };

    const handleJobClick = () => {
        navigate('/talentjob', { state: { talentId } });
    };

    // const getPaperStyle = (paths) => ({
    //     margin: 10,
    //     backgroundColor: paths.includes(location.pathname) ? '#E7E1F0' : 'white',

    // });
    const getPaperStyle = (paths) => ({
        margin: 10,
        backgroundColor: paths.includes(location.pathname) ? '#E7E1F0' : 'white',
        boxShadow: paths.includes(location.pathname) ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : '0px 2px 4px rgba(0, 0, 0, 0.1)', // More pronounced shadow for highlighted
        transition: "box-shadow 0.3s, background-color 0.3s", // Transition for both shadow and background color
        "&:hover": { 
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)', // Increase shadow on hover
        },
    });
    
    

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === 'keydown' &&
            ((event.key === 'Tab' || event.key === 'Shift'))
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 10px)`, // Increase width here
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 10px)`, // Adjust for breakpoints
        },
    });



    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#3c1767', // Set background color to white
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));



    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );



    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const LogoContainer = styled('div')({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    });
    const [rightDrawerOpen, setRightDrawerOpen] = useState(false);

    const handleRightDrawerToggle = () => {
        setRightDrawerOpen(!rightDrawerOpen);
    };

    const handleTalentDetailsClick = async () => {
        navigate('/talentprofileinformation', { state: { talentId } });
    }

    const handleJobofferClick = async () => {
        navigate('/organizationoffer', { state: { talentId } });
    }



    
    return (
        <Box>
            <AppBar position='fixed' open={open}>
                <Toolbar>
                    <Grid container xs={12} sm={12} md={12}>
                        <Grid item xs={6} sm={6} md={6}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Tooltip title="Logout">
                                <Button
                                    onClick={handleLogout}
                                    startIcon={<LogoutIcon />}
                                    sx={{ color: 'white', textTransform: 'none' }}
                                >
                                    Logout
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Grid component={Drawer} variant='permanent' open={open}>
                <DrawerHeader>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'center' : 'left',
                            px: 2.5,
                            bgcolor: "white",
                            justifyItems: 'center',
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: 0 ,  display:'flex' , alignItems:'center' , justifyContent:'center' }} />
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <LogoContainer>
                                <img
                                    src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                    alt="Logo"
                                    style={{ width: isMobile ? '80px' : '150px' }}
                                />
                            </LogoContainer>

                            <IconButton onClick={handleDrawerClose} sx={{ alignItems: 'end' , ml: open ? 3 : 'auto', }}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: '#3C2565' }} /> : <ChevronLeftIcon sx={{ color: '#3C2565' }} />}
                            </IconButton>
                        </Box>
                    </ListItemButton>
                </DrawerHeader>

                <Divider />
                <List>
                    <Paper elevation={3} style={getPaperStyle(['/talentdashboard'])} sx={{borderRadius:3}}>
                        <ListItemButton onClick={handleDashboard}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <HomeOutlinedIcon sx={{ color: '#3C2565' }} />
                            </ListItemIcon>
                            <ListItemText primary={'Dashboard'}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Paper>

                    <Paper elevation={3} style={getPaperStyle(['/talentjob'])} sx={{borderRadius:3}}>
                        <ListItemButton onClick={handleJobClick}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <WorkIcon sx={{ color: '#3C2565' }} />
                            </ListItemIcon>
                            <ListItemText primary={'Jobs'}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Paper>

                    <Paper elevation={3} style={getPaperStyle(['/organizationoffer', '/talent-organizationdetails'])} sx={{borderRadius:3}}>
                        <ListItemButton onClick={handleJobofferClick}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <LocalOfferIcon sx={{ color: '#3C2565' }} />
                            </ListItemIcon>
                            <ListItemText primary={'Organization Offers'}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Paper>

                    <Paper elevation={3} style={getPaperStyle(['/talentprofile'])} sx={{borderRadius:3}}>
                        <ListItemButton onClick={handleProfileClick}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <PersonAddIcon sx={{ color: '#3C2565' }} />
                            </ListItemIcon>
                            <ListItemText primary={'Profile'}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Paper>



                    <Paper elevation={3} style={getPaperStyle(['/talentprofileinformation'])} sx={{borderRadius:3}}>
                        <ListItemButton onClick={handleTalentDetailsClick}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <AccountBoxIcon sx={{ color: '#3C2565' }} />
                            </ListItemIcon>
                            <ListItemText primary={'Details'}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Paper>
                </List>
                <Divider />
                {/* <List>
                    <Paper elevation={3}>
                        <ListItemButton onClick={handleChangePassword}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Change Password'}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Paper>

                    <Paper elevation={3}>
                        <ListItemButton onClick={handleLogout}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Logout'}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Paper>
                </List> */}
            </Grid>

            {/* <Drawer
                anchor="right"
                open={rightDrawerOpen}
                onClose={handleRightDrawerToggle}
            >
                <Box sx={{ width: 250 }} role="presentation">
                    <Typography variant="h6" sx={{ p: 2 }}>
                        Profile Menu
                    </Typography>
                    <Divider />
                    <List>
                        <ListItem button onClick={handleChangePassword}>
                            <ListItemIcon>
                                <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary="Change Password" />
                        </ListItem>
                        <ListItem button onClick={handleLogout}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </Box>
            </Drawer> */}
        </Box>
    );
};
export default TalentDrawer