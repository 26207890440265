import { AppBar, Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Toolbar, Tooltip, Typography, Grid } from '@mui/material'
import React from 'react'
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiAppBar from '@mui/material/AppBar';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import HeaderTopbar from './HeaderTopbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useLocation } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LogoutIcon from '@mui/icons-material/Logout';




export const DrawerHeader = () => {


    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const drawerWidth = 240;
    const [open, setOpen] = useState(!isMobile);
    const theme = useTheme();
    const [clicked, setClicked] = useState(false);
    const location = useLocation();


    useEffect(() => {
        setOpen(!isMobile);
    }, [isMobile]);

    const handleError = () => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "This module is under progress",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Reload the window after clicking "OK"
                window.location.reload();
            }
        });
    }






    const handleDrawerOpen = () => {
        setOpen(true); // Open sidebar
    };

    const handleDrawerClose = () => {
        setOpen(false); // Close sidebar
    };

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 10px)`, // Increase width here
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 10px)`, // Adjust for breakpoints
        },
    });



    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#3c1767', // Set background color to white
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));



    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );



    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const LogoContainer = styled('div')({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    });


    // const getPaperStyle = (path) => ({
    //     margin: 10,
    //     backgroundColor: location.pathname === path ? '#e0e0e0' : 'white'
    // });

    const getPaperStyle = (paths) => ({
        margin: 10,
        backgroundColor: paths.includes(location.pathname) ? '#e0e0e0' : 'white'
    });



    return (
        <Box>

            <AppBar position='fixed' open={open}>
                <Toolbar >
                    <Grid container xs={12} sm={12} md={12}  >
                        <Grid item xs={6} sm={6} md={6}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={5}>
                            <Box display="flex" justifyContent="flex-end" >
                                <HeaderTopbar />
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Grid component={Drawer} variant='permanent' open={open}>

                <DrawerHeader>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'left',
                            px: 2.5,
                            bgcolor: "white",
                            justifyItems: 'center'
                        }}


                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>

                        </ListItemIcon>
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <LogoContainer>
                                <img
                                    src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                    alt="Logo"
                                    style={{ width: isMobile ? '80px' : '150px' }}
                                />

                            </LogoContainer>

                            <IconButton onClick={handleDrawerClose} sx={{ alignItems: 'end' }} >
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </Box>
                    </ListItemButton>
                </DrawerHeader>

                <Divider />
                <List>
                    <Link to={'/dashboard'} style={{ textDecoration: 'none'}}>
                        <Tooltip title="Home" placement="right-start">

                            <Paper elevation={3} style={getPaperStyle('/dashboard')}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,

                                    }}


                                >
                                    <ListItemIcon sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}>
                                        <HomeOutlinedIcon sx={{ color: '#FF6900' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </Paper>

                        </Tooltip>
                    </Link>

                    <Link to={'/formonboardingtalent'} style={{ textDecoration: 'none'}}>
                        <Tooltip title="Add Talent" placement="right-start">
                            <Paper elevation={3} style={getPaperStyle('/formonboardingtalent')}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}>
                                        <PersonAddIcon sx={{ color: '#FF6900' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Add New Talent" sx={{ opacity: open ? 1 : 0 }} />

                                </ListItemButton>
                            </Paper>
                        </Tooltip>
                    </Link>

                    <Link to={'/talentdata'} style={{ textDecoration: 'none'}}>
                        <Tooltip title="Talent Data" placement="right-start">
                            <Paper elevation={3} style={getPaperStyle(['/talentdata', '/details', '/edittalent'])} >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}>
                                        < WorkspacePremiumIcon sx={{ color: '#FF6900' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Talent Data" sx={{ opacity: open ? 1 : 0 }} />

                                </ListItemButton>
                            </Paper>
                        </Tooltip>
                    </Link>

                    <Link to={'/hiringdata'} style={{ textDecoration: 'none'}}>
                        <Tooltip title="Organizations" placement="right-start">
                            <Paper elevation={3} style={getPaperStyle(['/hiringdata', '/hiringdetails', '/editonboarding'])}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}>
                                        <HomeWorkIcon sx={{ color: '#FF6900' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Organizations" sx={{ opacity: open ? 1 : 0 }} />

                                </ListItemButton>
                            </Paper>
                        </Tooltip>
                    </Link>

                    <Link to={'/admins'} style={{ textDecoration: 'none'}}>
                        <Tooltip title="Admins" placement="right-start">
                            <Paper elevation={3} style={getPaperStyle(['/admins' , '/addadmin'])}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}>
                                        <AdminPanelSettingsIcon sx={{ color: '#FF6900' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Admins" sx={{ opacity: open ? 1 : 0 }} />

                                </ListItemButton>
                            </Paper>
                        </Tooltip>
                    </Link>












                </List>
                <Divider />

                {/* </Drawer> */}
            </Grid>


        </Box>






    )
}

export default DrawerHeader;